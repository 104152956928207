import { useState, useContext, useMemo, useEffect, useRef } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Tooltip, TextField, InputAdornment } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ColorModeContext, tokens } from "../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import BarChartIcon from '@mui/icons-material/BarChart';
import CategoryIcon from '@mui/icons-material/Category';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HistoryIcon from '@mui/icons-material/History';
import ImageIcon from '@mui/icons-material/Image';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import InventoryIcon from "@mui/icons-material/Inventory";
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CachedIcon from '@mui/icons-material/Cached';
import { SvgIcon } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import LineDetailsIcon from '@mui/icons-material/LineStyle';
import CampaignIcon from '@mui/icons-material/Campaign';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FolderIcon from '@mui/icons-material/Folder';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FeedIcon from '@mui/icons-material/Feed';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ExtensionIcon from '@mui/icons-material/Extension';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import { useAuth } from "../../components/auth/AuthContext";
import { logEvent } from "../../utils/logEvent";

const TrendingUpDay1Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
    <text x="20" y="22" fontSize="12" textAnchor="end" fill="currentColor">1</text>
  </SvgIcon>
);

const TrendingUpDay30Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
    <text x="22" y="22" fontSize="12" textAnchor="end" fill="currentColor">30</text>
  </SvgIcon>
);

const Item = ({ title, to, icon, selected, setSelected, setIsCollapsed, isCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = window.location.pathname;
  const isActive = selected === title || (to && location === to);
  const { user } = useAuth();
  
  const handleClick = () => {
    setSelected(title);
    
    // Log the menu item click with a concise one-line statement
    logEvent(user?.email || 'unknown', 'sidebar', 'menu_click', { item: title, path: to });
    
    // Handle navigation for regular links
    if (to && to !== "#") {
      navigate(to);
    }
    
    // Only collapse sidebar on mobile or if explicitly requested
    if (typeof setIsCollapsed === 'function' && window.innerWidth < 768) {
      setIsCollapsed(true);
    }
  };
  
  const menuItem = (
    <MenuItem
      active={isActive}
      style={{ 
        color: isActive ? colors.greenAccent[400] : colors.grey[100],
        height: "28px",
        fontWeight: isActive ? 'bold' : 'normal',
      }}
      onClick={handleClick}
      icon={icon}
    >
      {!isCollapsed && (
        <Typography 
          variant="body2" 
          sx={{ 
            fontWeight: isActive ? 'bold' : 'normal',
            color: isActive ? colors.greenAccent[400] : 'inherit'
          }}
        >
          {title}
        </Typography>
      )}
      {to && to !== "#" && <Link to={to} />}
    </MenuItem>
  );

  return isCollapsed ? (
    <Tooltip title={title} placement="right">
      {menuItem}
    </Tooltip>
  ) : menuItem;
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const [showIcons, setShowIcons] = useState(false);
  const [isCatalogingExpanded, setIsCatalogingExpanded] = useState(false);
  const [isExperimentalExpanded, setIsExperimentalExpanded] = useState(false);
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const scrollContainerRef = useRef(null);

  // Define all menu items for filtering
  const allMenuItems = useMemo(() => [
    { title: "Lines Details", to: "/catalog_lines/list", icon: <CategoryIcon />, category: "Cataloging" },
    { title: "Size Variations", to: "/catalog_size_variations/list", icon: <FormatSizeIcon />, category: "Cataloging" },
    { title: "Grouping", to: "/catalog_tools/list", icon: <GroupWorkIcon />, category: "Cataloging" },
    { title: "Listing Logs", to: "/logs/list", icon: <HistoryIcon />, category: "Cataloging" },
    { title: "Digital Asset Management", to: "/dam", icon: <ImageIcon />, category: "Cataloging" },
    { title: "DAM Folder", to: "/dam_folder", icon: <FolderIcon />, category: "Cataloging" },
    { title: "SKU Details", to: "/cosp", icon: <InventoryIcon />, category: "Marketing" },
    { title: "Line Details", to: "/line_details", icon: <LineDetailsIcon />, category: "Marketing" },
    { title: "Line Historic", to: "/line_monthly", icon: <TrendingUpDay30Icon />, category: "Marketing" },
    { title: "Campaign Changes", to: "/campaign_changes", icon: <CampaignIcon />, category: "Marketing" },
    { title: "Returns Reasons", to: "/returns", icon: <AssignmentReturnIcon />, category: "Marketing" },
    { title: "Returns Analysis", to: "/Returns_Analysis", icon: <AssignmentReturnOutlinedIcon/>, category: "Marketing" },
    { title: "Keyword Historic", to: "/keyword_historic", icon: <HistoryIcon />, category: "Marketing" },
    { title: "Product Lines", to: "/product_lines", icon: <InventoryIcon />, category: "Marketing" },
    { title: "ASIN Search", to: "/asin_search", icon: <SearchIcon />, category: "Marketing" },
    { title: "Competitor Search", to: "/competitor_search", icon: <SearchIcon />, category: "Tools" },
    { title: "Index Checker", to: "/index_checker", icon: <CheckCircleIcon />, category: "Tools" },
    { title: "Variations", to: "/variations/list", icon: <ShoppingCartIcon />, category: "Tools" },
    { title: "Sales Prediction", to: "/sp", icon: <BarChartIcon />, category: "Operations" },
    { title: "PO Checks", to: "/po_checks", icon: <LocalShippingIcon />, category: "Operations" },
    { title: "Line Fulfilment Fee", to: "/fulfilment_fee/line", icon: <LocalShippingIcon />, category: "Operations" },
    { title: "SKU Fulfilment Fee", to: "/fulfilment_fee/sku", icon: <LocalShippingIcon />, category: "Operations" },
    { title: "SB Dashboard", to: "/sb_dashboard", icon: <BarChartIcon />, category: "Operations" },
    { title: "Analytics", to: "/analytics", icon: <InsightsIcon />, category: "Operations" },
    { title: "Keyword Explorer", to: "/keyword_explorer", icon: <ExploreIcon />, category: "Experimental" },
    { title: "Repricing", to: "/repricing", icon: <AttachMoneyIcon />, category: "Experimental" },
    { title: "Monthly Report", to: "/monthly-report", icon: <AssessmentOutlinedIcon />, category: "Experimental" },
    { title: "Pricing", to: "/fulfilment_fee/pricing_fee_preview", icon: <LocalShippingIcon />, category: "Experimental" },
    { title: "Scratchpad Feed", to: "/scratchpad-feed", icon: <FeedIcon />, category: "Experimental" },
    { title: "Campaigns", to: "/campaigns", icon: <CampaignIcon />, category: "Experimental" },
    { title: "SEO Module", to: "/seo_module", icon: <SearchIcon />, category: "Experimental" },
    { title: "ETL CSV Report", to: "/etl_business_report", icon: <UploadFileIcon />, category: "Experimental" },
    { title: "Line Control Panel", to: "/line-control-panel", icon: <SettingsApplicationsOutlinedIcon />, category: "Experimental" },
    { title: "Tasks Monitor", to: "/tasks", icon: <ListAltOutlinedIcon />, category: "Experimental" },
    { title: "Keyword Spends", to: "/keyword-spends", icon: <MonetizationOnOutlinedIcon />, category: "Experimental" },
    { title: "Product Line Info", to: "/product-line-info", icon: <InfoOutlinedIcon />, category: "Experimental" },
  ], []);

  // Filter menu items based on search term
  const filteredMenuItems = useMemo(() => {
    if (!searchTerm.trim()) return null;
    
    return allMenuItems.filter(item => 
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, allMenuItems]);

  // Effect to handle scrolling when sections expand
  useEffect(() => {
    // When any section expands, check if scrollbar needs to be updated
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      // Force a recalculation of the scrollbar
      scrollContainer.style.overflow = 'hidden';
      setTimeout(() => {
        scrollContainer.style.overflow = 'auto';
      }, 10);
    }
  }, [isCatalogingExpanded, isExperimentalExpanded, isSettingsExpanded]);

  // Effect to set the selected item based on current location
  useEffect(() => {
    const currentPath = window.location.pathname;
    const matchingItem = allMenuItems.find(item => item.to === currentPath);
    if (matchingItem) {
      setSelected(matchingItem.title);
    }
  }, [allMenuItems]);

  const handleLogout = () => {
    logEvent(user?.email || 'unknown', 'sidebar', 'menu_click', { item: 'Logout' });
    logout(() => navigate('/login'));
  };

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };

  const toggleCataloging = () => {
    setIsCatalogingExpanded(!isCatalogingExpanded);
  };

  const toggleExperimental = () => {
    setIsExperimentalExpanded(!isExperimentalExpanded);
  };

  const toggleSettings = () => {
    setIsSettingsExpanded(!isSettingsExpanded);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  // Function to render filtered menu items
  const renderFilteredItems = () => {
    if (!filteredMenuItems || filteredMenuItems.length === 0) return null;
    
    return (
      <Box paddingLeft={isCollapsed ? undefined : "10%"} sx={{ mt: 2 }}>
        <Typography variant="h6" fontWeight='bold' color={colors.grey[300]} sx={{ m: "5px 0 5px 5px", fontSize: isCollapsed ? '11px' : '15px' }}>
          Search Results
        </Typography>
        {filteredMenuItems.map((item, index) => (
          <Item 
            key={`filtered-${index}`}
            title={item.title} 
            to={item.to} 
            icon={item.icon} 
            selected={selected} 
            setSelected={setSelected} 
            setIsCollapsed={setIsCollapsed} 
            isCollapsed={isCollapsed} 
          />
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{
      "& .pro-sidebar-inner": { 
        background: `${colors.primary[400]} !important`,
        width: isCollapsed ? "60px" : "230px",
        height: "100vh",
        display: "flex",
        flexDirection: "column"
      },
      "& .pro-sidebar": {
        width: isCollapsed ? "60px !important" : "240px !important",
        minWidth: isCollapsed ? "60px !important" : "240px !important",
        height: "100vh"
      },
      "& .pro-sidebar-content": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden"
      },
      "& .pro-menu": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "visible"
      },
      "& .pro-icon-wrapper": { 
        backgroundColor: "transparent !important",
        minWidth: "30px !important",
        fontSize: "18px"
      },
      "& .pro-inner-item": { 
        padding: isCollapsed ? "1px 5px 1px 10px !important" : "1px 10px 1px 10px !important",
        height: "28px !important"
      },
      "& .pro-inner-item:hover": { color: "#868dfb !important" },
      "& .pro-menu-item.active": { color: "#6870fa !important" },
    }}>
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem onClick={() => setIsCollapsed(!isCollapsed)} icon={isCollapsed ? <MenuOutlinedIcon /> : undefined} style={{ margin: isCollapsed ? "0" : "5px 0 2px 10px", color: colors.grey[100] }}>
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="1px">
                <Typography variant="h4" color={colors.grey[100]}>Encasa HQ</Typography>
                <IconButton size="small" onClick={(e) => {
                  e.stopPropagation(); // Prevent double triggering
                  setIsCollapsed(true);
                }}><MenuOutlinedIcon /></IconButton>
              </Box>
            )}
          </MenuItem>

          {/* Search Box */}
          {!isCollapsed && (
            <Box px={1} py={0.5}>
              <TextField
                fullWidth
                size="small"
                placeholder="Search menu..."
                value={searchTerm}
                onChange={handleSearchChange}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" sx={{ color: colors.grey[100] }} />
                    </InputAdornment>
                  ),
                  style: { 
                    fontSize: '0.85rem',
                    backgroundColor: colors.primary[400],
                    color: colors.grey[100],
                    borderRadius: '4px',
                    height: '32px'
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: colors.grey[500] },
                    '&:hover fieldset': { borderColor: colors.grey[400] },
                    '&.Mui-focused fieldset': { borderColor: colors.greenAccent[500] },
                  },
                  '& .MuiInputLabel-root': { color: colors.grey[100] },
                  '& .MuiInputBase-input': { 
                    color: colors.grey[100],
                    padding: '6px 8px 6px 0'
                  },
                  '& .MuiInputBase-input::placeholder': { color: colors.grey[300], opacity: 1 },
                  '& .MuiInputAdornment-root .MuiSvgIcon-root': { color: colors.grey[100], fontSize: '18px' },
                }}
              />
            </Box>
          )}

          {/* Scrollable Menu Container */}
          <Box 
            ref={scrollContainerRef}
            sx={{ 
              flexGrow: 1, 
              overflowY: "auto", 
              overflowX: "hidden",
              display: "flex",
              flexDirection: "column",
              maxHeight: isCollapsed ? "calc(100vh - 60px)" : "calc(100vh - 100px)",
              "&::-webkit-scrollbar": {
                width: "6px",
                display: "block",
              },
              "&::-webkit-scrollbar-track": {
                background: colors.primary[400],
              },
              "&::-webkit-scrollbar-thumb": {
                background: colors.grey[500],
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: colors.grey[400],
              },
              paddingBottom: "20px",
              scrollbarWidth: "thin",
              scrollbarColor: `${colors.grey[500]} ${colors.primary[400]}`,
              "&::-webkit-scrollbar-thumb:vertical": {
                minHeight: "30px",
              },
            }}
            className="sidebar-scrollable-container"
          >
            {/* Filtered Results */}
            {renderFilteredItems()}

            {/* Only show regular menu if not filtering or if collapsed */}
            {(!searchTerm.trim() || isCollapsed) && (
              <>
                {/* Cataloging */}
                <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: "8px 0 2px 5px", cursor: 'pointer', pr: 2 }} onClick={toggleCataloging}>
                    <Typography variant="subtitle2" fontWeight="bold" color={colors.grey[300]} sx={{ 
                      fontSize: isCollapsed ? '10px' : '13px', 
                      transition: 'font-size 0.3s ease'
                    }}>Cataloging</Typography>
                    {!isCollapsed && (<Box sx={{ color: '#808080' }}>{isCatalogingExpanded ? '▼' : '►'}</Box>)}
                  </Box>
                
                  {isCatalogingExpanded && (
                    <Box paddingLeft={isCollapsed ? undefined : "0%"}>
                      <Item title="Lines Details" to="/catalog_lines/list" icon={<CategoryIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                      <Item title="Size Variations" to="/catalog_size_variations/list" icon={<FormatSizeIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                      <Item title="Grouping" to="/catalog_tools/list" icon={<GroupWorkIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                      <Item title="Listing Logs" to="/logs/list" icon={<HistoryIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                      <Item title="Digital Asset Management" to="/dam" icon={<ImageIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                      <Item title="DAM Folder" to="/dam_folder" icon={<FolderIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    </Box>
                  )}
                </Box>

                {/* Marketing */}
                <Typography variant="subtitle2" fontWeight='bold' color={colors.grey[300]} sx={{ 
                  m: "8px 0 2px 5px", 
                  fontSize: isCollapsed ? '10px' : '13px', 
                  transition: 'font-size 0.3s ease'
                }}>Marketing</Typography>

                <Item title="SKU Details" to="/cosp" icon={<InventoryIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Line Details" to="/line_details" icon={<LineDetailsIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Line Historic" to="/line_monthly" icon={<TrendingUpDay30Icon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Campaign Changes" to="/campaign_changes" icon={<CampaignIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Returns Reasons" to="/returns" icon={<AssignmentReturnIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Returns Analysis" to="/Returns_Analysis" icon={<AssignmentReturnOutlinedIcon/>} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Keyword Historic" to="/keyword_historic" icon={<HistoryIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Product Lines" to="/product_lines" icon={<InventoryIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />    
                <Item title="ASIN Spends" to="/asin_search" icon={<SearchIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />            
                <Item title="Keyword Spends" to="/keyword-spends" icon={<MonetizationOnOutlinedIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                
                {/* Tools */}
                <Typography variant="subtitle2" fontWeight='bold' color={colors.grey[300]} sx={{ 
                  m: "8px 0 2px 5px", 
                  fontSize: isCollapsed ? '10px' : '13px', 
                  transition: 'font-size 0.3s ease'
                }}>Tools</Typography>

                <Item title="Competitor Search" to="/competitor_search" icon={<SearchIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Index Checker" to="/index_checker" icon={<CheckCircleIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Variations" to="/variations/list" icon={<ShoppingCartIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />

                {/* Operations */}
                <Typography variant="subtitle2" fontWeight='bold' color={colors.grey[300]} sx={{ 
                  m: "8px 0 2px 5px", 
                  fontSize: isCollapsed ? '10px' : '13px', 
                  transition: 'font-size 0.3s ease'
                }}>Operations</Typography>

                <Item title="Sales Prediction" to="/sp" icon={<BarChartIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="PO Checks" to="/po_checks" icon={<LocalShippingIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Line Fulfilment Fee" to="/fulfilment_fee/line" icon={<LocalShippingIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="SKU Fulfilment Fee" to="/fulfilment_fee/sku" icon={<LocalShippingIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="SB Dashboard" to="/sb_dashboard" icon={<BarChartIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                
                {/* Experimental */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: "8px 0 2px 5px", cursor: 'pointer', pr: 2 }} onClick={toggleExperimental}>
                  <Typography variant="subtitle2" fontWeight="bold" color={colors.grey[300]} sx={{ 
                    fontSize: isCollapsed ? '10px' : '13px', 
                    transition: 'font-size 0.3s ease'
                  }}>Experimental</Typography>
                  {!isCollapsed && (<Box sx={{ color: '#808080' }}>{isExperimentalExpanded ? '▼' : '►'}</Box>)}
                </Box>
              
                {isExperimentalExpanded && (
                  <Box paddingLeft={isCollapsed ? undefined : "0%"}>
                    <Item title="Keyword Explorer" to="/keyword_explorer" icon={<ExploreIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    <Item title="Repricing" to="/repricing" icon={<AttachMoneyIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    <Item title="Monthly Report" to="/monthly-report" icon={<AssessmentOutlinedIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    <Item title="Pricing" to="/fulfilment_fee/pricing_fee_preview" icon={<LocalShippingIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    <Item title="Scratchpad Feed" to="/scratchpad-feed" icon={<FeedIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    <Item title="Campaigns" to="/campaigns" icon={<CampaignIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    <Item title="SEO Module" to="/seo_module" icon={<SearchIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    <Item title="ETL CSV Report" to="/etl_business_report" icon={<UploadFileIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    <Item title="Line Control Panel" to="/line-control-panel" icon={<SettingsApplicationsOutlinedIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    <Item title="Tasks Monitor" to="/tasks" icon={<ListAltOutlinedIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                    <Item title="Product Line Info" to="/product-line-info" icon={<InfoOutlinedIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                  </Box>
                )}
                
                {/* Settings Section */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: "8px 0 2px 5px", cursor: 'pointer', pr: 2 }} onClick={toggleSettings}>
                  <Typography variant="subtitle2" fontWeight="bold" color={colors.grey[300]} sx={{ 
                    fontSize: isCollapsed ? '10px' : '13px', 
                    transition: 'font-size 0.3s ease'
                  }}>Settings</Typography>
                  {!isCollapsed && (<Box sx={{ color: '#808080' }}>{isSettingsExpanded ? '▼' : '►'}</Box>)}
                </Box>
              
                {isSettingsExpanded && (
                  <Box paddingLeft={isCollapsed ? undefined : "0%"}>
                    <Item 
                      title="Toggle Theme" 
                      to="#" 
                      icon={theme.palette.mode === 'dark' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />} 
                      selected={selected} 
                      setSelected={() => {
                        colorMode.toggleColorMode();
                        setSelected("Toggle Theme");
                      }} 
                      setIsCollapsed={setIsCollapsed} 
                      isCollapsed={isCollapsed} 
                    />
                    <Item 
                      title="Clear Cache" 
                      to="/cache-clearing" 
                      icon={<CachedIcon />} 
                      selected={selected} 
                      setSelected={setSelected} 
                      setIsCollapsed={setIsCollapsed} 
                      isCollapsed={isCollapsed} 
                    />
                    <Item 
                      title="Logout" 
                      to="#" 
                      icon={<ExitToAppIcon />} 
                      selected={selected} 
                      setSelected={() => {
                        handleLogout();
                        setSelected("Logout");
                      }} 
                      setIsCollapsed={setIsCollapsed} 
                      isCollapsed={isCollapsed} 
                    />
                    <Item title="Usage Analytics" to="/analytics" icon={<InsightsIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
