import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Modal, IconButton, TextField } from '@mui/material';
import { CalendarToday, NavigateBefore, NavigateNext } from '@mui/icons-material';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs with plugins
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

const DateRangePicker = ({ 
  startDate, 
  endDate, 
  onDateChange, 
  compareMode = false,
  previousStartDate = null,
  previousEndDate = null,
  onPreviousDateChange = null
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate ? dayjs(startDate) : dayjs().subtract(30, 'day'));
  const [selectedEndDate, setSelectedEndDate] = useState(endDate ? dayjs(endDate) : dayjs().subtract(1, 'day'));
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [nextMonth, setNextMonth] = useState(dayjs().add(1, 'month'));
  const [selectionMode, setSelectionMode] = useState('start'); // 'start' or 'end'
  const [presetOptions, setPresetOptions] = useState([
    { label: 'Today', action: () => handlePresetSelect(dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')) },
    { label: 'Yesterday', action: () => handlePresetSelect(dayjs().subtract(2, 'day'), dayjs().subtract(2, 'day')) },
    { label: 'This Week', action: () => handlePresetSelect(dayjs().subtract(dayjs().day() + 1, 'day'), dayjs().subtract(1, 'day')) },
    { label: 'Last 7 Days', action: () => handlePresetSelect(dayjs().subtract(8, 'day'), dayjs().subtract(1, 'day')) },
    { label: 'Last 30 Days', action: () => handlePresetSelect(dayjs().subtract(31, 'day'), dayjs().subtract(1, 'day')) },
    { label: 'Last 90 Days', action: () => handlePresetSelect(dayjs().subtract(91, 'day'), dayjs().subtract(1, 'day')) },
    { label: 'This Month', action: () => handlePresetSelect(dayjs().startOf('month'), dayjs().subtract(1, 'day')) },
    { label: 'Last Month', action: () => {
      const lastMonthStart = dayjs().subtract(1, 'month').startOf('month');
      const lastMonthEnd = dayjs().subtract(1, 'month').endOf('month');
      handlePresetSelect(lastMonthStart, lastMonthEnd);
    }},
  ]);
  
  useEffect(() => {
    if (startDate && endDate) {
      setSelectedStartDate(dayjs(startDate));
      setSelectedEndDate(dayjs(endDate));
    }
  }, [startDate, endDate]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handlePresetSelect = (start, end) => {
    setSelectedStartDate(start);
    setSelectedEndDate(end);
    setSelectionMode('end');
  };

  const handleDayClick = (day) => {
    if (selectionMode === 'start') {
      setSelectedStartDate(day);
      setSelectionMode('end');
    } else {
      // Ensure end date is not before start date
      if (day.isBefore(selectedStartDate)) {
        setSelectedStartDate(day);
        setSelectedEndDate(day);
      } else {
        setSelectedEndDate(day);
      }
      setSelectionMode('start');
    }
  };

  const handleSave = () => {
    onDateChange(selectedStartDate.toDate(), selectedEndDate.toDate());
    handleClose();
  };

  const handleCancel = () => {
    // Reset to original values
    setSelectedStartDate(startDate ? dayjs(startDate) : dayjs().subtract(30, 'day'));
    setSelectedEndDate(endDate ? dayjs(endDate) : dayjs().subtract(1, 'day'));
    handleClose();
  };

  const navigatePreviousMonth = () => {
    setCurrentMonth(prevMonth => {
      const newMonth = prevMonth.subtract(1, 'month');
      setNextMonth(prevMonth);
      return newMonth;
    });
  };

  const navigateNextMonth = () => {
    setNextMonth(prevMonth => {
      const newMonth = prevMonth.add(1, 'month');
      setCurrentMonth(prevMonth);
      return newMonth;
    });
  };

  const renderCalendar = (month) => {
    const monthStart = month.startOf('month');
    const monthName = month.format('MMMM');
    const year = month.format('YYYY');
    
    // Get days in month
    const daysInMonth = month.daysInMonth();
    
    // Get day of week for first day (0 = Sunday, 6 = Saturday)
    const firstDayOfWeek = monthStart.day();
    
    // Create array of day numbers with empty slots for padding
    const days = Array(firstDayOfWeek).fill(null);
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }
    
    // Create weeks
    const weeks = [];
    let week = [];
    
    days.forEach((day, index) => {
      if (index > 0 && index % 7 === 0) {
        weeks.push(week);
        week = [];
      }
      week.push(day);
      if (index === days.length - 1) {
        // Add padding to the last week if needed
        while (week.length < 7) {
          week.push(null);
        }
        weeks.push(week);
      }
    });
    
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
          <Typography variant="h6">{`${monthName} ${year}`}</Typography>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', textAlign: 'center' }}>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <Typography key={day} sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
              {day}
            </Typography>
          ))}
          
          {weeks.map((week, weekIndex) => (
            <React.Fragment key={`week-${weekIndex}`}>
              {week.map((day, dayIndex) => {
                if (day === null) {
                  return <Box key={`empty-${weekIndex}-${dayIndex}`} />;
                }
                
                const date = month.date(day);
                const isStart = date.format('YYYY-MM-DD') === selectedStartDate.format('YYYY-MM-DD');
                const isEnd = date.format('YYYY-MM-DD') === selectedEndDate.format('YYYY-MM-DD');
                const isInRange = date.isAfter(selectedStartDate, 'day') && date.isBefore(selectedEndDate, 'day') || isStart || isEnd;
                
                return (
                  <Box 
                    key={`day-${weekIndex}-${dayIndex}`}
                    onClick={() => handleDayClick(date)}
                    sx={{
                      cursor: 'pointer',
                      padding: '4px',
                      borderRadius: '50%',
                      backgroundColor: isStart || isEnd ? '#1976d2' : isInRange ? '#bbdefb' : 'transparent',
                      color: isStart || isEnd ? 'white' : 'inherit',
                      '&:hover': {
                        backgroundColor: isStart || isEnd ? '#1565c0' : '#e3f2fd',
                      },
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '30px',
                      width: '30px',
                      margin: '2px auto',
                    }}
                  >
                    <Typography variant="body2">{day}</Typography>
                  </Box>
                );
              })}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<CalendarToday />}
        onClick={handleOpen}
        sx={{ 
          border: '1px solid #ccc', 
          borderRadius: '4px',
          padding: '8px 16px',
          textTransform: 'none',
          color: 'black',
          backgroundColor: '#f5f5f5',
          '&:hover': {
            backgroundColor: '#e0e0e0',
          }
        }}
      >
        {selectedStartDate.format('DD-MM-YYYY')} - {selectedEndDate.format('DD-MM-YYYY')}
        {compareMode && previousStartDate && previousEndDate && (
          <Typography variant="caption" sx={{ ml: 1 }}>
            compared to {dayjs(previousStartDate).format('DD-MM-YYYY')} - {dayjs(previousEndDate).format('DD-MM-YYYY')}
          </Typography>
        )}
      </Button>

      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="date-range-picker-modal"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          width: '800px',
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: '8px',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box sx={{ 
              width: '200px', 
              backgroundColor: '#f8f9fa', 
              p: 2, 
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
            }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#1976d2' }}>Presets</Typography>
              {presetOptions.map((option, index) => (
                <Button 
                  key={index} 
                  onClick={option.action}
                  sx={{ 
                    display: 'block', 
                    textAlign: 'left', 
                    width: '100%', 
                    mb: 1,
                    justifyContent: 'flex-start',
                    color: '#424242',
                    '&:hover': {
                      backgroundColor: '#e3f2fd',
                      color: '#1976d2'
                    }
                  }}
                >
                  {option.label}
                </Button>
              ))}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, ml: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField 
                    value={selectedStartDate.format('MMM DD, YYYY')}
                    variant="outlined"
                    size="small"
                    InputProps={{ readOnly: true }}
                    sx={{ width: '150px' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField 
                    value={selectedEndDate.format('MMM DD, YYYY')}
                    variant="outlined"
                    size="small"
                    InputProps={{ readOnly: true }}
                    sx={{ width: '150px' }}
                  />
                </Box>
              </Box>

              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                backgroundColor: '#fff',
                borderRadius: '8px',
                p: 2,
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={navigatePreviousMonth} sx={{ color: '#1976d2' }}>
                    <NavigateBefore />
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-around' }}>
                  {renderCalendar(currentMonth)}
                  {renderCalendar(nextMonth)}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={navigateNextMonth} sx={{ color: '#1976d2' }}>
                    <NavigateNext />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button 
              onClick={handleCancel} 
              sx={{ 
                mr: 2,
                color: '#616161',
                '&:hover': {
                  backgroundColor: '#f5f5f5'
                }
              }}
            >
              CANCEL
            </Button>
            <Button 
              onClick={handleSave} 
              variant="contained" 
              color="primary"
              sx={{
                fontWeight: 'bold',
                px: 3
              }}
            >
              SAVE
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DateRangePicker; 