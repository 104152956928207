import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect, useCallback } from "react";
import Header from "../../components/Header";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
import AGGrid_Options from "../../components/global/AGGrid_Options";
import AGGrid_Sidebar from "../../components/global/AGGrid_Sidebar";
import ProductLineInfo from "../product_line_info/product_line_info";

const LineControlPanel = () => {
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedLine, setSelectedLine] = useState(null);

  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/line-details/get-countries-lines', fetchOptions);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          const transformedData = data.map(item => ({
            country: item.country,
            line: item.line
          }));
          setRowData(transformedData);
        } else {
          console.error("Expected array but got:", data);
          setRowData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load lines: " + error.message);
        setRowData([]);
      }
    };

    fetchData();
  }, []);

  const columnDefs = [
    { 
      field: "country", 
      headerName: "Country", 
      filter: true,
      width: 150,
      suppressSizeToFit: true
    },
    { 
      field: "line", 
      headerName: "Line", 
      filter: true,
      width: 150,
      suppressSizeToFit: true
    },
    {
      headerName: "KYP",
      width: 150,
      suppressSizeToFit: true,
      cellRenderer: (params) => {
        if (!params.data) return null;
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setSelectedLine(params.data);
                setDrawerOpen(true);
              }}
            >
              EDIT
            </Button>
          </Box>
        );
      }
    },
    {
      headerName: "Keywords",
      width: 150,
      suppressSizeToFit: true,
      cellRenderer: (params) => {
        if (!params.data) return null;
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="contained"
              size="small"
              component="a"
              href={`/keyword-spends?country=${params.data.country}&line=${params.data.line}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              EDIT
            </Button>
          </Box>
        );
      }
    }
  ];

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  const clearFilters = useCallback(() => {
    if (gridApi) {
      gridApi.setFilterModel(null);
      toast.success("Filters cleared successfully");
    }
  }, [gridApi]);

  const handleRunClassification = async (rowData) => {
    try {
      // Show initial toast
      const toastId = toast.loading("Starting classification...");
      
      const response = await fetch("/api/keyword_classifier/classify_line_keywords", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          country: rowData.country,
          line: rowData.line
        })
      });

      const data = await response.json();
      console.log("Classification response:", data);

      if (response.ok) {
        // Update the loading toast to indicate task is queued
        toast.update(toastId, {
          render: "Keyword classification task has been queued. Check Tasks Monitor for status.",
          type: "success",
          isLoading: false,
          autoClose: 5000
        });
      } else {
        // Update the loading toast with error message
        toast.update(toastId, {
          render: data.message || "Failed to queue classification",
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error: " + error.message);
    }
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Line Control Panel</Typography>
        <Button 
          variant="outlined" 
          color="primary" 
          onClick={clearFilters}
          disabled={!gridApi}
        >
          Clear Filters
        </Button>
      </Box>
      
      <Box
        className="ag-theme-alpine"
        sx={{
          width: "100%",
          height: "75vh",
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          {...AGGrid_Options}
          sideBar={AGGrid_Sidebar}
          rowHeight={30}
        />
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '80%',
            maxWidth: '1000px',
            boxSizing: 'border-box',
          },
        }}
      >
        {selectedLine && (
          <ProductLineInfo
            initialCountry={selectedLine.country}
            initialLine={selectedLine.line}
            onClose={() => setDrawerOpen(false)}
          />
        )}
      </Drawer>
    </Box>
  );
};

export default LineControlPanel; 