export const getRatioValue = (numeratorField, denominatorField) => ({
  aggFunc: params => {
    let totalNumerator = 0, totalDenominator = 0;
    params.values.forEach(value => { 
      if (value && typeof value === 'object') { 
        totalNumerator += value[numeratorField] || 0; 
        totalDenominator += value[denominatorField] || 0; 
      } 
    });
    return { 
      [numeratorField]: totalNumerator, 
      [denominatorField]: totalDenominator, 
      ratio: totalDenominator > 0 ? totalNumerator / totalDenominator : 0 
    };
  },
  valueGetter: params => {
    if (!params.data) return { ratio: null };
    const numerator = params.data[numeratorField] || 0;
    const denominator = params.data[denominatorField] || 0;
    return { 
      [numeratorField]: numerator, 
      [denominatorField]: denominator, 
      ratio: denominator > 0 ? numerator / denominator : null 
    };
  }
});