import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FlagIcon from '@mui/icons-material/Flag';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import axios from 'axios';
import { useAuth } from '../../components/auth/AuthContext';
import { logEvent } from '../../utils/logEvent';
import AGGrid_Options from '../../components/global/AGGrid_Options';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar';

/*
================================================================================
FLAG & HIGHLIGHTING CONDITIONS
================================================================================

1. QUANTITY-BASED EXCLUSION RULES (overrides all other conditions):
   - Amazon IN: Do NOT flag if PO Qty <= 5
   - Other regions: Do NOT flag if PO Qty <= 10

2. RED FLAG CONDITIONS (if any of these are true AND not excluded by rule #1):
   - Last Sold Days > 7 AND FBA Stock > 0 
   - DOC value exceeds threshold:
     * Amazon IN: DOC >= 80
     * Other regions: DOC >= 150
   - Discount is between -40% and -20% inclusive (>= -40 and <= -20)
   - Listing Issue field is not empty

3. CELL HIGHLIGHTING RULES:
   - Last Sold Days cell: Highlighted red if value > 7 AND FBA Stock > 0
   - FBA Stock cell: Highlighted red if Last Sold Days > 7 AND value > 0
   - DOC cell: Highlighted red if:
     * Amazon IN: value >= 80
     * Other regions: value >= 150
   - Discount cell: Highlighted red if value >= -40 and <= -20
   - Listing Issue cell: Highlighted red if not empty
================================================================================
*/

const PODetails = () => {
  const { shipment_id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shipmentInfo, setShipmentInfo] = useState(null);
  const gridRef = useRef(null);

  // Format date helper function
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Fetch data when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/get_po_check_details', { params: { shipment_id } });
        
        if (response.data && response.data.length > 0) {
          console.log("API Response Data Keys:", Object.keys(response.data[0]));
          console.log("Cat values in data:", response.data.map(row => row.cat).filter(Boolean));
          
          setRowData(response.data);
          setShipmentInfo({
            dispatch_date: response.data[0].dispatch_date,
            region: response.data[0].region,
            status: response.data[0].status,
            production: response.data[0].production,
            mode: response.data[0].mode
          });
        }
        
        setLoading(false);
        
        // Log page view
        logEvent(user?.email || 'unknown', 'po_details', 'page_view', { shipment_id });
      } catch (err) {
        console.error("Error fetching PO details:", err);
        setError(err.message);
        setLoading(false);
      }
    };
    
    fetchData();
  }, [shipment_id, user]);

  // Custom cell renderer for flag column
  const flagCellRenderer = (params) => {
    const row = params.data;
    if (!row) return null;
    
    // New quantity-based exclusion rules - Don't flag if quantity is small
    if ((row.region === 'Amazon IN' && row.quantity <= 5) || 
        (row.region !== 'Amazon IN' && row.quantity <= 10)) {
      return null; // Don't flag small quantities regardless of other conditions
    }
    
    // Check conditions for showing flag
    const conditions = [
      (row.last_sold_days !== null && row.last_sold_days !== undefined && row.last_sold_days > 7 && row.fba_stock > 0),
      (row.doc !== null && row.doc !== undefined && 
        ((row.region === 'Amazon IN' && row.doc >= 80) || 
         (row.region !== 'Amazon IN' && row.doc >= 150))),
      (row.discount !== null && row.discount !== undefined && row.discount >= -40 && row.discount <= -20),
      (row.listing_issue !== null && row.listing_issue !== undefined && row.listing_issue.trim() !== '')
    ];
    
    console.log('Flag conditions for row:', row);
    console.log('PO Qty exclusion applies:', (row.region === 'Amazon IN' && row.quantity <= 5) || (row.region !== 'Amazon IN' && row.quantity <= 10));
    console.log('Condition 1 (Last Sold Days > 7 and FBA Stock > 0):', row.last_sold_days > 7 && row.fba_stock > 0);
    console.log('Condition 2 (DOC > 150):', row.doc > 150);
    console.log('Condition 3 (Discount between -40% and -20%):', row.discount >= -40 && row.discount <= -20);
    console.log('Condition 4 (Listing Issue is not blank):', row.listing_issue && row.listing_issue.trim() !== '');
    
    if (conditions.some(condition => condition)) {
      // Build tooltip text
      const reasons = [];
      if (row.last_sold_days !== null && row.last_sold_days !== undefined && row.last_sold_days > 7 && row.fba_stock > 0) {
        reasons.push(`Last sold: ${row.last_sold_days} days, FBA stock: ${row.fba_stock}`);
      }
      if (row.doc !== null && row.doc !== undefined) {
        if (row.region === 'Amazon IN' && row.doc >= 80) {
          reasons.push(`DOC (Amazon IN): ${row.doc} days (≥80)`);
        } else if (row.region !== 'Amazon IN' && row.doc >= 150) {
          reasons.push(`DOC (${row.region}): ${row.doc} days (≥150)`);
        }
      }
      if (row.discount !== null && row.discount !== undefined && row.discount >= -40 && row.discount <= -20) {
        reasons.push(`Discount: ${row.discount}%`);
      }
      if (row.listing_issue !== null && row.listing_issue !== undefined && row.listing_issue.trim() !== '') {
        reasons.push(`Listing Issue: ${row.listing_issue}`);
      }
      
      return (
        <div title={reasons.join('\n')}>
          <FlagIcon style={{ color: '#f44336', fontSize: '20px' }} />
        </div>
      );
    }
    
    return null;
  };

  // Value getter for flag column to make it filterable
  const getFlagValue = (params) => {
    const row = params.data;
    if (!row) return 'No';
    
    // New quantity-based exclusion rules - Don't flag if quantity is small
    if ((row.region === 'Amazon IN' && row.quantity <= 5) || 
        (row.region !== 'Amazon IN' && row.quantity <= 10)) {
      return 'No'; // Don't flag small quantities regardless of other conditions
    }
    
    const conditions = [
      (row.last_sold_days !== null && row.last_sold_days !== undefined && row.last_sold_days > 7 && row.fba_stock > 0),
      (row.doc !== null && row.doc !== undefined && 
        ((row.region === 'Amazon IN' && row.doc >= 80) || 
         (row.region !== 'Amazon IN' && row.doc >= 150))),
      (row.discount !== null && row.discount !== undefined && row.discount >= -40 && row.discount <= -20),
      (row.listing_issue !== null && row.listing_issue !== undefined && row.listing_issue.trim() !== '')
    ];
    
    return conditions.some(condition => condition) ? 'Yes' : 'No';
  };

  // Column definitions with cell styling
  const columnDefs = useMemo(() => {
    // Base columns that are always shown
    const baseColumns = [
      { headerName: "Warning", field: "flag", width: 80, cellRenderer: flagCellRenderer, valueGetter: getFlagValue, filter: true, filterParams: { values: ['Yes', 'No'], defaultOption: 'equals' }, pinned: 'left' },
      { headerName: "SKU", field: "sku", width: 120, pinned: 'left' },
      { headerName: "Line", field: "line", width: 80 },
      { headerName: "Color", field: "color", width: 80 },
      { headerName: "Size", field: "size", width: 70 },
      { headerName: "Pcs/Pack", field: "pcs_pack", width: 85, filter: 'agNumberColumnFilter', sortable: true, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        cellStyle: { textAlign: 'right' }
      },
      { headerName: "MA", field: "ma", width: 60, filter: 'agNumberColumnFilter', sortable: true, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 1}) : '',
        cellStyle: { textAlign: 'right' }
      },
      { headerName: "Cat", field: "cat", width: 60, cellStyle: { textAlign: 'center' } },
      { headerName: "FBA Stock", field: "fba_stock", width: 60, filter: 'agNumberColumnFilter', sortable: true, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        cellStyle: { textAlign: 'right' }
      },
      { headerName: "Inbound Qty", field: "shipped_qty", width: 75, filter: 'agNumberColumnFilter', sortable: true,
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        cellStyle: { textAlign: 'right' }
      },
      { headerName: "PO Qty", field: "quantity", width: 75, filter: 'agNumberColumnFilter', sortable: true, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        cellStyle: { textAlign: 'right' }
      },
      { headerName: "Last Sold", field: "last_sold_days", width: 80, filter: 'agNumberColumnFilter', sortable: true, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', 
        cellStyle: params => {
          if (params.value > 7 && params.data?.fba_stock > 0) {
            return { backgroundColor: '#ffd6d6', textAlign: 'right' };
          }
          return { textAlign: 'right' };
        }
      },
      { headerName: "DOC", field: "doc", width: 65, filter: 'agNumberColumnFilter', sortable: true, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        cellStyle: params => {
          if ((params.data?.region === 'Amazon IN' && params.value >= 80) || 
              (params.data?.region !== 'Amazon IN' && params.value >= 150)) {
            return { backgroundColor: '#ffd6d6', textAlign: 'right' };
          }
          return { textAlign: 'right' };
        }
      },
      { headerName: "Disc%", field: "discount", width: 80, filter: 'agNumberColumnFilter', sortable: true, 
        valueFormatter: params => params.value ? `${params.value}%` : '',
        cellStyle: params => {
          if (params.value && params.value >= -40 && params.value <= -20) {
            return { backgroundColor: '#ffd6d6', textAlign: 'right' };
          }
          return { textAlign: 'right' };
        }
      },
      { headerName: "Short LTSF", field: "inv_short_ltsf", width: 70, filter: 'agNumberColumnFilter', sortable: true, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        cellStyle: params => {
          if (params.value > 0) {
            return { backgroundColor: '#ffd6d6', textAlign: 'right' };
          }
          return { textAlign: 'right' };
        }
      },
      { headerName: "Long LTSF", field: "inv_long_ltsf", width: 70, filter: 'agNumberColumnFilter', sortable: true, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        cellStyle: params => {
          if (params.value > 0) {
            return { backgroundColor: '#ffd6d6', textAlign: 'right' };
          }
          return { textAlign: 'right' };
        }
      },
      { headerName: "Listing Issue", field: "listing_issue", width: 130,
        cellStyle: params => {
          if (params.value && params.value.trim() !== '') {
            return { backgroundColor: '#ffd6d6' };
          }
          return null;
        }
      },
      { headerName: "ASIN", field: "asin", width: 120},
      { headerName: "Revised Qty", field: "revised_qty", width: 80, pinned: 'right', editable: true, filter: 'agNumberColumnFilter', sortable: true, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        cellStyle: { textAlign: 'right' }
      },
      { headerName: "Comments", field: "comments", width: 120, pinned: 'right', editable: true },
    ];
    
    // If we have data, add any additional columns dynamically
    if (rowData.length > 0) {
      const firstRow = rowData[0];
      const allColumns = Object.keys(firstRow);
      // Exclude both the base columns and the subtitle columns
      const excludedColumns = [
        'sku', 'asin', 'line', 'color', 'size', 'pcs_pack', 'quantity', 'last_sold_days', 
        'fba_stock', 'doc', 'ma', 'discount', 'listing_issue', 'inv_long_ltsf', 'inv_short_ltsf', 'flag',
        'revised_qty', 'comments', 'cat','asin', 'shipped_qty',
        // Subtitle fields
        'dispatch_date', 'region', 'status', 'production', 'mode',
        'channel_abb'
      ];
      
      const additionalColumns = allColumns
        .filter(col => !excludedColumns.includes(col))
        .map(col => {
          const isNumeric = typeof firstRow[col] === 'number';
          return {
            headerName: col.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
            field: col,
            width: 140,
            ...(isNumeric ? {
              filter: 'agNumberColumnFilter',
              sortable: true,
              valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '',
              cellStyle: { textAlign: 'right' }
            } : {})
          };
        });
      
      return [...baseColumns, ...additionalColumns];
    }
    
    return baseColumns;
  }, [rowData]);

  // Navigation handlers
  const handleBack = () => {
    navigate('/po_checks');
  };

  // Export handler
  const handleExportCSV = () => {
    gridRef.current.api.exportDataAsCsv({
      fileName: `PO_Details_${shipment_id}.csv`
    });
  };

  // Clear filters handler
  const handleClearFilters = () => {
    gridRef.current.api.setFilterModel(null);
  };

  // Header component with shipment details
  const renderHeader = () => {
    if (!shipmentInfo) return null;
    
    return (
      <>
        <Typography variant="h5" component="div" sx={{ fontSize: '1.5rem', fontWeight: 500 }}>
          Shipment: {shipment_id}
        </Typography>
        <Typography variant="subtitle1" component="div" sx={{ 
          color: 'text.secondary',
          fontSize: '1rem',
          mt: 1,
          mb: 2
        }}>
          Dispatch Date: <Typography component="span" sx={{ fontWeight: 800 }}>{formatDate(shipmentInfo.dispatch_date)}</Typography> &nbsp;|&nbsp; 
          Region: <Typography component="span" sx={{ fontWeight: 800 }}>{shipmentInfo.region}</Typography> &nbsp;|&nbsp; 
          Status: <Typography component="span" sx={{ fontWeight: 800 }}>{shipmentInfo.status}</Typography> &nbsp;|&nbsp; 
          Production: <Typography component="span" sx={{ fontWeight: 800 }}>{shipmentInfo.production}</Typography> &nbsp;|&nbsp;
          Mode: <Typography component="span" sx={{ fontWeight: 800 }}>{shipmentInfo.mode}</Typography>
        </Typography>
      </>
    );
  };

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Header with Back and Export buttons */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Button 
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          variant="outlined"
        >
          Back to PO Checks
        </Button>
        
        {!loading && rowData.length > 0 && (
          <Box display="flex" gap={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportCSV}
            >
              Export to CSV
            </Button>
          </Box>
        )}
      </Box>
      
      {/* Loading, error, or no data states */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box sx={{ p: 2, color: 'error.main' }}>
          <Typography variant="body1">Error loading data: {error}</Typography>
        </Box>
      ) : rowData.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">No data available for this shipment</Typography>
        </Box>
      ) : (
        <>
          {/* Shipment header info */}
          {renderHeader()}
          
          {/* AG Grid table */}
          <Box flex={1} sx={{ height: 'calc(100vh - 200px)' }}>
            <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                {...AGGrid_Options}
                sideBar={AGGrid_Sidebar}
                pagination={true}
                paginationPageSize={100}
                suppressRowClickSelection={true}
              />
            </div>
          </Box>
        </>
      )}
    </div>
  );
};

export default PODetails; 