import React, { useState, useEffect, useRef, useMemo } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useAuth } from "../../components/auth/AuthContext";
import { logEvent } from "../../utils/logEvent";
import AGGrid_Options from '../../components/global/AGGrid_Options';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar';

const POChecks = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const gridRef = useRef(null);
  const navigate = useNavigate();

  // Fetch PO Checks data when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch("/api/get_po_checks");
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const jsonData = await response.json();
        setRowData(jsonData);
        setLoading(false);
        
        // Log page load
        logEvent(user?.email || 'unknown', 'po_checks', 'page_load');
      } catch (error) {
        console.error("Error fetching PO checks data:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  // Custom cell renderer for Shipment ID with navigation
  const ShipmentIdCellRenderer = (params) => {
    const handleClick = async () => {
      // Log the navigation event
      await logEvent(user?.email || 'unknown', 'po_checks', 'view_details', { shipment_id: params.value });
      // Navigate to the details page
      navigate(`/po_details/${params.value}`);
    };

    return (
      <span 
        style={{ color: '#1976d2', cursor: 'pointer' }}
        onClick={handleClick}
      >
        {params.value}
      </span>
    );
  };

  // Format date helper function
  const formatDate = (params) => {
    if (!params.value) return '';
    const date = new Date(params.value);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Column definitions
  const columnDefs = useMemo(() => [
    {
      headerName: "Region",
      field: "region",
      sortable: true,
      filter: true,
      width: 150
    },
    {
      headerName: "Shipment ID",
      field: "shipment_id",
      sortable: true,
      filter: true,
      width: 180,
      cellRenderer: ShipmentIdCellRenderer
    },
    {
      headerName: "Quantity",
      field: "total_qty",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 150,
      type: 'numericColumn',
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : ''
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      width: 150
    },
    {
      headerName: "Production",
      field: "production",
      sortable: true,
      filter: true,
      width: 150
    },
    {
      headerName: "Mode",
      field: "mode",
      sortable: true,
      filter: true,
      width: 130
    },
    {
      headerName: "Dispatch Date",
      field: "dispatch_date",
      sortable: true,
      filter: 'agDateColumnFilter',
      width: 180,
      valueFormatter: formatDate
    }
  ], [user, navigate]);

  // Handle export to CSV
  const handleExportCSV = () => {
    gridRef.current.api.exportDataAsCsv({
      fileName: "PO_Checks.csv"
    });
  };

  return (
    <div style={{ padding: '20px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Header with title and export button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Purchase Order Checks</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          {!loading && rowData.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportCSV}
            >
              Export to CSV
            </Button>
          )}
        </Box>
      </Box>

      {/* Loading and error states */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box sx={{ p: 2, color: 'error.main' }}>
          <Typography variant="body1">Error loading data: {error}</Typography>
        </Box>
      ) : rowData.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">No data available</Typography>
        </Box>
      ) : (
        /* AG Grid table */
        <Box flex={1}>
          <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              {...AGGrid_Options}
              sideBar={AGGrid_Sidebar}
              pagination={true}
              paginationPageSize={100}
            />
          </div>
        </Box>
      )}
    </div>
  );
};

export default POChecks; 