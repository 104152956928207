import React from 'react';

export const columns = [
  { headerName : "Product Info",
    children : [
    { headerName: "Ctry", field: "country", sortable: true, filter: true, width: 65 },
    { headerName: "Line", field: "line", sortable: true, filter: true, width: 95 },
    { headerName: "SKU", field: "sku", sortable: true, filter: true, width: 140 },
    { headerName: "Asin", field: "asin", sortable: true, filter: true, width: 130, columnGroupShow: 'open' },
    { headerName: "Feed Product Type", field: "feed_product_type", sortable: true, filter: true, width: 130, columnGroupShow: 'open' },
    ]},
  { headerName : "Stock",
    children : [  
    { headerName: "Risk Flag", field: "risk_flag", sortable: true, filter: true, width: 120 },
    { headerName: "Last Sold Days", field: "last_sold_days", sortable: true, filter: true, width: 100, columnGroupShow: 'open' },
    { headerName: "FBA Stock", field: "fba_stock", sortable: true, filter: 'agNumberColumnFilter', width: 95 },
    { headerName: "MA", field: "ma", sortable: true, filter: 'agNumberColumnFilter', width : 90,cellStyle: { textAlign: 'center'}, columnGroupShow: 'open' },
    { headerName: "DOC", field: "doc", sortable: true, filter: 'agNumberColumnFilter', width: 90, columnGroupShow: 'open' },
    { headerName: "Missed Days", field: "missed_days", sortable: true, filter: true, width: 95 },
    ]},
  { headerName : "LTSF",
      children : [
    { headerName: "Inv At Risk", field: "inv_at_risk", sortable: true, filter: 'agNumberColumnFilter', width: 105, cellStyle: { textAlign: 'center'} },
    { headerName: "Inv Short LTSF", field: "inv_short_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 105, cellStyle: { textAlign: 'center'} },
    { headerName: "Inv Long LTSF", field: "inv_long_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 105, cellStyle: { textAlign: 'center'} },
      ]},
  { headerName : "Pricing",    
    children : [ 
    { headerName: "Live Price", field: "live_price", sortable: true, filter: true, width: 120 },
    { headerName: "CC Price", field: "price_cc", sortable: true, filter: true, width: 120, 
    cellStyle: params => { if (!params.data || params.data.live_price === undefined || params.data.price_cc === undefined) 
        return { textAlign: 'center' }; return params.data.live_price > params.data.price_cc ? 
    { textAlign: 'center', backgroundColor: '#faf8e8', color: '#000000' } : { textAlign: 'center' }; } },
    { headerName: "Dynamic Price", field: "dynamic_price", sortable: true, filter: 'agNumberColumnFilter', width: 120, 
    cellStyle: params => { if (!params.data || params.data.dynamic_price === undefined || params.data.reqd_price === undefined) return { textAlign: 'center' }; 
    const  dynamicPrice = Number(params.data.dynamic_price), price_cc = Number(params.data.price_cc); 
    return dynamicPrice > price_cc ? { textAlign: 'center', backgroundColor: '#e6ffe6', color: '#000000' } : 
           dynamicPrice < price_cc ? { textAlign: 'center', backgroundColor: '#ffe6e6', color: '#000000' } : { textAlign: 'center' }; }, 
    valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '', aggFunc: 'sum' },
    { field: "rule", headerName: "Rule", flex: 1, sortable: true, filter: true, minWidth: 250},
    { headerName: "Min Price", field: "min_price", sortable: true, filter: true, width: 120, editable: true, columnGroupShow: 'open'  }
    ]}
  ];


export const columns_sku = [
  { headerName : "Product Info",
    children : [
    { headerName: "Ctry", field: "country", sortable: true, filter: true, width: 65 },
    { headerName: "Line", field: "line", sortable: true, filter: true, width: 95 },
    { headerName: "SKU", field: "sku", sortable: true, filter: true, width: 140 },
    { headerName: "Asin", field: "asin", sortable: true, filter: true, width: 130, columnGroupShow: 'open' },
    { headerName: "Feed Product Type", field: "feed_product_type", sortable: true, filter: true, width: 130, columnGroupShow: 'open' },
    ]},
  { headerName : "Stock",
    children : [  
    { headerName: "Risk Flag", field: "risk_flag", sortable: true, filter: true, width: 120 },
    { headerName: "Last Sold Days", field: "last_sold_days", sortable: true, filter: true, width: 100, columnGroupShow: 'open' },
    { headerName: "FBA Stock", field: "fba_stock", sortable: true, filter: 'agNumberColumnFilter', width: 100 },
    { headerName: "MA", field: "ma", sortable: true, filter: 'agNumberColumnFilter', width : 90,cellStyle: { textAlign: 'center'}, columnGroupShow: 'open' },
    { headerName: "DOC", field: "doc", sortable: true, filter: 'agNumberColumnFilter', width: 90, columnGroupShow: 'open' },
    { headerName: "Missed Days", field: "missed_days", sortable: true, filter: true, width: 90 },
    ]},
  { headerName : "LTSF",
      children : [
    { headerName: "Inv At Risk", field: "inv_at_risk", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'center'} },
    { headerName: "Inv Short LTSF", field: "inv_short_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'center'} },
    { headerName: "Inv Long LTSF", field: "inv_long_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'center'} },
      ]},
  { headerName : "Pricing",    
    children : [ 
    { headerName: "Live Price", field: "live_price", sortable: true, filter: true, width: 120 },
    { headerName: "CC Price", field: "price_cc", sortable: true, filter: true, width: 120, 
      cellStyle: params => { if (!params.data || params.data.live_price === undefined || params.data.price_cc === undefined) 
        return { textAlign: 'center' }; return params.data.live_price > params.data.price_cc ? 
    { textAlign: 'center', backgroundColor: '#faf8e8', color: '#000000' } : { textAlign: 'center' }; } },
    { headerName: "Dynamic Price", field: "dynamic_price", sortable: true, filter: 'agNumberColumnFilter', width: 120,
    cellStyle: params => { if (!params.data || params.data.dynamic_price === undefined || params.data.reqd_price === undefined) return { textAlign: 'center' }; 
    const  dynamicPrice = Number(params.data.dynamic_price), price_cc = Number(params.data.price_cc); 
    return dynamicPrice > price_cc ? { textAlign: 'center', backgroundColor: '#e6ffe6', color: '#000000' } : 
           dynamicPrice < price_cc ? { textAlign: 'center', backgroundColor: '#ffe6e6', color: '#000000' } : { textAlign: 'center' }; }, 
    valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '', aggFunc: 'sum' },
    { field: "rule", headerName: "Rule", flex: 1, sortable: true, filter: true, minWidth: 250},
    { headerName: "Min Price", field: "min_price", sortable: true, filter: true, width: 110, editable: true, columnGroupShow: 'open'  }
    ]}
    ];

export const BQ_Repricing_Log_Columns = [
    { field: "country", headerName: "Ctry", sortable: true, filter: true, width : 65,pinned : 'left' },
    { field: "sku", headerName: "SKU", sortable: true, filter: 'agTextColumnFilter', minWidth : 175, pinned: 'left' ,
      filterParams: { filterOptions: [ { 
            displayKey: 'multipleSKUs', displayName: 'Multiple SKUs',
            predicate: ([filterValue], cellValue) => { if (!filterValue) return true;
              const skus = filterValue.replace(/\s*,\s*/g, ' ')  
                                      .split(/\s+/)              
                                      .filter(sku => sku !== '');
              return skus.includes(cellValue);
            }, numberOfInputs: 1, } ], }
    },
    { headerName: "", field: "link", sortable: true, filter: false, width: 30, pinned: 'left', enableRowGroup: true,
    cellRenderer: params => { if (!params.value) { return null; } const url = params.value.startsWith('http') ? params.value : `https://${params.value}`; 
    return React.createElement('a', { href: url, target: '_blank', rel: 'noopener noreferrer', style: { textDecoration: 'none'}  }, '🔗');  } },
    { headerName: "Asin", field: "asin", sortable: true, filter: 'agTextColumnFilter', width : 130,
      filterParams: { filterOptions: [
          { displayKey: 'multipleAsins', displayName: 'Multiple ASINs',
            predicate: ([filterValue], cellValue) => { if (!filterValue) return true;
              const asins = filterValue.replace(/\s*,\s*/g, ' ') 
                                       .split(/\s+/)             
                                       .filter(asin => asin !== '');
             return asins.includes(cellValue);
            }, numberOfInputs: 1,  } ], }
    },
    { headerName: "Line", field: "line", sortable: true, filter: true, width : 95},
    { headerName: "DRI", field: "dri", sortable: true, filter: true, width : 65},
    { headerName : "Log Info",
      children : [
    { headerName: "Time ID", field: "time_id", sortable: true, filter: true, width : 110 },
    { headerName: "Feed ID", field: "feedId", sortable: true, filter: true, width : 110, columnGroupShow: 'open' },
    { headerName: "Feed Product Type", field: "product_type", sortable: true, filter: true, width: 130, columnGroupShow: 'open' },
    { headerName: "Message ID", field: "message_id", sortable: true, filter: true, width : 100, cellStyle: { textAlign: 'center' }, columnGroupShow: 'open' },
    ]},
    { headerName: 'Stocks',
      children: [
    { headerName: "FBA Stock", field: "fba_stock", sortable: true, filter: 'agNumberColumnFilter', width : 95,cellStyle: { textAlign: 'center'} },
    { headerName: "MA", field: "ma", sortable: true, filter: 'agNumberColumnFilter', width : 95,cellStyle: { textAlign: 'center'}, columnGroupShow: 'open' },
    { headerName: "DOC", field: "doc", sortable: true, filter: 'agNumberColumnFilter', width : 95,cellStyle: { textAlign: 'center'} },
    { headerName: "Missed Days", field: "missed_days", sortable: true, filter: 'agNumberColumnFilter', width : 95,cellStyle: { textAlign: 'center'} },
    { headerName: "Last Sold Days", field: "last_sold_days", sortable: true, filter: true, width: 100, columnGroupShow: 'open',cellStyle: { textAlign: 'center'} },]},    
    { headerName : "LTSF",
      children : [
    { headerName: "Risk Flag", field: "risk_flag", sortable: true, filter: true, width : 110},    
    { headerName: "Inv At Risk",field: "inv_at_risk" , sortable: true, filter: 'agNumberColumnFilter', width : 110,cellStyle: { textAlign: 'center'}, columnGroupShow: 'open' },
    { headerName: "Inv Short LTSF", field: "inv_short_ltsf",sortable: true, filter: 'agNumberColumnFilter', width : 110,cellStyle: { textAlign: 'center'}, columnGroupShow: 'open' },
    { headerName: "Inv Long LTSf", field: "inv_long_ltsf", sortable: true, filter: 'agNumberColumnFilter', width : 110,cellStyle: { textAlign: 'center'}, columnGroupShow: 'open' },
    ]},
    
    {headerName: 'Pricing',
    children: [
    { headerName: "Live Price", field: "live_price", flex: 1,sortable: true, minWidth : 100, filter: 'agNumberColumnFilter',cellStyle: { textAlign: 'center'} },
    { headerName: "Reqd Price", field: "reqd_price", flex: 1, sortable: true, minWidth: 100, filter: 'agNumberColumnFilter', 
      cellStyle: params => { if (!params.data || params.data.live_price === undefined || params.data.reqd_price === undefined) 
      return { textAlign: 'center' }; return params.data.live_price > params.data.reqd_price ? 
      { textAlign: 'center', backgroundColor: '#faf8e8', color: '#000000' } : { textAlign: 'center' }; } },
    { headerName: "Dynamic Price", field: "dynamic_price", flex: 1, sortable: true, minWidth: 105, filter: 'agNumberColumnFilter', 
    cellStyle: params => { if (!params.data || params.data.dynamic_price === undefined || params.data.reqd_price === undefined) return { textAlign: 'center' }; 
    const  dynamicPrice = Number(params.data.dynamic_price), reqdPrice = Number(params.data.reqd_price); 
    return dynamicPrice > reqdPrice ? { textAlign: 'center', backgroundColor: '#e6ffe6', color: '#000000' } : 
           dynamicPrice < reqdPrice ? { textAlign: 'center', backgroundColor: '#ffe6e6', color: '#000000' } : { textAlign: 'center' }; }, 
    valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '', aggFunc: 'sum' },
    { headerName: "Rule", field: "rule", flex: 1, sortable: true, filter: true, minWidth: 320}
    ]}
  ];