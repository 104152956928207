import { createElement } from 'react';

export const country_domain_map = {
    'IN': 'amazon.in',
    'ES': 'amazon.es',
    'IT': 'amazon.it',
    'FR': 'amazon.fr',
    'DE': 'amazon.de',
    'UK': 'amazon.co.uk',
    'US': 'amazon.com',
    'CA': 'amazon.ca',
    'JP': 'amazon.co.jp',
    'AMS': 'amazon.in',
};

export const columns = [
    {
        field: 'country',
        headerName: 'Ctry',
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressMiniFilter: false,
        },
        width: 55,
        pinned: 'left',
        enableRowGroup: true,
    },
    {
        field: 'line',
        headerName: 'Line',
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressMiniFilter: false,
        },
        width: 70,
        pinned: 'left',
        enableRowGroup: true,
    },
    {
        field: 'keyword',
        headerName: 'Keyword',
        filter: 'agTextColumnFilter',
        width: 250,
        pinned: 'left',
        enableRowGroup: true,
    },
    {
        headerName: '',
        field: 'link',
        width: 25, pinned: 'left',
        filter: false,
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressMiniFilter: false,
        },
        cellRenderer: params => {
            if (!params.data || !params.data.keyword) return null;
            const domain = country_domain_map[params.data.country] || 'amazon.com';
            const searchTerm = params.data.keyword.replace(/\s+/g, '+');
            const url = `https://www.${domain}/s?k=${searchTerm}`;
            return createElement('a', {
                href: url,
                target: '_blank',
                rel: 'noopener noreferrer',
                style: { textDecoration: 'none' }
            }, '🔗');
        }
    },
    {
        field: 'label',
        headerName: 'Label',
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressMiniFilter: false,
        },
        width: 150,
        pinned: 'left',
        enableRowGroup: true,
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
            values: [
                'generic',
                'highly_relevant',
                'competitor',
                'brand',
                'asin',
                'seasonal',
                'size',
                'color',
                'size_color',
                'pack_pcs',
                'irrelevant'
            ],
            searchDebounceDelay: 0,
            searchType: 'startsWith',
            filterList: true,
            allowTyping: true,
            showArrow: false
        },
        suppressSizeToFit: true
    },
    {
        field: 'dri',
        headerName: 'DRI',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressMiniFilter: false,
        },
        width: 50,
        enableRowGroup: true,
    },
    {
        field: 'clicks',
        headerName: 'Clicks',
        filter: 'agNumberColumnFilter',
        // type: 'numericColumn',
        width: 70,
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        aggFunc: 'sum'
    },
    {   field: 'cost', headerName: 'Cost', filter: 'agNumberColumnFilter',  width: 80, cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum'
    },
    {
        field: 'orders',
        headerName: 'Orders',
        filter: 'agNumberColumnFilter',
        // type: 'numericColumn',
        width: 70,
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        aggFunc: 'sum'
    },
    {   field: 'sales7d', headerName: 'Sales', filter: 'agNumberColumnFilter', width: 80, cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum'
    },
    {
        field: 'acos', headerName: 'ACOS', filter: 'agNumberColumnFilter', width: 65, cellStyle: { textAlign: 'right' },
        valueGetter: (params) => params.data && params.data.sales7d && params.data.cost  ? (params.data.cost / params.data.sales7d) * 100  : 0,
        valueFormatter: (params) => params.value  ? params.value.toFixed(0) + '%' : ''
    },
    {   headerName: "CVR", field: "cvr", sortable: true, filter: 'agNumberColumnFilter', width: 65, cellStyle: { textAlign: 'right' }, 
        valueGetter: (params) => params.data && params.data.orders && params.data.clicks  ? (params.data.orders / params.data.clicks) * 100  : 0,
        valueFormatter: (params) => params.value  ? params.value.toFixed(1) + '%' : ''
    },
    {
        field: 'label_source',
        headerName: 'Label Source',
        filter: 'agTextColumnFilter',
        width: 120,
        enableRowGroup: true,
    },
    {
        field: 'confidence_score',
        headerName: 'Confidence',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        width: 120,
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? (params.value * 100).toFixed(0) + '%' : '',
        aggFunc: 'avg'
    },
    {
        field: 'assigned_at',
        headerName: 'Assigned At',
        filter: 'agDateColumnFilter',
        width: 140,
        valueFormatter: params => params.value ? new Date(params.value).toLocaleString() : '',
    },
    {
        field: 'h10_search_volume',
        headerName: 'H10 Volume',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        width: 100,
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        aggFunc: 'sum'
    },
    {
        field: 'h10_keyword_sales',
        headerName: 'H10 Sales',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        width: 100,
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        aggFunc: 'sum'
    },
    {
        field: 'h10_fetch_date',
        headerName: 'H10 Date',
        filter: 'agDateColumnFilter',
        width: 100,
        valueFormatter: params => params.value ? new Date(params.value).toLocaleDateString() : '',
    }
];