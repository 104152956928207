import React, { createContext, useState, useEffect, useContext } from 'react';

// Create the auth context
const AuthContext = createContext(null);

// Auth provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Get the backend API URL based on environment
  const API_URL = process.env.REACT_APP_API_URL || 
                 (window.location.hostname === 'localhost' 
                  ? 'http://localhost:5000' 
                  : window.location.origin);
  
  console.log('Using API URL:', API_URL);

  // Check if user is already authenticated on component mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // Check if we have a token in localStorage
        const token = localStorage.getItem('token');
        const authStatus = localStorage.getItem('isAuthenticated');
        
        console.log('Checking auth status:', 
          'token exists =', !!token, 
          'isAuthenticated =', authStatus);
        
        if (token && authStatus === 'true') {
          // If we have a token, get the user data from the API
          const response = await fetch(`${API_URL}/api/auth/user`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          
          if (response.ok) {
            const data = await response.json();
            setUser(data.user);
            console.log('User authenticated successfully');
          } else {
            // If the token is invalid, clear localStorage and set user to null
            console.log('Token validation failed, logging out');
            await logout();
          }
        } else {
          // If no token or not authenticated, ensure we're logged out
          if (authStatus !== 'false') {
            console.log('No valid auth data found, clearing state');
            localStorage.removeItem('token');
            localStorage.setItem('isAuthenticated', 'false');
            setUser(null);
          }
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        setError('Failed to verify authentication status');
        // On error, ensure we're logged out
        await logout();
      } finally {
        setLoading(false);
      }
    };
    
    checkAuthStatus();
  }, []);

  // Sign in with Google token
  const googleSignIn = async (credential) => {
    setLoading(true);
    console.log('Starting Google sign-in process');
    
    try {
      console.log('Sending token to backend');
      const response = await fetch(`${API_URL}/api/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: credential })
      });
      
      const data = await response.json();
      console.log('Response from backend:', response.status, data);
      
      if (data.success) {
        console.log('Authentication successful');
        // Store token and user information
        localStorage.setItem('token', data.token);
        localStorage.setItem('isAuthenticated', 'true');
        setUser(data.user);
        return { success: true, user: data.user };
      } else {
        console.error('Authentication failed:', data.message);
        setError(data.message || 'Authentication failed');
        return { success: false, message: data.message };
      }
    } catch (error) {
      console.error('Google Sign-In failed:', error);
      setError('Failed to authenticate with Google: ' + (error.message || 'Unknown error'));
      return { success: false, message: 'Failed to authenticate with Google: ' + (error.message || 'Unknown error') };
    } finally {
      setLoading(false);
    }
  };

  // Traditional login (for backward compatibility)
  const login = async (username, password) => {
    setLoading(true);
    try {
      // For now, keep the simple check for backward compatibility
      if (username === 'admin' && password === 'enHQ109@') {
        localStorage.setItem('isAuthenticated', 'true');
        // Set a basic user object
        const basicUser = { id: 'admin', name: 'Admin', email: 'admin@example.com' };
        setUser(basicUser);
        return { success: true, user: basicUser };
      } else {
        setError('Invalid username or password');
        return { success: false, message: 'Invalid username or password' };
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError('Login failed');
      return { success: false, message: 'Login failed' };
    } finally {
      setLoading(false);
    }
  };

  // Logout function
  const logout = async (callback) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      
      if (token) {
        // Call the logout API to invalidate the token on the server
        await fetch(`${API_URL}/api/auth/logout`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }
      
      // Clear localStorage and state
      localStorage.removeItem('token');
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('user');
      
      // Force clear localStorage items to ensure they're removed
      localStorage.setItem('isAuthenticated', 'false');
      
      // Clear user state
      setUser(null);
      
      console.log('Logout completed - localStorage cleared:', 
        'token =', localStorage.getItem('token'), 
        'isAuthenticated =', localStorage.getItem('isAuthenticated'));
      
      // Execute callback if provided (for navigation)
      if (typeof callback === 'function') {
        callback();
      }
      
      return { success: true };
    } catch (error) {
      console.error('Logout failed:', error);
      return { success: false, message: 'Logout failed' };
    } finally {
      setLoading(false);
    }
  };

  // Check if user is authenticated
  const isAuthenticated = () => {
    const authStatus = localStorage.getItem('isAuthenticated');
    // Only return true if user is set OR isAuthenticated is explicitly 'true'
    // This ensures 'false' or null values are treated as not authenticated
    return !!user || (authStatus === 'true');
  };

  // Context value
  const value = {
    user,
    loading,
    error,
    login,
    googleSignIn,
    logout,
    isAuthenticated
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Custom hook to use the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext; 