import { Box, Button, TextField, MenuItem, Typography, LinearProgress, useTheme, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Divider, Autocomplete } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import AGGrid_Options from "../../components/global/AGGrid_Options";
import { tokens } from "../../theme";
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';

const MAX_LENGTHS = {
  title: 199,
  bulletPoint: 499,
  description: 1999,
  backendKeywordsAsin: 199,
  imgAltTags: 1000,
  imageAltTags: 1000
};

const getProgressBarColor = (score) => {
  if (score <= 30) return "#f44336"; // Red
  if (score <= 70) return "#ff9800"; // Orange
  return "#4caf50"; // Green
};

// Update tooltip content constants with examples
const TOOLTIP_CONTENT = {
  NGP: (details) => `N-gram Presence (40%): Measures how many important keywords from the Frankenstein table are present in your content. Higher presence means better optimization.

Formula: (${details.matching_ngrams.length} matching keywords / ${details.total_ngrams} total keywords) × 100 = ${Math.round(details.NGP)}%
Note: Irrelevant keywords are excluded from both matching and total keywords.

Example: If Frankenstein table shows 'bamboo sheets' as important keywords and your content includes 'bamboo sheets', this improves your N-gram score.`,

  KVP: `Keyword Volume Percentage (40%): Calculates the total search volume coverage of your keywords compared to the potential maximum volume. Higher volume coverage means better visibility.\n

Example: If 'bamboo sheets' has 10k monthly searches and 'bed sheets' has 5k, and your content includes both, you're covering 15k out of the potential search volume.`,

  R: `Readability Score (20%): Uses Flesch reading ease score to evaluate content readability.\n
• >50% = 100% score (Easy to read)\n
• >40% = 80% score (Moderate)\n
• >30% = 60% score (Difficult) \n

Example: "These soft bamboo sheets are perfect for your bed" scores better than "The bamboo-derived cellulosic fiber bedding implements optimal sleep conditions."`,

  G: `Grammar Score (20%): Evaluates basic grammar rules, sentence structure, and common writing mistakes. Better grammar means more professional content.\n

Example:
✓ "The bamboo sheets are soft and comfortable."
✗ "bamboo sheets is soft & comfortable"\n

Common checks:
• Capitalization
• Punctuation
• Spacing
• Sentence structure`
};

// Update the customGridStyles near the top of the file
const customGridStyles = `
  .ag-theme-alpine {
    --ag-font-size: 11px !important;
    --ag-header-font-size: 11px !important;
    --ag-row-height: 20px !important;
    --ag-header-height: 30px !important;
    --ag-cell-horizontal-padding: 3px !important;
  }
  .ag-theme-alpine .ag-header-cell-text {
    font-size: 11px !important;
  }
  .ag-theme-alpine .ag-cell-value {
    font-size: 11px !important;
  }
  .ag-theme-alpine .ag-cell {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    display: flex !important;
    align-items: center !important;
  }
  .ag-theme-alpine .ag-right-aligned-cell {
    justify-content: flex-end !important;
  }
`;

const SEOModule = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [country, setCountry] = useState("");
  const [asin, setAsin] = useState("");
  const [countries, setCountries] = useState([]);
  const [title, setTitle] = useState("");
  const [bulletPoint1, setBulletPoint1] = useState("");
  const [bulletPoint2, setBulletPoint2] = useState("");
  const [bulletPoint3, setBulletPoint3] = useState("");
  const [bulletPoint4, setBulletPoint4] = useState("");
  const [bulletPoint5, setBulletPoint5] = useState("");
  const [description, setDescription] = useState("");
  const [backendKeywordsAsin, setBackendKeywordsAsin] = useState("");
  const [backendKeywordsLine, setBackendKeywordsLine] = useState("");
  const [seoScore, setSeoScore] = useState(null);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contentFetched, setContentFetched] = useState(false);
  const [line, setLine] = useState("");
  const [mklData, setMklData] = useState([]);
  const [frankensteinData, setFrankensteinData] = useState([]);
  const [missingKeywords, setMissingKeywords] = useState("");
  const [lines, setLines] = useState([]);
  const [chatGPTScore, setChatGPTScore] = useState(null);
  const [bufferedContent, setBufferedContent] = useState(null);
  const [isRetryHovered, setIsRetryHovered] = useState(false);
  const [isRegenerateHovered, setIsRegenerateHovered] = useState(false);
  const [showContentComparison, setShowContentComparison] = useState(false);
  const [imageAltTags, setImageAltTags] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [sidebarWidth, setSidebarWidth] = useState(240);
  const [irrelevantKeywords, setIrrelevantKeywords] = useState("");
  const [openComparisonDialog, setOpenComparisonDialog] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);

  const mklColumns = [
    { 
      field: 'keywords', 
      headerName: 'Keywords', 
      flex: 2, 
      headerClassName: 'bold-header',
      filter: true,
      sortable: true
    },
    { 
      field: 'search_vol', 
      headerName: 'Search Vol', 
      flex: 1, 
      type: 'numericColumn',
      filter: 'agNumberColumnFilter',
      sortable: true,
      valueFormatter: params => params.value ? Number(params.value).toLocaleString() : '-',
      cellClass: 'ag-right-aligned-cell'
    },
    { 
      field: 'kwd_sales', 
      headerName: 'Kwd Sales', 
      flex: 1, 
      type: 'numericColumn',
      filter: 'agNumberColumnFilter',
      sortable: true,
      valueFormatter: params => params.value ? Number(params.value).toLocaleString() : '-',
      cellClass: 'ag-right-aligned-cell'
    },
    { 
      field: 'trend', 
      headerName: 'Trend', 
      flex: 1,
      type: 'numericColumn',
      filter: 'agNumberColumnFilter',
      sortable: true,
      valueFormatter: params => params.value ? Number(params.value).toLocaleString() : '-',
      cellClass: 'ag-right-aligned-cell'
    }
  ];

  // Fetch countries on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('/api/seo/get_countries');
        const data = await response.json();
        if (Array.isArray(data)) {
          setCountries(data);
        }
      } catch (err) {
        setError("Failed to fetch countries");
      }
    };
    fetchCountries();
  }, []);

  // Fetch lines on component mount
  useEffect(() => {
    const fetchLines = async () => {
      try {
        const response = await fetch('/api/seo/get_lines');
        const data = await response.json();
        if (Array.isArray(data)) {
          setLines(data);
        }
      } catch (err) {
        setError("Failed to fetch lines");
      }
    };
    fetchLines();
  }, []);

  // Add effect to listen for sidebar changes
  useEffect(() => {
    const handleResize = () => {
      // Check if sidebar is collapsed (60px) or expanded (240px)
      const sidebarElement = document.querySelector('.pro-sidebar');
      if (sidebarElement) {
        setSidebarWidth(sidebarElement.offsetWidth);
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    
    // Add mutation observer to detect sidebar changes
    const observer = new MutationObserver(handleResize);
    const sidebar = document.querySelector('.pro-sidebar');
    if (sidebar) {
      observer.observe(sidebar, { attributes: true });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  // Add document title and initial logging
  useEffect(() => {
    document.title = "SEO Module | Encasa HQ";
    console.log('Initiating SEO Module');
  }, []);

  // Update handleFetchContent with logging
  const handleFetchContent = async () => {
    if (!country || !asin || !line) return;
    setLoading(true);
    setError(null);
    console.log(`Fetching content for ASIN: ${asin}, Line: ${line}, Country: ${country}`);

    try {
      const response = await fetch('/api/seo/fetch_content', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ country, asin, line })
      });

      console.log(`Fetch content response status: ${response.status}`);
      const data = await response.json();
      
      if (response.ok) {
        console.log('Content fetched successfully:', {
          title: data.title ? 'Present' : 'Missing',
          bullet_points: data.bullet_points?.length || 0,
          description: data.description ? 'Present' : 'Missing',
          mkl_data: data.mkl_data?.length || 0
        });
        
        setTitle(data.title || '');
        setBulletPoint1(data.bullet_points[0] || '');
        setBulletPoint2(data.bullet_points[1] || '');
        setBulletPoint3(data.bullet_points[2] || '');
        setBulletPoint4(data.bullet_points[3] || '');
        setBulletPoint5(data.bullet_points[4] || '');
        setDescription(data.description || '');
        setBackendKeywordsAsin(data.backend_keywords_asin || '');
        const lineKeywords = data.backend_keywords_line || '';
        setBackendKeywordsLine(lineKeywords !== data.backend_keywords_asin ? lineKeywords : '');
        setMklData(data.mkl_data.map((item, index) => ({ ...item, id: index })));
        setContentFetched(true);
      } else {
        console.error('Failed to fetch content:', data.error);
        setError(data.error || 'Failed to fetch content');
      }
    } catch (err) {
      console.error('Error in handleFetchContent:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Update handleCheckSEO with logging
  const handleCheckSEO = useCallback(async () => {
    setLoading(true);
    setError(null);
    setSeoScore(null);
    setDetails(null);
    console.log('Calculating SEO score for:', { asin, line, country });

    try {
      const response = await fetch('/api/seo/calculate_seo_score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country,
          asin,
          title,
          bullet_points: [
            bulletPoint1,
            bulletPoint2,
            bulletPoint3,
            bulletPoint4,
            bulletPoint5
          ].filter(bp => bp),
          description,
          backend_keywords_asin: backendKeywordsAsin,
          backend_keywords_line: backendKeywordsLine,
          image_alt_tags: imageAltTags,
          frankenstein_data: frankensteinData,
          irrelevant_keywords: irrelevantKeywords.split(/\s+/).filter(k => k)
        }),
      });

      console.log(`SEO score calculation response status: ${response.status}`);
      const data = await response.json();
      
      if (!response.ok) {
        console.error('Failed to calculate SEO score:', data.error);
        throw new Error(data.error || 'Failed to calculate SEO score');
      }
      
      console.log('SEO score calculated successfully:', {
        score: data.seo_score,
        ngp: data.details?.NGP,
        kvp: data.details?.KVP,
        readability: data.details?.R,
        grammar: data.details?.G
      });
      
      setSeoScore(data.seo_score);
      setDetails(data.details);
    } catch (err) {
      console.error('Error in handleCheckSEO:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [country, asin, title, bulletPoint1, bulletPoint2, bulletPoint3, bulletPoint4, bulletPoint5, 
      description, backendKeywordsAsin, backendKeywordsLine, imageAltTags, frankensteinData, irrelevantKeywords]);

  const processKeywords = useCallback(() => {
    if (!mklData.length) return [];

    // Common words to exclude
    const excludeWords = new Set([
      'for', 'and', 'the', 'a', 'an', 'in', 'on', 'at', 'to', 'of', 
      'with', 'by', 'from', 'up', 'about', 'into', 'over', 'after',
      'is', 'are', 'was', 'were', 'be', 'been', 'being',
      'this', 'that', 'these', 'those', 'x',
      'has', 'have', 'had', 'having'
    ]);

    // Get all words from MKL keywords
    const words = mklData
      .map(item => item.keywords.toLowerCase())
      .join(' ')
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .split(/\s+/)
      .filter(word => word.length > 0 && !excludeWords.has(word.toLowerCase()));

    // Count word frequency
    const wordFrequency = {};
    words.forEach(word => {
      wordFrequency[word] = (wordFrequency[word] || 0) + 1;
    });

    // Calculate search volume for each word
    const wordVolume = {};
    mklData.forEach(item => {
      const keywordWords = item.keywords.toLowerCase()
        .replace(/[^a-zA-Z0-9\s]/g, '')
        .split(/\s+/)
        .filter(word => !excludeWords.has(word));
      
      keywordWords.forEach(word => {
        if (!wordVolume[word]) {
          wordVolume[word] = 0;
        }
        wordVolume[word] += (item.search_vol || 0);
      });
    });

    const uniqueWords = [...new Set(words)];
    
    const contentToCheck = [
      title.toLowerCase(),
      [bulletPoint1, bulletPoint2, bulletPoint3, bulletPoint4, bulletPoint5].join(' ').toLowerCase(),
      description.toLowerCase(),
      backendKeywordsAsin.toLowerCase(),
      backendKeywordsLine.toLowerCase(),
      imageAltTags.toLowerCase()
    ].join(' ');

    // Create array with all data and sort by volume
    const allNgrams = uniqueWords.map((word, index) => ({
      id: index,
      ngram: word,
      frequency: wordFrequency[word],
      volume: wordVolume[word] || 0,
      inTitle: title.toLowerCase().includes(word),
      present: contentToCheck.includes(word)
    }));

    // Sort by volume in descending order and take top 100
    return allNgrams
      .sort((a, b) => b.volume - a.volume)
      .slice(0, 100);

  }, [mklData, title, bulletPoint1, bulletPoint2, bulletPoint3, bulletPoint4, bulletPoint5, description, backendKeywordsAsin, backendKeywordsLine, imageAltTags]);

  // Update Frankenstein data when content changes
  useEffect(() => {
    if (contentFetched) {
      const processedData = processKeywords();
      setFrankensteinData(processedData);
    }
  }, [contentFetched, title, bulletPoint1, bulletPoint2, bulletPoint3, bulletPoint4, bulletPoint5, description, backendKeywordsAsin, backendKeywordsLine, mklData, processKeywords]);

  // Update the effect to sort by frequency
  useEffect(() => {
    if (frankensteinData.length) {
      const missing = frankensteinData
        .filter(word => !word.present) // Get words that are not present
        .sort((a, b) => b.frequency - a.frequency) // Sort by frequency in descending order
        .map(word => word.ngram)
        .join('  ');
      setMissingKeywords(missing);
    }
  }, [frankensteinData]);

  const frankensteinColumns = [
    {
      field: 'ngram',
      headerName: 'N-gram',
      flex: 1,
      headerClassName: 'bold-header'
    },
    {
      field: 'frequency',
      headerName: 'Freq.',
      flex: 1,
      type: 'numericColumn',
      filter: 'agNumberColumnFilter',
      sort: 'desc',
      valueFormatter: params => params.value ? Number(params.value).toLocaleString() : '-',
      cellClass: 'ag-right-aligned-cell'
    },
    {
      field: 'volume',
      headerName: 'Total Vol',
      flex: 1,
      type: 'numericColumn',
      filter: 'agNumberColumnFilter',
      sort: 'desc',
      valueFormatter: params => params.value ? Number(params.value).toLocaleString() : '-',
      cellClass: 'ag-right-aligned-cell'
    },
    {
      field: 'inTitle',
      headerName: 'In Title',
      flex: 0.6,
      cellRenderer: params => params.value ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
    },
    {
      field: 'present',
      headerName: 'Present',
      flex: 0.6,
      cellRenderer: params => params.value ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
    }
  ];

  // Add new tooltip content for SEO Score
  const SEO_SCORE_TOOLTIP = (details) => `
  Score Breakdown:
  • N-gram Presence (30%): ${Math.round(details.NGP)}%
  • Keyword Volume (30%): ${Math.round(details.KVP)}%
  • Readability (20%): ${Math.round(details.R)}%
  • Grammar (20%): ${Math.round(details.G)}%
  ${details.restricted_keywords?.length ? '\nNote: 20% penalty applied for restricted keywords' : ''}
  `;

  // Update renderSEOScore function
  const renderSEOScore = () => {
    if (!seoScore) return null;

    return (
      <Box sx={{ mt: 3, p: 3, border: '1px solid #ccc', borderRadius: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
          <Typography variant="h4" textAlign="center">
            SEO Score: {Math.round(seoScore)}%
          </Typography>
          <Tooltip 
            title={SEO_SCORE_TOOLTIP(details)} 
            arrow 
            placement="top"
            sx={{ 
              whiteSpace: 'pre-line',
              '& .MuiTooltip-tooltip': {
                fontSize: '0.9rem',
                padding: '8px 12px',
                lineHeight: 1.5
              }
            }}
          >
            <InfoIcon sx={{ fontSize: 20, color: 'primary.main', cursor: 'help' }} />
          </Tooltip>
        </Box>
        {renderProgressBar()}
        {details && renderMetricsDetails()}
      </Box>
    );
  };

  // Update renderMetricsDetails function
  const renderMetricsDetails = () => (
    <Box sx={{ mt: 3 }}>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={2}>
        {Object.entries(TOOLTIP_CONTENT).map(([key, content]) => (
          <Box key={key} display="flex" alignItems="left" gap={0.3}>
            <Typography>
              {key === 'NGP' ? 'N-gram Presence' : 
               key === 'KVP' ? 'Keyword Volume' :
               key === 'R' ? 'Readability Score' : 'Grammar Score'}: {Math.round(details[key])}%
            </Typography>
            <Tooltip title={typeof content === 'function' ? content(details) : content} placement="top">
              <InfoIcon sx={{ fontSize: 18, color: 'grey.500' }} />
            </Tooltip>
          </Box>
        ))}
      </Box>
      
      {details.restricted_keywords && details.restricted_keywords.length > 0 && (
        <Box mt={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography color="error">
              Warning: Found restricted keywords:
            </Typography>
            <Tooltip title="A 10% penalty is applied to the final SEO score when restricted keywords are found" placement="top">
              <InfoIcon sx={{ fontSize: 18, color: 'error.main' }} />
            </Tooltip>
          </Box>
          <Box component="ul" sx={{ mt: 1 }}>
            {checkRestrictedKeywords().map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );

  const renderProgressBar = () => (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" textAlign="center" gutterBottom>
        Score Breakdown
      </Typography>
      <Box position="relative">
        <LinearProgress
          variant="determinate"
          value={seoScore}
          sx={{
            height: 20,
            borderRadius: 5,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: getProgressBarColor(seoScore),
            },
          }}
        />
      </Box>
    </Box>
  );

  const getCharCountColor = (current, max) => {
    return current > max ? "#f44336" : "#4caf50"; // Red if exceeds, Green if within limit
  };

  const renderCharCount = (current, max) => (
    <Typography 
      variant="caption" 
      sx={{ 
        color: getCharCountColor(current, max),
        position: 'absolute',
        bottom: 4,
        right: 8,
      }}
    >
      {current}
    </Typography>
  );

  // Function to check restricted keywords in all fields
  const checkRestrictedKeywords = () => {
    if (!details?.restricted_keywords) return [];
    
    const foundKeywords = new Set();
    const fieldsToCheck = {
      'Title': title,
      'Bullet Points': [bulletPoint1, bulletPoint2, bulletPoint3, bulletPoint4, bulletPoint5].join(' '),
      'Description': description,
      'Backend Keywords of ASIN': backendKeywordsAsin,
      'Backend Keywords of Line': backendKeywordsLine
    };

    Object.entries(fieldsToCheck).forEach(([field, content]) => {
      details.restricted_keywords.forEach(keyword => {
        if (content.toLowerCase().includes(keyword.toLowerCase())) {
          foundKeywords.add(`${field}: "${keyword}"`);
        }
      });
    });

    return Array.from(foundKeywords);
  };

  // Helper function to filter out colors and sizes
  const filterColorsSizes = (keywords) => {
    const colors = ['grey', 'black', 'blue', 'red', 'coral', 'white', 'green', 'yellow', 'purple', 'pink', 'brown'];
    const sizePattern = /\d+\s*x\s*\d+|\d+\s*inch/i;
    
    return keywords.filter(kw => {
      const kwLower = kw.ngram.toLowerCase();
      return !colors.some(color => kwLower.includes(color)) && !sizePattern.test(kwLower);
    });
  };

  // Update getMissingKeywordsInfo
  const getMissingKeywordsInfo = (frankensteinData, contentToCheck) => {
    const filteredData = filterColorsSizes(frankensteinData);
    
    const missingNGrams = filteredData
      .filter(item => !contentToCheck.toLowerCase().includes(item.ngram.toLowerCase()))
      .sort((a, b) => b.frequency - a.frequency)
      .slice(0, 5);

    const missingVolume = filteredData
      .filter(item => !contentToCheck.toLowerCase().includes(item.ngram.toLowerCase()))
      .sort((a, b) => parseInt(b.volume) - parseInt(a.volume))
      .slice(0, 5);

    return { missingNGrams, missingVolume };
  };

  // Move renderChatGPTScore outside of ContentComparisonDialog and into the main component scope
  const renderChatGPTScore = () => {
    if (!chatGPTScore) return null;

    return (
      <Box sx={{ mt: 3, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
        <Typography 
          variant="h5" 
          gutterBottom 
          textAlign="center"
          sx={{ 
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            pb: 1,
            mb: 2
          }}
        >
          ChatGPT Generated Score: {Math.round(chatGPTScore.seo_score)}%
        </Typography>
        
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={2}>
          <Typography>N-gram Presence: {Math.round(chatGPTScore.details.NGP)}%</Typography>
          <Typography>Keyword Volume: {Math.round(chatGPTScore.details.KVP)}%</Typography>
          <Typography>Readability Score: {Math.round(chatGPTScore.details.R)}%</Typography>
          <Typography>Grammar Score: {Math.round(chatGPTScore.details.G)}%</Typography>
        </Box>
      </Box>
    );
  };

  // Update ContentComparisonDialog to use the main component's renderChatGPTScore
  const ContentComparisonDialog = () => {
    const [editedContent, setEditedContent] = useState({
      description: '',
      bulletPoints: ['', '', '', '', ''],
      backendKeywords: ''
    });

    // Add back the compareText function
    const compareText = (original, updated) => {
      if (!original || !updated) return null;
      const words1 = original.split(/\s+/);
      const words2 = updated.split(/\s+/);
      const diff = [];
      
      let i = 0, j = 0;
      while (i < words1.length || j < words2.length) {
        if (i >= words1.length) {
          diff.push(<span key={`add-${j}`} style={{ color: 'green' }}>{words2[j]} </span>);
          j++;
        } else if (j >= words2.length) {
          diff.push(<span key={`del-${i}`} style={{ color: 'red', textDecoration: 'line-through' }}>{words1[i]} </span>);
          i++;
        } else if (words1[i] === words2[j]) {
          diff.push(<span key={`same-${i}`}>{words1[i]} </span>);
          i++;
          j++;
        } else {
          diff.push(<span key={`del-${i}`} style={{ color: 'red', textDecoration: 'line-through' }}>{words1[i]} </span>);
          diff.push(<span key={`add-${j}`} style={{ color: 'green' }}>{words2[j]} </span>);
          i++;
          j++;
        }
      }
      return diff;
    };

    useEffect(() => {
      if (bufferedContent) {
        setEditedContent({
          description: bufferedContent.description || '',
          bulletPoints: bufferedContent.bulletPoints || ['', '', '', '', ''],
          backendKeywords: backendKeywordsAsin
        });
      }
    }, [bufferedContent]);

    const handleAccept = () => {
      setDescription(editedContent.description);
      setBulletPoint1(editedContent.bulletPoints[0] || '');
      setBulletPoint2(editedContent.bulletPoints[1] || '');
      setBulletPoint3(editedContent.bulletPoints[2] || '');
      setBulletPoint4(editedContent.bulletPoints[3] || '');
      setBulletPoint5(editedContent.bulletPoints[4] || '');
      
      enqueueSnackbar('New content has been updated', { variant: 'success' });
      setOpenComparisonDialog(false);
    };

    return (
      <Dialog 
        open={openComparisonDialog} 
        onClose={() => setOpenComparisonDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Content Comparison</DialogTitle>
        <DialogContent sx={{ pb: '120px' }}>
          <Box display="flex" flexDirection="column" gap={2}>
            {/* Title (read-only) */}
            <Box>
              <Typography variant="subtitle1" gutterBottom><strong>Title</strong></Typography>
              <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1, minHeight: '50px', backgroundColor: '#f5f5f5' }}>
                {title}
              </Box>
            </Box>

            {/* Description - Updated styling */}
            <Box>
              <Typography variant="subtitle1" gutterBottom><strong>Description</strong></Typography>
              <Box sx={{ 
                p: 2, 
                border: '1px solid #ddd', 
                borderRadius: '4px 4px 0 0',
                backgroundColor: 'white',
                minHeight: 'fit-content'
              }}>
                {compareText(description, bufferedContent?.description)}
              </Box>
              <Divider sx={{ my: 1 }} /> {/* Add divider */}
              <TextField
                multiline
                rows={6}  // Increased rows
                fullWidth
                value={editedContent.description}
                onChange={(e) => setEditedContent({
                  ...editedContent,
                  description: e.target.value
                })}
                sx={{
                  '& .MuiInputBase-root': {
                    p: 2,
                    border: '1px solid #ddd',
                    borderRadius: '0 0 4px 4px',
                    backgroundColor: 'white',
                    '& .MuiInputBase-input': {
                      color: 'inherit'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}
              />
            </Box>

            {/* Bullet Points - Updated styling */}
            {[bulletPoint1, bulletPoint2, bulletPoint3, bulletPoint4, bulletPoint5].map((bp, idx) => (
              <Box key={idx}>
                <Typography variant="subtitle1" gutterBottom><strong>Bullet Point {idx + 1}</strong></Typography>
                <Box sx={{ 
                  p: 2, 
                  border: '1px solid #ddd', 
                  borderRadius: '4px 4px 0 0',
                  backgroundColor: 'white',
                  minHeight: 'fit-content'
                }}>
                  {compareText(bp, bufferedContent?.bulletPoints[idx])}
                </Box>
                <Divider sx={{ my: 1 }} /> {/* Add divider */}
                <TextField
                  multiline
                  rows={4}  // Increased rows
                  fullWidth
                  value={editedContent.bulletPoints[idx]}
                  onChange={(e) => {
                    const newBulletPoints = [...editedContent.bulletPoints];
                    newBulletPoints[idx] = e.target.value;
                    setEditedContent({
                      ...editedContent,
                      bulletPoints: newBulletPoints
                    });
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      p: 2,
                      border: '1px solid #ddd',
                      borderRadius: '0 0 4px 4px',
                      backgroundColor: 'white',
                      '& .MuiInputBase-input': {
                        color: 'inherit'
                      }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    }
                  }}
                />
              </Box>
            ))}

            {/* Backend Keywords (read-only) */}
            <Box>
              <Typography variant="subtitle1" gutterBottom><strong>Backend Keywords</strong></Typography>
              <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1, minHeight: '50px', backgroundColor: '#f5f5f5' }}>
                {backendKeywordsAsin}
              </Box>
            </Box>

            {/* Score sections at bottom */}
            <Box sx={{ 
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              p: 2,
              backgroundColor: 'background.paper',
              borderTop: '1px solid #ddd',
              minHeight: '100px'
            }}>
              {/* Score Breakdown - Left Side */}
              {chatGPTScore && (
                <Box sx={{ 
                  position: 'absolute',
                  bottom: 20,
                  left: 24,
                  width: '45%'  // Slightly wider
                }}>
                  <Typography variant="h6" gutterBottom sx={{ fontSize: '13px' }}>  {/* Smaller font */}
                    Score Breakdown
                  </Typography>
                  <Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
                    <Typography sx={{ fontSize: '12px' }}>N-gram Presence: {Math.round(chatGPTScore.details.NGP)}%</Typography>
                    <Typography sx={{ fontSize: '12px' }}>Keyword Volume: {Math.round(chatGPTScore.details.KVP)}%</Typography>
                    <Typography sx={{ fontSize: '12px' }}>Readability Score: {Math.round(chatGPTScore.details.R)}%</Typography>
                    <Typography sx={{ fontSize: '12px' }}>Grammar Score: {Math.round(chatGPTScore.details.G)}%</Typography>
                  </Box>
                </Box>
              )}

              {/* ChatGPT Score - Center */}
              {chatGPTScore && (
                <Box sx={{ 
                  position: 'absolute',
                  bottom: 20,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  textAlign: 'center'
                }}>
                  <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 'bold', mb: 1 }}>
                    ChatGPT Score: {Math.round(chatGPTScore.seo_score)}%
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={chatGPTScore.seo_score}
                    sx={{
                      width: 180,  // Slightly narrower
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: '#e0e0e0',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: getProgressBarColor(chatGPTScore.seo_score),
                        borderRadius: 4,
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenComparisonDialog(false)}
            sx={{ color: 'red' }}
          >
            Reject
          </Button>
          <Button 
            onClick={handleAccept}
            sx={{ color: 'green' }}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Add this helper function at the top level of the component
  const ensureCompleteContent = (content) => {
    if (!content) return '';
    
    // Split into sentences using regex that accounts for multiple punctuation types
    const sentences = content.split(/(?<=[.!?])\s+/);
    
    // If the last item doesn't end with punctuation, it's incomplete
    if (sentences.length > 0 && !sentences[sentences.length - 1].match(/[.!?]$/)) {
      // Remove the incomplete sentence
      sentences.pop();
    }
    
    // Join complete sentences back together
    return sentences.join(' ').trim();
  };

  // Add a character limit enforcer function
  const enforceCharacterLimit = (content, limit) => {
    if (!content) return '';
    
    // If content is already within limit, return as is
    if (content.length <= limit) return content;
    
    // Split into sentences
    const sentences = content.split(/(?<=[.!?])\s+/);
    let result = '';
    
    // Add sentences until we reach the limit
    for (let sentence of sentences) {
      if ((result + sentence).length <= limit) {
        result += (result ? ' ' : '') + sentence;
      } else {
        break;
      }
    }
    
    return result.trim();
  };

  // Update the handleRegenerate function
  const handleRegenerate = async () => {
    try {
      setLoading(true);
      setIsRegenerating(true);
      setError(null);

      // Define contentType variable
      const contentType = 'content';

      const currentContent = {
        title: title,
        description,
        bullet_points: [bulletPoint1, bulletPoint2, bulletPoint3, bulletPoint4, bulletPoint5],
        backend_keywords: backendKeywordsAsin
      };

      const missingKeywordsList = frankensteinData
        .filter(item => !item.present)
        .map(item => item.ngram)
        .slice(0, 10);

      // Get current and previous scores
      const currentSeoScore = seoScore || 0;
      const previousGeneratedScore = chatGPTScore ? chatGPTScore.seo_score : 0;

      // Generate content for description and all bullet points
      const responses = await Promise.all([
        // Description
        fetch('/api/seo/generate_content', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            prompt: `Please improve this description while incorporating these missing keywords naturally: ${missingKeywordsList.join(', ')}

Current description:
${description}`,
            is_retry: true,
            previous_content: currentContent,
            current_seo_score: currentSeoScore,
            previous_generated_score: previousGeneratedScore
          })
        }),
        // Bullet Point 1
        fetch('/api/seo/generate_content', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            prompt: `Please improve this bullet point while incorporating these missing keywords naturally: ${missingKeywordsList.join(', ')}

Current bullet point 1:
${bulletPoint1}`,
            is_retry: true,
            previous_content: currentContent,
            current_seo_score: currentSeoScore,
            previous_generated_score: previousGeneratedScore
          })
        }),
        // Bullet Point 2
        fetch('/api/seo/generate_content', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            prompt: `Please improve this bullet point while incorporating these missing keywords naturally: ${missingKeywordsList.join(', ')}

Current bullet point 2:
${bulletPoint2}`,
            is_retry: true,
            previous_content: currentContent,
            current_seo_score: currentSeoScore,
            previous_generated_score: previousGeneratedScore
          })
        }),
        // Bullet Point 3
        fetch('/api/seo/generate_content', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            prompt: `Please improve this bullet point while incorporating these missing keywords naturally: ${missingKeywordsList.join(', ')}

Current bullet point 3:
${bulletPoint3}`,
            is_retry: true,
            previous_content: currentContent,
            current_seo_score: currentSeoScore,
            previous_generated_score: previousGeneratedScore
          })
        }),
        // Bullet Point 4
        fetch('/api/seo/generate_content', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            prompt: `Please improve this bullet point while incorporating these missing keywords naturally: ${missingKeywordsList.join(', ')}

Current bullet point 4:
${bulletPoint4}`,
            is_retry: true,
            previous_content: currentContent,
            current_seo_score: currentSeoScore,
            previous_generated_score: previousGeneratedScore
          })
        }),
        // Bullet Point 5
        fetch('/api/seo/generate_content', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            prompt: `Please improve this bullet point while incorporating these missing keywords naturally: ${missingKeywordsList.join(', ')}

Current bullet point 5:
${bulletPoint5}`,
            is_retry: true,
            previous_content: currentContent,
            current_seo_score: currentSeoScore,
            previous_generated_score: previousGeneratedScore
          })
        })
      ]);

      // Check if any response failed
      for (const response of responses) {
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to generate content');
        }
      }

      // Parse all responses
      const [
        descriptionRes,
        bullet1Res,
        bullet2Res,
        bullet3Res,
        bullet4Res,
        bullet5Res
      ] = await Promise.all(responses.map(r => r.json()));

      // Update bufferedContent with processed and length-limited content
      setBufferedContent({
        description: enforceCharacterLimit(
          ensureCompleteContent(descriptionRes?.description || ''),
          MAX_LENGTHS.description
        ),
        bulletPoints: [
          enforceCharacterLimit(ensureCompleteContent(bullet1Res?.description || ''), MAX_LENGTHS.bulletPoint),
          enforceCharacterLimit(ensureCompleteContent(bullet2Res?.description || ''), MAX_LENGTHS.bulletPoint),
          enforceCharacterLimit(ensureCompleteContent(bullet3Res?.description || ''), MAX_LENGTHS.bulletPoint),
          enforceCharacterLimit(ensureCompleteContent(bullet4Res?.description || ''), MAX_LENGTHS.bulletPoint),
          enforceCharacterLimit(ensureCompleteContent(bullet5Res?.description || ''), MAX_LENGTHS.bulletPoint)
        ],
        backendKeywords: backendKeywordsAsin
      });

      // Calculate SEO score with length-limited content
      const seoResponse = await fetch('/api/seo/calculate_seo_score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country,
          asin,
          title: title,
          bullet_points: [
            enforceCharacterLimit(ensureCompleteContent(bullet1Res?.description || ''), MAX_LENGTHS.bulletPoint),
            enforceCharacterLimit(ensureCompleteContent(bullet2Res?.description || ''), MAX_LENGTHS.bulletPoint),
            enforceCharacterLimit(ensureCompleteContent(bullet3Res?.description || ''), MAX_LENGTHS.bulletPoint),
            enforceCharacterLimit(ensureCompleteContent(bullet4Res?.description || ''), MAX_LENGTHS.bulletPoint),
            enforceCharacterLimit(ensureCompleteContent(bullet5Res?.description || ''), MAX_LENGTHS.bulletPoint)
          ],
          description: enforceCharacterLimit(
            ensureCompleteContent(descriptionRes?.description || ''),
            MAX_LENGTHS.description
          ),
          backend_keywords_asin: backendKeywordsAsin,
          backend_keywords_line: backendKeywordsLine,
          image_alt_tags: imageAltTags,
          frankenstein_data: frankensteinData,
          irrelevant_keywords: irrelevantKeywords.split(/\s+/).filter(k => k)
        }),
      });

      if (!seoResponse.ok) {
        const errorData = await seoResponse.json();
        throw new Error(errorData.error || 'Failed to calculate SEO score for new content');
      }

      const chatGPTScoreResponse = await seoResponse.json();
      setChatGPTScore(chatGPTScoreResponse);
      setOpenComparisonDialog(true);

    } catch (error) {
      console.error('Error in handleRegenerate:', error);
      setError(error.message);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
      setIsRegenerating(false);
    }
  };

  // Update renderMissingKeywords function
  const renderMissingKeywords = () => {
    const contentToCheck = `${title} ${[bulletPoint1, bulletPoint2, bulletPoint3, bulletPoint4, bulletPoint5].join(' ')} ${description} ${backendKeywordsAsin} ${backendKeywordsLine}`;
    const irrelevantSet = new Set(irrelevantKeywords.split(/\s+/).filter(k => k));
    const { missingNGrams, missingVolume } = getMissingKeywordsInfo(frankensteinData, contentToCheck);

    // Filter out irrelevant keywords
    const filteredMissingNGrams = missingNGrams.filter(item => !irrelevantSet.has(item.ngram));
    const filteredMissingVolume = missingVolume.filter(item => !irrelevantSet.has(item.ngram));

    return (
      <Box sx={{ mt: 3, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
        <Typography variant="h6" gutterBottom>Optimization Suggestions</Typography>
        
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3}>
          <Box>
            <Typography variant="subtitle1" fontWeight="bold" color="primary">
              Top Missing N-grams (by frequency):
            </Typography>
            <ul>
              {filteredMissingNGrams.map((item, idx) => (
                <li key={idx}>{item.ngram} (frequency: {item.frequency})</li>
              ))}
            </ul>
          </Box>
          
          <Box>
            <Typography variant="subtitle1" fontWeight="bold" color="primary">
              Top Missing Keywords (by volume):
            </Typography>
            <ul>
              {filteredMissingVolume.map((item, idx) => (
                <li key={idx}>{item.ngram} (volume: {item.volume})</li>
              ))}
            </ul>
          </Box>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handleRegenerate}
          sx={{ mt: 2 }}
        >
          Re-Generate Score
        </Button>
      </Box>
    );
  };

  // Add near the top of the component, after state declarations
  const handleAddToMKL = () => {
    window.open('https://docs.google.com/spreadsheets/d/1ja_8ojU82s_XWviBBMalPaItdoUj70SoT2FLNdF02Hs/edit?gid=1540640371#gid=1540640371', '_blank');
  };

  const handleAddCerebroData = () => {
    window.open('/etl_business_report', '_blank');
  };

  useEffect(() => {
    // Add custom styles to document head
    const styleElement = document.createElement('style');
    styleElement.innerHTML = customGridStyles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <Box m="20px" height="100vh" display="flex" flexDirection="column">
      {/* Loading Dialog */}
      <Dialog
        open={isRegenerating}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'hidden'
          }
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={3}
        >
          <CircularProgress 
            size={60} 
            sx={{ 
              color: '#fff',
              '& .MuiCircularProgress-circle': {
                strokeWidth: 4
              }
            }} 
          />
          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              mt: 2,
              textAlign: 'center',
              textShadow: '0 2px 4px rgba(0,0,0,0.5)'
            }}
          >
            Generating Improved Content...
          </Typography>
        </Box>
      </Dialog>

      <Box 
        sx={{ 
          position: 'sticky',
          top: 0,
          zIndex: 1100,
          height: '80px',
          backgroundColor: theme.palette.background.default,
          borderBottom: `none`,
          pt: 1,
          pb: 1,
          px: 3,
          mb: 2
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" height="100%">
          <Box display="flex" alignItems="center" gap={2}>

            <Typography variant="h4" sx={{ 
              fontSize: '1.3rem',
              fontWeight: 'bold' // Add bold
            }}>
              SEO Module
            </Typography>
            
            {contentFetched && (
              <Box display="flex" gap={2}>
                <TextField
                  label="Country"
                  value={country}
                  size="small"
                  disabled
                  sx={{ width: 80 }}
                />
                <TextField
                  label="ASIN"
                  value={asin}
                  size="small"
                  disabled
                  sx={{ width: 120 }}
                />
                <TextField
                  label="Line"
                  value={line}
                  size="small"
                  disabled
                  sx={{ width: 120 }}
                />
              </Box>
            )}
          </Box>

          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              onClick={handleAddToMKL}
              sx={{
                backgroundColor: colors.primary[400],
                color: colors.grey[100],
                fontSize: '12px',
                fontWeight: 'bold',
                '&:hover': { backgroundColor: colors.primary[500], color: 'white' }
              }}
            >
              Add to MKL
            </Button>
            <Button
              variant="contained"
              onClick={handleAddCerebroData}
              sx={{
                backgroundColor: colors.primary[400],
                color: colors.grey[100],
                fontSize: '13px',
                fontWeight: 'bold',
                '&:hover': { backgroundColor: colors.primary[500], color: 'white' }
              }}

            >
              Add Cerebro Data
            </Button>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gap={2}>  {/* Main container with flex layout */}
        {/* Left side - Input fields */}
        <Box flex={1} maxWidth="65%">  {/* Increased from 60% to 65% */}
          <Box display="flex" flexDirection="column" gap={1}>  {/* Reduced gap */}
            <Box display="flex" gap={2} mt={1}>
              <TextField
                label="ASIN"
                value={asin}
                onChange={(e) => {
                  setAsin(e.target.value.toUpperCase());
                  setContentFetched(false);
                }}
                placeholder="Enter ASIN"
                fullWidth
                size="small"  
              />
              <Autocomplete
                freeSolo
                value={line}
                onChange={(event, newValue) => {
                  setLine(newValue || '');
                  setContentFetched(false);
                }}
                inputValue={line}
                onInputChange={(event, newInputValue) => {
                  setLine(newInputValue);
                  setContentFetched(false);
                }}
                options={lines.sort((a, b) => a.localeCompare(b))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Line"
                    placeholder="Select or type line"
                    size="small"
                  />
                )}
                size="small"
                fullWidth
              />
              <Autocomplete
                freeSolo
                value={country}
                onChange={(event, newValue) => {
                  setCountry(newValue || '');
                  setContentFetched(false);
                }}
                inputValue={country}
                onInputChange={(event, newInputValue) => {
                  setCountry(newInputValue);
                  setContentFetched(false);
                }}
                options={countries.sort((a, b) => a.localeCompare(b))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    placeholder="Select or type country"
                    size="small"
                  />
                )}
                size="small"
                fullWidth
              />
              <Button
                variant="contained"
                onClick={handleFetchContent}
                disabled={!country || !asin || !line || loading}
                sx={{ minWidth: '120px' }}
                size="small"
              >
                Fetch
              </Button>
            </Box>

            {/* Content fields with reduced font size */}
            <TextField
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              multiline
              rows={2}
              disabled={!contentFetched}
              size="small"
              InputProps={{
                endAdornment: renderCharCount(title.length, MAX_LENGTHS.title),
                style: { fontSize: '12px' }  // Reduced content text font size
              }}
              sx={{
                '& .MuiInputBase-root': { 
                  padding: '8px',
                },
              }}
            />

            {[
              { value: bulletPoint1, setter: setBulletPoint1, label: "Bullet Point 1" },
              { value: bulletPoint2, setter: setBulletPoint2, label: "Bullet Point 2" },
              { value: bulletPoint3, setter: setBulletPoint3, label: "Bullet Point 3" },
              { value: bulletPoint4, setter: setBulletPoint4, label: "Bullet Point 4" },
              { value: bulletPoint5, setter: setBulletPoint5, label: "Bullet Point 5" }
            ].map((bp, idx) => (
              <TextField
                key={idx}
                label={bp.label}
                value={bp.value}
                onChange={(e) => bp.setter(e.target.value)}
                multiline
                rows={2}
                fullWidth
                disabled={!contentFetched}
                size="small"
                InputProps={{
                  endAdornment: renderCharCount(bp.value.length, MAX_LENGTHS.bulletPoint),
                  style: { fontSize: '12px' }  // Reduced content text font size
                }}
                error={bp.value.length > MAX_LENGTHS.bulletPoint}
                helperText={bp.value.length > MAX_LENGTHS.bulletPoint ? "Exceeds maximum length" : null}
                sx={{
                  '& .MuiInputBase-root': {
                    padding: '8px',
                  },
                }}
              />
            ))}

            {/* Other text fields with similar styling */}
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
              fullWidth
              disabled={!contentFetched}
              size="small"
              InputProps={{
                endAdornment: renderCharCount(description.length, MAX_LENGTHS.description),
                style: { fontSize: '12px' }  // Added consistent font size
              }}
              sx={{
                '& .MuiInputBase-root': {
                  padding: '8px',
                },
              }}
            />

            <TextField
              label="Backend Keywords of ASIN"
              value={backendKeywordsAsin}
              onChange={(e) => setBackendKeywordsAsin(e.target.value)}
              multiline
              rows={3}
              fullWidth
              disabled={!contentFetched}
              size="small"
              InputProps={{
                endAdornment: renderCharCount(backendKeywordsAsin.length, MAX_LENGTHS.backendKeywordsAsin),
                style: { fontSize: '12px' }  // Added consistent font size
              }}
              sx={{
                '& .MuiInputBase-root': {
                  padding: '8px',
                },
              }}
            />

            <TextField
              label="Backend Keywords of Line"
              value={backendKeywordsLine}
              onChange={(e) => setBackendKeywordsLine(e.target.value)}
              multiline
              rows={3}
              fullWidth
              disabled={!contentFetched}
              size="small"
              InputProps={{
                style: { fontSize: '12px' }  // Added consistent font size
              }}
              sx={{
                '& .MuiInputBase-root': {
                  padding: '8px',
                },
              }}
            />

            <TextField
              label="Image Alt Tags"
              value={imageAltTags}
              onChange={(e) => setImageAltTags(e.target.value)}
              multiline
              rows={2}
              fullWidth
              disabled={!contentFetched}
              size="small"
              InputProps={{
                endAdornment: renderCharCount(imageAltTags.length, MAX_LENGTHS.imageAltTags),
                style: { fontSize: '12px' }  // Added consistent font size
              }}
              helperText="Add keywords for image alt tags (these will be included in SEO score calculation)"
              sx={{
                '& .MuiInputBase-root': {
                  padding: '8px',
                },
              }}
            />

            <TextField
              label="Irrelevant Keywords"
              value={irrelevantKeywords}
              onChange={(e) => setIrrelevantKeywords(e.target.value)}
              multiline
              rows={2}
              fullWidth
              disabled={!contentFetched}
              size="small"
              InputProps={{
                style: { fontSize: '12px' }
              }}
              helperText="Add keywords to exclude from SEO score calculation (space separated)"
              sx={{
                '& .MuiInputBase-root': {
                  padding: '8px',
                },
              }}
            />

            {/* Check SEO Score button */}
            {contentFetched && (
              <Button
                variant="contained"
                onClick={handleCheckSEO}
                disabled={loading}
                size="medium"
                sx={{ 
                  width: '200px',  // Fixed width
                  alignSelf: 'flex-start',  // Align to left
                  mt: 1  // Small margin top
                }}
              >
                {loading ? "Calculating..." : "Check SEO Score"}
              </Button>
            )}

            {/* SEO Score results */}
            {seoScore !== null && (
              <Box sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                {renderSEOScore()}
                {details && renderMissingKeywords()}
                {chatGPTScore && renderChatGPTScore()}
              </Box>
            )}
          </Box>
        </Box>

        {/* Right side - Tables */}
        {contentFetched && (
          <Box flex={1} display="flex" flexDirection="column" gap={2} maxWidth="32%">  {/* Changed gap to 2 for more spacing */}
            {/* MKL Table */}
            <Box
              backgroundColor={colors.primary[400]}
              p="20px"
              borderRadius="4px"
            >
              <Typography variant="h6" gutterBottom sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                MKL
              </Typography>
              <div className="ag-theme-alpine" style={{ width: '100%', height: '350px' }}>
                <AgGridReact
                  rowData={mklData}
                  columnDefs={mklColumns}
                  defaultColDef={{
                    flex: 0.6,
                    minWidth: 45,
                    sortable: true,
                    filter: true,
                    resizable: true,
                  }}
                  pagination={true}
                  paginationPageSize={10}
                  sideBar={false}
                  {...AGGrid_Options}
                />
              </div>
            </Box>

            {/* Frankenstein Table */}
            <Box
              backgroundColor={colors.primary[400]}
              p="20px"
              borderRadius="4px"
            >
              <Typography variant="h6" gutterBottom sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                Frankenstein
              </Typography>
              <div className="ag-theme-alpine" style={{ width: '100%', height: '350px' }}>
                <AgGridReact
                  rowData={frankensteinData}
                  columnDefs={frankensteinColumns}
                  defaultColDef={{
                    flex: 0.6,
                    minWidth: 45,
                    sortable: true,
                    filter: true,
                    resizable: true,
                  }}
                  pagination={true}
                  paginationPageSize={10}
                  sideBar={false}
                  {...AGGrid_Options}
                />
              </div>
            </Box>

            {/* Missing Keywords */}
            <Box
              backgroundColor={colors.primary[400]}
              p="20px"
              borderRadius="4px"
            >
              <Typography variant="h6" gutterBottom sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                Missing Keywords
              </Typography>
              <TextField
                value={missingKeywords}
                multiline
                rows={10}
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { fontSize: '10px' }
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    minHeight: '100px',
                    maxHeight: '350px',
                    height: 'auto !important',
                    overflow: 'auto',
                    backgroundColor: 'background.paper'
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '12px',
                    padding: '8px',
                    lineHeight: 1.4,
                    height: 'auto !important',
                    overflowY: 'auto'
                  }
                }}
              />
            </Box>
          </Box>
        )}
      </Box>

      {error && (
        <Typography color="error" textAlign="center">
          {error}
        </Typography>
      )}
      
      <ContentComparisonDialog />
    </Box>
  );
};

export default SEOModule;
