import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Divider
} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const displayHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
  return `${day}/${month}/${year} ${displayHours}:${minutes} ${ampm}`;
};

const formatDateTimeForInput = (date) => {
  const localDate = new Date(date);
  const year = localDate.getFullYear();
  const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
  const day = localDate.getDate().toString().padStart(2, '0');
  const hours = localDate.getHours().toString().padStart(2, '0');
  const minutes = localDate.getMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const ScratchpadNotes = ({ line, country, sku, note_prefix }) => {
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [noteDateTime, setNoteDateTime] = useState(formatDateTimeForInput(new Date()));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const fetchNotes = async () => {
    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams({ line });
      if (country) queryParams.append('country', country);
      if (sku) queryParams.append('sku', sku);
      
      const response = await fetch(`/api/scratchpad/notes?${queryParams}`);
      if (!response.ok) throw new Error('Failed to fetch notes');
      
      const data = await response.json();
      setNotes(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setNoteDateTime(formatDateTimeForInput(new Date()));
    fetchNotes();
  };

  const handleClose = () => {
    setOpen(false);
    setNewNote('');
    setNoteDateTime(formatDateTimeForInput(new Date()));
  };

  const handleSubmit = async () => {
    if (!newNote.trim()) return;

    try {
        setError(null);
        setSuccessMessage('');
        setLoading(true);

        // Parse the local datetime string
        const [datePart, timePart] = noteDateTime.split('T');
        const [hours, minutes] = timePart.split(':');
        const [year, month, day] = datePart.split('-');
        
        // Create Date object in local time
        const localDate = new Date(
            parseInt(year),
            parseInt(month) - 1, // Month is 0-based in JavaScript
            parseInt(day),
            parseInt(hours),
            parseInt(minutes)
        );

        console.log('Local Date:', localDate.toString());
        console.log('Local Time:', `${hours}:${minutes}`);
        console.log('Local ISO:', localDate.toISOString());
        
        // Convert to UTC
        const utcHours = localDate.getUTCHours();
        const utcMinutes = localDate.getUTCMinutes();
        const utcYear = localDate.getUTCFullYear();
        const utcMonth = localDate.getUTCMonth();
        const utcDay = localDate.getUTCDate();
        
        const utcDate = new Date(Date.UTC(utcYear, utcMonth, utcDay, utcHours, utcMinutes));

        // Add note_prefix if provided
        const noteContent = note_prefix ? `${note_prefix}\n${newNote.trim()}` : newNote.trim();

        const response = await fetch('/api/scratchpad/notes', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                line,
                country,
                sku,
                content: noteContent,
                timestamp: utcDate.toISOString()
            })
        });

        if (!response.ok) throw new Error('Failed to create note');

        setNewNote('');
        setNoteDateTime(formatDateTimeForInput(new Date()));
        setSuccessMessage('Note added successfully');
        
        await new Promise(resolve => setTimeout(resolve, 1000));
        await fetchNotes();
        
        setTimeout(() => setSuccessMessage(''), 3000);
        
    } catch (err) {
        setError(err.message);
    } finally {
        setLoading(false);
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <EditNoteIcon />
      </IconButton>

      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="bold">Notes for {line} {country ? `(${country})` : ''} {sku ? `- ${sku}` : ''}</Typography>
            <TextField
              type="datetime-local"
              label="Date and Time"
              value={noteDateTime}
              onChange={(e) => setNoteDateTime(e.target.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: '220px' }}
            />
          </Box>
        </DialogTitle>
        
        <DialogContent>
          {error && (
            <Typography color="error" gutterBottom>
              Error: {error}
            </Typography>
          )}
          
          {successMessage && (
            <Typography color="success.main" gutterBottom>
              {successMessage}
            </Typography>
          )}

          <Box mb={3}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              placeholder="Add a new note..."
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              margin="normal"
            />
            <Box display="flex" justifyContent="flex-end" mt={1} mb={2}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSubmit}
                disabled={!newNote.trim() || loading}
              >
                {loading ? 'Adding...' : 'Add Note'}
              </Button>
            </Box>
          </Box>

          <Divider />

          {loading ? (
            <Typography>Loading notes...</Typography>
          ) : notes.length > 0 ? (
            <List>
              {notes.map((note) => (
                <ListItem 
                  key={`${note.id}-${note.version_number}`} 
                  divider
                  secondaryAction={
                    <Typography variant="caption" color="textSecondary">
                      {formatDateTime(note.updated_at)}
                    </Typography>
                  }
                >
                  <ListItemText 
                    primary={
                      <Typography
                        component="pre"
                        style={{
                          fontFamily: 'inherit',
                          whiteSpace: 'pre-wrap',
                          margin: 0
                        }}
                      >
                        {note.content}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color="textSecondary" align="center" sx={{ my: 2 }}>
              No notes found
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScratchpadNotes; 