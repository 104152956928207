import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Divider, Drawer, FormControl, IconButton, 
  InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import QuickFilter from '../../components/global/QuickFilter.jsx';
import { columns } from './keywordSpendsColumns';
import AGGrid_Options from "../../components/global/AGGrid_Options";
import AGGrid_Sidebar from "../../components/global/AGGrid_Sidebar";
import { toast } from 'react-toastify';
import { getTableData } from '../../utils/gridUtils';
import { logEvent } from '../../utils/logEvent';
import ProductLineInfo from '../product_line_info/product_line_info';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DateRangePicker from '../../components/global/DateRangePicker.jsx';
import dayjs from 'dayjs';

const KeywordSpends = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [countries, setCountries] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLine, setSelectedLine] = useState('');
  const [quickFilterText, setQuickFilterText] = useState('');
  const [keywordData, setKeywordData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isClassifying, setIsClassifying] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [kypData, setKypData] = useState(null);
  const [includeKeywords, setIncludeKeywords] = useState("");
  const [includeAndKeywords, setIncludeAndKeywords] = useState("");
  const [excludeKeywords, setExcludeKeywords] = useState("");
  const [filteredKeywordData, setFilteredKeywordData] = useState([]);
  const [hasLabels, setHasLabels] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').toDate());
  const [endDate, setEndDate] = useState(dayjs().subtract(1, 'day').toDate());
  const [aiSearchQuery, setAiSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  
  const gridRef = useRef(null);

  // Add checkbox column to the beginning of columns array
  const enhancedColumns = [
    {
      headerName: "",
      field: "cb",
      width: 35,
      checkboxSelection: (params) => !!params.data,
      headerCheckboxSelection: true,
      suppressMenu: true,
      filter: false,
      pinned: 'left',
      cellRenderer: (params) => {
        if (!params.data) return '';
        return params.value;
      }
    },
    ...columns,
    {
      headerName: "",
      field: "approve_button",
      width: 50,
      sortable: false,
      filter: false,
      cellRenderer: (params) => {
        if (!params.data || !params.data.label || params.data.label_source === 'user') return null;
        return (
          <Tooltip title="Approve this label">
            <IconButton
              size="small"
              onClick={() => handleApproveLabel(params)}
              color={params.data.label_source === 'user' ? 'primary' : 'default'}
            >
              <ThumbUpIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      }
    }
  ];

  useEffect(() => {
    console.log("Component mounted, initializing data fetch");
    // Get URL parameters
    const params = new URLSearchParams(window.location.search);
    const countryParam = params.get('country');
    const lineParam = params.get('line');

    console.log(`URL parameters - country: ${countryParam}, line: ${lineParam}`);

    // First fetch countries and lines
    fetchCountriesAndLines().then(() => {
      // After fetching, set the values from URL if they exist
      if (countryParam) {
        console.log(`Setting country from URL: ${countryParam}`);
        setSelectedCountry(countryParam);
      }
      if (lineParam) {
        console.log(`Setting line from URL: ${lineParam}`);
        setSelectedLine(lineParam);
      }
      setIsInitialized(true);
    });
  }, []); // Only run on mount

  useEffect(() => {
    if (!isInitialized) {
      return; // Wait for initialization
    }

    // No longer fetch data automatically after initialization
    console.log(`Country and line selected - country: ${selectedCountry}, line: ${selectedLine}`);
    // fetchKeywordData() - removed to prevent automatic loading
  }, [isInitialized, selectedCountry, selectedLine]);

  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };

  const fetchCountriesAndLines = async () => {
    try {
      console.log("Fetching countries and lines data");
      const response = await fetch('/api/line-details/get-countries-lines', fetchOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const uniqueCountries = [...new Set(data.map(item => item.country))];
      const uniqueLines = [...new Set(data.map(item => item.line))];
      console.log(`Retrieved ${uniqueCountries.length} countries and ${uniqueLines.length} lines`);
      setCountries(uniqueCountries);
      setLines(uniqueLines);
    } catch (error) {
      console.error('Error fetching countries and lines:', error);
      setError(error.message);
    }
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    // Fetch data with new date range if country and line are selected
    if (selectedCountry || selectedLine) {
      fetchKeywordData(start, end);
    }
    
    // Log the event
    logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'date_changed', {
      country: selectedCountry || 'all',
      line: selectedLine || 'all',
      start_date: dayjs(start).format('YYYY-MM-DD'),
      end_date: dayjs(end).format('YYYY-MM-DD')
    });
  };

  const fetchKeywordData = async (start = startDate, end = endDate) => {
    try {
      console.log("Starting keyword data fetch");
      setLoading(true);
      let url = '/api/keyword_explorer/get_keywords';
      
      // Add filters to URL if they exist
      const params = new URLSearchParams();
      if (selectedCountry) params.append('country', selectedCountry);
      if (selectedLine) params.append('line', selectedLine);
      
      // Add date range parameters
      params.append('start_date', dayjs(start).format('YYYY-MM-DD'));
      params.append('end_date', dayjs(end).format('YYYY-MM-DD'));
      
      const queryString = params.toString();
      url += `?${queryString}`;
      
      console.log(`Fetching keyword data from: ${url}`);
      const response = await fetch(url, fetchOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(`Retrieved ${data.length} keyword records`);
      
      // Check if any keywords have labels
      const hasAnyLabels = data.some(item => item.label);
      setHasLabels(hasAnyLabels);
      
      setKeywordData(data);
      setError(null);
      
      // Log the event
      await logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'data_loaded', {
        country: selectedCountry || 'all',
        line: selectedLine || 'all',
        start_date: dayjs(start).format('YYYY-MM-DD'),
        end_date: dayjs(end).format('YYYY-MM-DD'),
        record_count: data.length
      });
    } catch (error) {
      console.error('Error fetching keyword data:', error);
      setError(error.message);
      setKeywordData([]);
      setHasLabels(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchKypData = async () => {
    try {
      const response = await fetch(`/api/line/kyp?country=${selectedCountry}&line=${selectedLine}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setKypData(data);
      return data;
    } catch (error) {
      console.error('Error fetching KYP data:', error);
      return null;
    }
  };

  useEffect(() => {
    if (selectedCountry && selectedLine) {
      fetchKypData();
    }
  }, [selectedCountry, selectedLine]);

  const checkKypAndProceed = async (action) => {
    let kyp = kypData;
    if (!kyp) {
      kyp = await fetchKypData();
    }

    if (!kyp || !kyp.product_notes) {
      toast.error("KYP Product Notes must be filled before running AI Classification");
      setDrawerOpen(true);
      return false;
    }
    return true;
  };

  const handleCountryChange = (event) => {
    console.log(`Country selection changed to: ${event.target.value}`);
    setSelectedCountry(event.target.value);
    
    // Update URL parameters
    const params = new URLSearchParams(window.location.search);
    if (event.target.value) {
      params.set('country', event.target.value);
    } else {
      params.delete('country');
    }
    if (selectedLine) {
      params.set('line', selectedLine);
    }
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);

  };

  const handleLineChange = (event) => {
    console.log(`Line selection changed to: ${event.target.value}`);
    setSelectedLine(event.target.value);
    
    // Update URL parameters
    const params = new URLSearchParams(window.location.search);
    if (event.target.value) {
      params.set('line', event.target.value);
    } else {
      params.delete('line');
    }
    if (selectedCountry) {
      params.set('country', selectedCountry);
    }
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);

  };

  const handleQuickFilterChange = useCallback((filter) => {
    setQuickFilterText(filter);
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setQuickFilterText('');
  }, []);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
    logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'export_csv', {
      country: selectedCountry || 'all',
      line: selectedLine || 'all',
      filtered: quickFilterText ? true : false
    });
  }, [selectedCountry, selectedLine, quickFilterText]);

  const handleCopyTableJson = () => {
    if (!gridRef.current || !gridRef.current.api) return;
    
    const tableData = getTableData(gridRef.current.api);
    navigator.clipboard.writeText(JSON.stringify(tableData, null, 2))
      .then(() => {
        console.log('Table JSON copied to clipboard');
        logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'copy_table_json');
      })
      .catch(err => {
        console.error('Failed to copy table JSON:', err);
      });
  };

  const handleClassifySelection = async () => {
    if (!selectedCountry || !selectedLine) {
      toast.error("Please select both country and line first");
      return;
    }

    // Get only the visible selected nodes from the current page
    const selectedNodes = gridRef.current.api.getRenderedNodes()
      .filter(node => node.selected);

    if (!selectedNodes || selectedNodes.length === 0) {
      toast.error("Please select at least one keyword to classify");
      return;
    }

    // Filter out user-labeled keywords and get search terms
    const selectedKeywords = selectedNodes
      .filter(node => node.data && node.data.keyword && (!node.data.label_source || node.data.label_source !== 'user'))
      .map(node => node.data.keyword);

    if (selectedKeywords.length === 0) {
      toast.error("No valid keywords found in selection. User-labeled keywords will be skipped.");
      return;
    }

    // Add confirmation for large batches
    if (selectedKeywords.length > 100) {
      const confirmed = window.confirm(`You are about to classify ${selectedKeywords.length} keywords. This is a large batch and may take some time. Do you want to proceed?`);
      if (!confirmed) {
        return;
      }
    }

    // Check KYP before proceeding
    if (!(await checkKypAndProceed())) {
      return;
    }

    console.log("Selected keywords for classification:", selectedKeywords);
    setIsClassifying(true);
    try {
      const response = await fetch("/api/keyword_classifier/classify_keywords_batch", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          country: selectedCountry,
          line: selectedLine,
          keywords: selectedKeywords
        })
      });

      const data = await response.json();
      console.log("Classification task response:", data);

      if (response.ok) {
        toast.success(`Classification task queued for ${selectedKeywords.length} keywords. Check Tasks Monitor for status.`);
        
        // Log the event
        await logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'classify_selection', {
          country: selectedCountry,
          line: selectedLine,
          keyword_count: selectedKeywords.length,
          task_id: data.task_id || 'unknown'
        });
      } else {
        throw new Error(data.message || "Failed to queue classification task");
      }
    } catch (error) {
      console.error("Classification error:", error);
      toast.error("Error: " + error.message);
    } finally {
      setIsClassifying(false);
    }
  };

  const handleClassifyLine = async () => {
    if (!selectedCountry || !selectedLine) {
      toast.error("Please select both country and line first");
      return;
    }

    // Get count of keywords in the line
    const totalKeywords = keywordData.length;
    if (totalKeywords > 200) {
      const confirmed = window.confirm(`This line contains ${totalKeywords} keywords to classify. This is a large batch and may take some time. Do you want to proceed?`);
      if (!confirmed) {
        return;
      }
    }

    // Check KYP before proceeding
    if (!(await checkKypAndProceed())) {
      return;
    }

    setIsClassifying(true);
    try {
      const response = await fetch("/api/keyword_classifier/classify_line_keywords", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          country: selectedCountry,
          line: selectedLine
        })
      });

      const data = await response.json();
      if (response.ok) {
        toast.success("Line classification task has been queued. Check Tasks Monitor for status.");
        
        // Log the event
        await logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'classify_line', {
          country: selectedCountry,
          line: selectedLine,
          keyword_count: totalKeywords,
          task_id: data.task_id || 'unknown'
        });
      } else {
        toast.error(data.message || "Failed to queue line classification");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error: " + error.message);
    } finally {
      setIsClassifying(false);
    }
  };

  const handleClassifyPendingKeywords = async () => {
    if (!selectedCountry || !selectedLine) {
      toast.error("Please select both country and line first");
      return;
    }

    // Check KYP before proceeding
    if (!(await checkKypAndProceed())) {
      return;
    }

    setIsClassifying(true);
    try {
      const response = await fetch("/api/keyword_classifier/classify_pending_keywords", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          country: selectedCountry,
          line: selectedLine
        })
      });

      const data = await response.json();
      if (response.ok) {
        toast.success("Classification task for pending keywords has been queued. Check Tasks Monitor for status.");
        
        // Log the event
        await logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'classify_pending', {
          country: selectedCountry,
          line: selectedLine,
          task_id: data.task_id || 'unknown'
        });
      } else {
        toast.error(data.message || "Failed to queue pending keywords classification");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error: " + error.message);
    } finally {
      setIsClassifying(false);
    }
  };

  const handleInitialClassification = async () => {
    if (!selectedCountry || !selectedLine) {
      toast.error("Please select both country and line first");
      return;
    }

    // Check KYP before proceeding
    if (!(await checkKypAndProceed())) {
      return;
    }

    // Get the first 50 rows from the grid
    const firstFiftyKeywords = gridRef.current.api.getDisplayedRowAtIndex(0) ? 
      Array.from({length: 50}, (_, i) => {
        const row = gridRef.current.api.getDisplayedRowAtIndex(i);
        return row ? row.data.keyword : null;
      }).filter(Boolean) : [];

    if (firstFiftyKeywords.length === 0) {
      toast.error("No keywords found in the table");
      return;
    }

    setIsClassifying(true);
    try {
      const response = await fetch("/api/keyword_classifier/classify_keywords_batch", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          country: selectedCountry,
          line: selectedLine,
          keywords: firstFiftyKeywords
        })
      });

      const data = await response.json();
      if (response.ok) {
        toast.success(`Classification task queued for first ${firstFiftyKeywords.length} keywords. Check Tasks Monitor for status.`);
        // Set hasLabels to true after initial classification is queued
        // Note: This is optimistic as we assume the classification will succeed
        setHasLabels(true);
        
        // Log the event
        await logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'initial_classification', {
          country: selectedCountry,
          line: selectedLine,
          keyword_count: firstFiftyKeywords.length,
          task_id: data.task_id || 'unknown'
        });
      } else {
        toast.error(data.message || "Failed to queue initial classification");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error: " + error.message);
    } finally {
      setIsClassifying(false);
    }
  };

  const handleCellValueChanged = async (params) => {
    if (params.column.colId === 'label') {
      try {
        console.log('Updating label with data:', {
          country: params.data.country,
          line: params.data.line,
          keyword: params.data.keyword,
          new_label: params.newValue
        });

        const response = await fetch('/api/keyword_classifier/update_keyword_label', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            country: params.data.country,
            line: params.data.line,
            keyword: params.data.keyword,
            new_label: params.newValue
          })
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }

        console.log('Label updated successfully');
        // Update local data to reflect changes
        params.data.label_source = 'user';
        params.data.confidence_score = 1;
        
        // Ensure hasLabels is set to true when a label is manually changed
        setHasLabels(true);
        
        params.api.refreshCells({
          rowNodes: [params.node],
          columns: ['label', 'label_source', 'confidence_score'],
          force: true
        });
        
        // Log the event
        await logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'label_manually_updated', {
          country: params.data.country,
          line: params.data.line,
          keyword: params.data.keyword,
          new_label: params.newValue,
          old_label: params.oldValue || 'none'
        });
      } catch (error) {
        console.error('Error updating keyword label:', error);
        setError(`Failed to update label: ${error.message}`);
        // Revert the cell value
        params.node.setDataValue('label', params.oldValue);
      }
    }
  };

  const handleApproveLabel = async (params) => {
    try {
      console.log('Approving label with data:', {
        country: params.data.country,
        line: params.data.line,
        keyword: params.data.keyword,
        new_label: params.data.label
      });

      const response = await fetch('/api/keyword_classifier/update_keyword_label', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: params.data.country,
          line: params.data.line,
          keyword: params.data.keyword,
          new_label: params.data.label
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      console.log('Label approved successfully');
      // Update local data to reflect changes
      params.data.label_source = 'user';
      params.data.confidence_score = 1;
      
      // Ensure hasLabels is set to true when a label is approved
      setHasLabels(true);
      
      // Flash the cell green to indicate success
      params.api.flashCells({
        rowNodes: [params.node],
        columns: ['label_source'],
        flashDelay: 1000,
        fadeDelay: 500,
        cssClass: 'flash-success'
      });
      
      params.api.refreshCells({
        rowNodes: [params.node],
        columns: ['label', 'label_source', 'confidence_score', 'approve_button'],
        force: true
      });
      
      // Log the event
      await logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'label_approved', {
        country: params.data.country,
        line: params.data.line,
        keyword: params.data.keyword,
        label: params.data.label,
        previous_source: params.data.label_source || 'none'
      });
    } catch (error) {
      console.error('Error approving label:', error);
      
      // Flash the cell red to indicate error
      params.api.flashCells({
        rowNodes: [params.node],
        columns: ['label_source'],
        flashDelay: 1000,
        fadeDelay: 500,
        cssClass: 'flash-error'
      });
    }
  };

  // Add effect for keyword filtering
  useEffect(() => {
    const includeList = includeKeywords
      .split(',')
      .map(k => k.trim().toLowerCase())
      .filter(k => k.length > 0);
    
    const includeAndList = includeAndKeywords
      .split(',')
      .map(k => k.trim().toLowerCase())
      .filter(k => k.length > 0);
    
    const excludeList = excludeKeywords
      .split(',')
      .map(k => k.trim().toLowerCase())
      .filter(k => k.length > 0);

    let filtered = [...keywordData];

    if (includeList.length > 0) {
      filtered = filtered.filter(row => 
        includeList.some(keyword => 
          row.keyword.toLowerCase().includes(keyword)
        )
      );
    }

    if (includeAndList.length > 0) {
      filtered = filtered.filter(row => 
        includeAndList.every(keyword => 
          row.keyword.toLowerCase().includes(keyword)
        )
      );
    }

    if (excludeList.length > 0) {
      filtered = filtered.filter(row => 
        !excludeList.some(keyword => 
          row.keyword.toLowerCase().includes(keyword)
        )
      );
    }

    setFilteredKeywordData(filtered);
  }, [includeKeywords, includeAndKeywords, excludeKeywords, keywordData]);

  const handleLoadData = () => {
    let loadType = "all data";
    if (selectedCountry && selectedLine) {
      loadType = `data for ${selectedCountry} and ${selectedLine}`;
    } else if (selectedCountry) {
      loadType = `all data for ${selectedCountry}`;
    } else if (selectedLine) {
      loadType = `all data for ${selectedLine} across all countries`;
    }
    
    console.log(`Manually loading ${loadType} for date range ${dayjs(startDate).format('YYYY-MM-DD')} to ${dayjs(endDate).format('YYYY-MM-DD')}`);
    fetchKeywordData(startDate, endDate);
    
    // Log the event
    logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'load_data_clicked', {
      country: selectedCountry || 'all',
      line: selectedLine || 'all',
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD')
    });
  };

  const handleAiSearch = async () => {
    if (!aiSearchQuery.trim()) {
      toast.error("Please enter an AI search query");
      return;
    }

    if (keywordData.length === 0) {
      toast.error("Please load data first");
      return;
    }

    setIsSearching(true);
    try {
      // Get product info from KYP data
      let productInfo = "";
      if (kypData && kypData.product_notes) {
        productInfo = kypData.product_notes;
      }

      // Prepare the keywords list (limit to 1000 to avoid too large requests)
      const keywordsList = keywordData.slice(0, 1000).map(item => item.keyword);
      
      const response = await fetch('/api/keyword_classifier/ai_search_keywords', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          keywords: keywordsList,
          product_info: productInfo,
          user_query: aiSearchQuery
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      
      // Update filter fields
      setIncludeKeywords(result.include_or);
      setIncludeAndKeywords(result.include_and);
      setExcludeKeywords(result.exclude);
      
      toast.success("AI search filters applied");
      
      // Log the event
      logEvent(localStorage.getItem('user_email'), 'keyword_spends', 'ai_search', {
        country: selectedCountry || 'all',
        line: selectedLine || 'all',
        search_query: aiSearchQuery
      });
    } catch (error) {
      console.error('Error in AI search:', error);
      toast.error(`AI search failed: ${error.message}`);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div style={{ padding: '20px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <style>
        {`
          .flash-success {
            background-color: rgba(76, 175, 80, 0.5) !important;
          }
          .flash-error {
            background-color: rgba(244, 67, 54, 0.5) !important;
          }
        `}
      </style>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Keyword Spends</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <DateRangePicker 
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
          />
          <QuickFilter onFilterChange={handleQuickFilterChange} />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleExportCSV}
          >
            Export CSV
          </Button>
          <Divider orientation="vertical" flexItem sx={{ mx: 0.25 }} />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => window.open('/tasks', '_blank')}
          >
            TASKS QUEUE
          </Button>
        </Box>
      </Box>

      {/* Main content container with minimal gap */}
      <Box display="flex" gap={2} height="calc(100vh - 80px)">
        {/* Left side - All inputs */}
        <Box display="flex" flexDirection="column" gap={1} width="250px">
          <FormControl variant="outlined" size="small">
            <InputLabel>Country</InputLabel>
            <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              label="Country"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {countries.map(country => (
                <MenuItem key={country} value={country}>{country}</MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <FormControl variant="outlined" size="small">
          <Autocomplete
          value={selectedLine}
          onChange={(event, newValue) => {
          setSelectedLine(newValue || '');
          handleLineChange({ target: { value: newValue || '' } });
          }}
          options={lines}
          renderInput={(params) => (
          <TextField {...params}
            label="Line" variant="outlined" size="small"
          />
          )}
          freeSolo
          autoSelect
          filterOptions={(options, params) => {
          const filtered = options.filter(option =>
          option.toLowerCase().includes(params.inputValue.toLowerCase()) );
          return filtered; }}
          sx={{ '& .MuiOutlinedInput-root': { padding: '3px' } }}
          />
          </FormControl>

          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleLoadData}
            sx={{ mt: 1 }}
          >
            Load
          </Button>

          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <Box display="flex" gap={1}>
              <TextField
                label="AI Search"
                placeholder="e.g. bright cushion"
                value={aiSearchQuery}
                onChange={(e) => setAiSearchQuery(e.target.value)}
                size="small"
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAiSearch}
                disabled={isSearching || keywordData.length === 0}
                sx={{ minWidth: '80px' }}
              >
                {isSearching ? "..." : "Search"}
              </Button>
            </Box>
            <TextField
              label="Include keywords (OR)"
              placeholder="comma separated"
              value={includeKeywords}
              onChange={(e) => setIncludeKeywords(e.target.value)}
              size="small"
            />
            <TextField
              label="Include keywords (AND)"
              placeholder="comma separated"
              value={includeAndKeywords}
              onChange={(e) => setIncludeAndKeywords(e.target.value)}
              size="small"
            />
            <TextField
              label="Exclude keywords"
              placeholder="comma separated"
              value={excludeKeywords}
              onChange={(e) => setExcludeKeywords(e.target.value)}
              size="small"
            />
          </Box>

          <Box display="flex" flexDirection="column" gap={1} mt={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <AutoAwesomeIcon sx={{ color: 'primary.main' }} />
              <Typography variant="subtitle1" color="primary" fontWeight="bold">
                AI Classify
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInitialClassification}
              disabled={isClassifying || !selectedCountry || !selectedLine}
            >
              Initial Classification
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClassifySelection}
              disabled={isClassifying || !selectedCountry || !selectedLine || !hasLabels}
            >
              Classify Selection
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClassifyPendingKeywords}
              disabled={isClassifying || !selectedCountry || !selectedLine || !hasLabels}
            >
              Classify Pending KWs
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClassifyLine}
              disabled={isClassifying || !selectedCountry || !selectedLine || !hasLabels}
            >
              Reclassify Entire Line
            </Button>

            <Box display="flex" alignItems="center" gap={1} mt={2}>
              <Typography variant="subtitle1" fontWeight="bold">
                KYP
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDrawerOpen(true)}
              disabled={!selectedCountry || !selectedLine}
            >
              Edit KYP
            </Button>

            <Accordion sx={{ mt: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="instructions-content"
                id="instructions-header"
              >
                <Typography>Instructions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" component="div">
                  <Box component="ol" sx={{ pl: 2, mt: 0, mb: 0 }}>
                    <li>
                      <strong>Read through the Guidelines:</strong> These are what the AI also uses as its base when classifying.
                    </li>
                    <li>
                      <strong>Define KYP:</strong> Start by defining the KYP using the Edit KYP Button. This has an auto-generate button to create a basic description based on the Title and Bullet Points. However, spend a few minutes to refine it and add anything that maybe missed. This will help getting the keyword classification more accurate.
                    </li>
                    <li>
                      <strong>Run Initial Classification:</strong> By clicking this button, you will let AI classify the first 50 keywords. This is a sample classification that you can check to see if it has got most right. It is important to go through this and correct anything that is wrong or those which have a low confidence score or give a thumbs up to any that are correct and have low logprob. You can give a thumbs up to any that you agree with.
                    </li>
                    <li>
                      <strong>Classify Pending KWs:</strong> This will then AI classify all the pending kws
                    </li>
                    <li>
                      <strong>(Optional) Reclassify Line:</strong> Use this only if you have made significant changes to the KYP or feel that there were many wrong labels, which you have corrected. Note, when you reclassify any words that have been manually defined by you, they get skipped.
                    </li>
                    <li>
                      <strong>Note:</strong> AI-classification is purposely skipped in these instances:
                      <ol type="1">
                        <li>User classified: If any keywords have been previously user-classified (by manually selecting a label or using the thumbs up), they are never reclassified by AI. The label can always be changed manually</li>
                        <li>ASINs: If a keyword starts with b0, we don't use AI - we just classify it as 'asin' directly</li>
                      </ol>
                    </li>
                  </Box>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ mt: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="classification-notes-content"
                id="classification-notes-header"
              >
                <Typography>Classification Guidelines</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" component="div">
                  <Box component="ul" sx={{ pl: 2, mt: 0, mb: 0 }}>
                    <li>Choose the most relevant category from: generic, highly_relevant, competitor, brand, asin, seasonal, size, color, size_color, pack_pcs, or irrelevant</li>
                    <li>If no other category fits, mark as irrelevant</li>
                    <li>Mark as irrelevant if:
                      <ul>
                        <li>It's a different product altogether</li>
                        <li>The search is too generic (e.g., just "accessories" or "decor")</li>
                        <li>The search mentions a blacklisted word</li>
                        <li>The design style differs (e.g., solid vs pattern)</li>
                      </ul>
                    </li>
                    <li>Mark as highly_relevant if the search term matches the product very closely and its distinctive design features</li>
                    <li>For color/design terms:
                      <ul>
                        <li>Classify as color if it mentions any color, even if not exact match</li>
                        <li>If both size and color are mentioned, use size_color category</li>
                      </ul>
                    </li>
                    <li>ASINs are Amazon product IDs starting with "b0" (e.g., "b0c34xr789")</li>
                    <li>Brand terms include: encasa, encasa xo, encasa homes, the white cradle</li>
                  </Box>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        {/* Right side - Grid */}
        {error ? (
          <Typography color="error" variant="h6">Error: {error}</Typography>
        ) : (
          <div style={{ flex: 1 }} className="ag-theme-alpine">
            {loading ? (
              <Typography>Loading...</Typography>
            ) : (
              <AgGridReact
                ref={gridRef}
                rowData={filteredKeywordData}
                columnDefs={enhancedColumns}
                {...AGGrid_Options}
                sideBar={AGGrid_Sidebar}
                quickFilterText={quickFilterText}
                onCellValueChanged={handleCellValueChanged}
                rowSelection="multiple"
              />
            )}
          </div>
        )}
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '80%',
            maxWidth: '1000px',
            boxSizing: 'border-box',
          },
        }}
      >
        {selectedCountry && selectedLine && (
          <ProductLineInfo
            initialCountry={selectedCountry}
            initialLine={selectedLine}
            onClose={() => {
              setDrawerOpen(false);
              fetchKypData(); // Refresh KYP data when drawer closes
            }}
            onSave={() => {
              fetchKypData(); // Refresh KYP data when save is successful
            }}
          />
        )}
      </Drawer>
    </div>
  );
};

export default KeywordSpends; 