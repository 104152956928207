import React, { useEffect, useState, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Box, Typography } from '@mui/material';
import AGGrid_Options from '../../components/global/AGGrid_Options.jsx';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar.jsx';

const Returns = () => {
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);

  useEffect(() => {
    fetch('/api/orders/returns_reasons')
      .then(response => response.json())
      .then(data => {
        const uniqueData = Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
        setRowData(uniqueData);
      });
  }, []);

  const columns = [
    { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 80 },
    { headerName: "Line", field: "Line", sortable: true, filter: true, width: 80 },
    { headerName: "Color", field: "Color_Design", sortable: true, filter: true, width: 150 },
    { headerName: "Size", field: "Size", sortable: true, filter: true, width: 120 },
    { headerName: "Pcs Pack", field: "pcs_pack", sortable: true, filter: true, width: 100 },
    { headerName: "Return Date", field: "return_date", sortable: true, filter: true, width: 130, valueFormatter: params => {
      const date = new Date(params.value);
      return isNaN(date.getTime()) ? '' : date.toISOString().split('T')[0];
    } },
    { headerName: "Reasons", field: "reason", sortable: true, filter: true, width: 180 },
    { headerName: "Customer Comments", field: "customer_comments", sortable: true, filter: true, width: 400 }
  ];

  const handleExportCSV = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Returns Reasons</Typography>
        <Box>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columns}
          {...AGGrid_Options}
          sideBar={AGGrid_Sidebar}
        />
      </div>
    </div>
  );
};

export default Returns;
