import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Box, useTheme, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Button, Grid, InputAdornment, IconButton, CircularProgress } from "@mui/material";
import { tokens } from "../../theme";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AGGrid_Options from '../../components/global/AGGrid_Options.jsx';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar.jsx';
import DateRangePicker from '../../components/global/DateRangePicker.jsx';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { format, parseISO } from 'date-fns';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

const Analytics = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const gridRef = useRef(null);
  
  // COLORS for charts
  const CHART_COLORS = [
    colors.greenAccent[500],
    colors.blueAccent[500],
    colors.redAccent[500],
    colors.greenAccent[300],
    colors.blueAccent[300],
    colors.redAccent[300],
    colors.greenAccent[100],
    colors.blueAccent[100],
    colors.redAccent[100],
    colors.grey[500],
  ];
  
  // State for data and filters
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState("");
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch data from the API
  const fetchActivityData = useCallback(async () => {
    try {
      setLoading(true);
      
      // Calculate the number of days between startDate and current date for the API query
      const daysDiff = dayjs().diff(dayjs(startDate), 'day');
      
      // Build the API URL with query parameters
      let apiUrl = `/api/analytics/user_activity?days=${daysDiff}`;
      
      // Add additional filter parameters if they exist
      if (selectedUser) {
        apiUrl += `&user_id=${encodeURIComponent(selectedUser)}`;
      }
      
      console.log(`Fetching data from ${apiUrl}`);
      
      // Fetch data from the API endpoint
      const response = await fetch(apiUrl);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log(`Received ${data.length} records from API`);
      
      if (!data || data.length === 0) {
        console.warn("No data returned from API");
        setActivityData([]);
        setLoading(false);
        return;
      }
      
      // Transform the data to add an id field and parse dates
      const transformedData = data.map((item, index) => {
        // Parse the timestamp from the format in the API response
        let eventTimestamp;
        try {
          // Try to parse the timestamp as ISO format first
          eventTimestamp = new Date(item.event_timestamp);
          if (isNaN(eventTimestamp.getTime())) {
            // If that fails, try to parse it as the format in the CSV
            const parts = item.event_timestamp.split(' ');
            const datePart = parts[0];
            const timePart = parts[1];
            eventTimestamp = new Date(`${datePart}T${timePart}`);
          }
        } catch (e) {
          console.error("Error parsing date:", e);
          eventTimestamp = new Date(); // Fallback to current date
        }
        
        // Parse the additional_data JSON if it exists
        let additionalData = {};
        if (item.additional_data) {
          try {
            additionalData = typeof item.additional_data === 'string' ? 
              JSON.parse(item.additional_data) : item.additional_data;
          } catch (e) {
            console.error("Error parsing additional_data:", e);
            additionalData = item.additional_data;
          }
        }
        
        return {
          ...item,
          id: index,
          event_timestamp: eventTimestamp,
          logged_at: new Date(item.logged_at),
          additional_data: additionalData
        };
      });
      
      console.log("Setting activity data:", transformedData.length);
      setActivityData(transformedData);
    } catch (error) {
      console.error("Error fetching activity data:", error);
      setActivityData([]);
    } finally {
      setLoading(false);
    }
  }, [startDate, selectedUser]);

  useEffect(() => {
    fetchActivityData();
  }, [fetchActivityData]);

  // Refetch data when filters change
  const handleFilterChange = useCallback(() => {
    fetchActivityData();
  }, [fetchActivityData]);

  // Handler for date changes
  const handleDateChange = useCallback((newStartDate, newEndDate) => {
    console.log(`Date range changed: ${newStartDate} to ${newEndDate}`);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    // Add a slight delay to ensure state is updated before fetching
    setTimeout(() => {
      fetchActivityData();
    }, 0);
  }, [fetchActivityData]);

  // Get unique users for the dropdown
  const uniqueUsers = useMemo(() => {
    if (!activityData) return [];
    
    const users = [...new Set(activityData.map(item => item.user_id))];
    return users.sort();
  }, [activityData]);

  // Filter data based on search term, user, and date range
  const filteredData = useMemo(() => {
    if (!activityData || activityData.length === 0) {
      console.log("No activity data to filter");
      return [];
    }
    
    console.log(`Filtering ${activityData.length} records with search="${searchTerm}", user="${selectedUser}", startDate=${startDate}, endDate=${endDate}`);
    
    const filtered = activityData.filter(item => {
      // Filter by search term
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = 
        !searchTerm || 
        item.user_id.toLowerCase().includes(searchLower) ||
        item.tool_used.toLowerCase().includes(searchLower) ||
        item.event_type.toLowerCase().includes(searchLower) ||
        (item.additional_data && 
          typeof item.additional_data === 'object' && 
          JSON.stringify(item.additional_data).toLowerCase().includes(searchLower));
      
      // Filter by user
      const matchesUser = !selectedUser || item.user_id === selectedUser;
      
      // Filter by date range
      const itemDate = new Date(item.event_timestamp);
      const startDateObj = startDate ? new Date(startDate) : null;
      const endDateObj = endDate ? new Date(endDate) : null;
      
      // Set time to start of day for startDate
      if (startDateObj) {
        startDateObj.setHours(0, 0, 0, 0);
      }
      
      // Set time to end of day for endDate
      if (endDateObj) {
        endDateObj.setHours(23, 59, 59, 999);
      }
      
      const matchesDateRange = 
        (!startDateObj || itemDate >= startDateObj) && 
        (!endDateObj || itemDate <= endDateObj);
      
      return matchesSearch && matchesUser && matchesDateRange;
    });
    
    console.log(`Filtered to ${filtered.length} records`);
    return filtered;
  }, [activityData, searchTerm, selectedUser, startDate, endDate]);

  // Prepare data for the page visits chart
  const pageVisitsData = useMemo(() => {
    const pageVisits = {};
    
    filteredData.forEach(item => {
      if (item.tool_used === "sidebar" && item.event_type === "menu_click" && item.additional_data && item.additional_data.item) {
        const pageName = item.additional_data.item;
        pageVisits[pageName] = (pageVisits[pageName] || 0) + 1;
      }
    });
    
    // Convert to array and sort by count
    return Object.entries(pageVisits)
      .map(([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10); // Top 10 pages
  }, [filteredData]);

  // Prepare data for the user activity chart
  const userActivityData = useMemo(() => {
    const userActivity = {};
    
    filteredData.forEach(item => {
      userActivity[item.user_id] = (userActivity[item.user_id] || 0) + 1;
    });
    
    // Convert to array and sort by count
    return Object.entries(userActivity)
      .map(([name, count]) => ({ name, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 5); // Top 5 users
  }, [filteredData]);

  // Define AG Grid column definitions
  const columnDefs = useMemo(() => [
    { 
      field: 'user_id', 
      headerName: 'User', 
      filter: 'agTextColumnFilter',
      sortable: true,
      minWidth: 220
    },
    { 
      field: 'tool_used', 
      headerName: 'Tool', 
      filter: 'agTextColumnFilter',
      sortable: true,
      minWidth: 120
    },
    { 
      field: 'event_type', 
      headerName: 'Event Type', 
      filter: 'agTextColumnFilter',
      sortable: true,
      minWidth: 150
    },
    { 
      field: 'event_timestamp', 
      headerName: 'Timestamp', 
      filter: 'agDateColumnFilter',
      sortable: true,
      minWidth: 180,
      valueFormatter: (params) => {
        return params.value ? format(new Date(params.value), 'yyyy-MM-dd HH:mm:ss') : '';
      }
    },
    { 
      field: 'additional_data', 
      headerName: 'Details', 
      filter: 'agTextColumnFilter',
      sortable: true,
      minWidth: 250,
      valueFormatter: (params) => {
        if (!params.value) return '';
        try {
          const data = typeof params.value === 'string' ? JSON.parse(params.value) : params.value;
          return Object.entries(data)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');
        } catch (e) {
          return String(params.value);
        }
      }
    }
  ], []);

  // AG Grid default column definition
  const defaultColDef = useMemo(() => ({
    flex: 1,
    resizable: true,
    filter: true,
    sortable: true,
  }), []);

  // Handle export data
  const handleExportData = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.exportDataAsCsv({
        fileName: `user_activity_${format(new Date(), 'yyyy-MM-dd')}.csv`,
      });
    }
  }, []);

  // Handle clear filters
  const handleClearFilters = useCallback(() => {
    setSearchTerm('');
    setSelectedUser('');
    const date = new Date();
    date.setDate(date.getDate() - 30);
    setStartDate(date);
    setEndDate(new Date());
    
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setFilterModel(null);
    }
    
    // Trigger data refetch with the new filters
    setTimeout(() => {
      fetchActivityData();
    }, 0);
  }, [fetchActivityData]);

  // Render the filters section
  const renderFilters = () => (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", md: "center" }}
      p={2}
      gap={2}
      sx={{ backgroundColor: colors.primary[400] }}
    >
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={2} alignItems={{ xs: "flex-start", md: "center" }}>
        {/* User Filter */}
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            labelId="user-select-label"
            id="user-select"
            value={selectedUser}
            label="User"
            onChange={(e) => {
              setSelectedUser(e.target.value);
              handleFilterChange();
            }}
          >
            <MenuItem value="">All Users</MenuItem>
            {uniqueUsers.map((user) => (
              <MenuItem key={user} value={user}>
                {user}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Date Range Pickers */}
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
        />

        {/* Search Field */}
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setSearchTerm("")}
                  edge="end"
                  size="small"
                  sx={{ visibility: searchTerm ? 'visible' : 'hidden' }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Usage Analytics</Typography>
        <Box>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleExportData}
            startIcon={<DownloadIcon />}
            style={{ marginRight: '10px' }}
          >
            Export
          </Button>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters}
            startIcon={<FilterListOffIcon />}
          >
            Clear Filters
          </Button>
        </Box>
      </Box>
      
      {/* Filters and Controls */}
      {renderFilters()}
      
      {/* Loading Indicator */}
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      )}
      
      {/* Charts */}
      {!loading && (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {/* Page Visits Chart */}
            <Grid item xs={12} md={6}>
              <Box
                backgroundColor={colors.primary[400]}
                p="20px"
                borderRadius="4px"
                height="400px"
              >
                <Typography variant="h5" fontWeight="600" sx={{ mb: 2 }}>
                  Top Page Visits
                </Typography>
                <ResponsiveContainer width="100%" height="90%">
                  <BarChart
                    data={pageVisitsData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="name" 
                      angle={-45} 
                      textAnchor="end" 
                      height={70}
                      tick={{ fontSize: 12 }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" name="Visits" fill={colors.greenAccent[500]} />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Grid>
            
            {/* User Activity Bar Chart */}
            <Grid item xs={12} md={6}>
              <Box
                backgroundColor={colors.primary[400]}
                p="20px"
                borderRadius="4px"
                height="400px"
              >
                <Typography variant="h5" fontWeight="600" sx={{ mb: 2 }}>
                  Top User Activity
                </Typography>
                <ResponsiveContainer width="100%" height="90%">
                  <BarChart
                    data={userActivityData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="name" 
                      angle={-45} 
                      textAnchor="end" 
                      height={70}
                      tick={{ fontSize: 12 }}
                      tickFormatter={(value) => value.split('@')[0]} 
                    />
                    <YAxis />
                    <Tooltip formatter={(value, name, props) => [value, props.payload.name]} />
                    <Legend />
                    <Bar dataKey="count" name="Activities" fill={colors.blueAccent[500]} />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Grid>
          </Grid>
          
          {/* Activity Log Table */}
          <Box mt={4}>
            <Typography variant="h5" fontWeight="600" sx={{ mb: 2 }}>
              Activity Log
            </Typography>
            <Box
              className="ag-theme-alpine"
              sx={{
                height: "60vh",
                width: "100%",
                "& .ag-header-cell-label": {
                  fontWeight: "bold"
                }
              }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={filteredData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection="multiple"
                pagination={true}
                paginationPageSize={15}
                {...AGGrid_Options}
                sideBar={AGGrid_Sidebar}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Analytics; 