import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard/dashboard";
import { CatalogLinesList, CatalogLinesForm } from "./scenes/catalog_lines/catalog_lines";
import { CatalogSizeVariationsList, CatalogSizeVariationsForm } from "./scenes/catalog_size_variations/catalog_size_variations";
import CatalogTools from "./scenes/catalog_tools/catalog_tools";
import Variations from "./scenes/variations/variations";
import { SkuFulfilmentFee, LineFulfilmentFee } from "./scenes/fulfilment_fee/fulfilment_fee";
import Logs from "./scenes/logs/logs";
import Returns from "./scenes/returns/returns";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Login from './components/auth/Login';
import PrivateRoute from './components/auth/PrivateRoute';
import { AuthProvider, useAuth } from './components/auth/AuthContext';
import SBDashboard from "./scenes/sb_dashboard/sb_dashboard";
import COSP from "./scenes/cosp/cosp";
import LineMonthly from "./scenes/line_monthly/line_monthly";
import DAM from "./scenes/dam/dam";
import CacheClearingPanel from "./scenes/cache_clearing_panel/cache_clearing_panel";
import KeywordExplorer from "./scenes/keyword_explorer/keyword_explorer";
import LineDetails from "./scenes/line_details/line_details";
import SP from "./scenes/sp/sp";
import POChecks from "./scenes/po_checks/po_checks";
import PODetails from "./scenes/po_checks/po_details";
import CampaignChanges from "./scenes/campaign_changes/campaign_changes";
import ReturnsAnalysis from "./scenes/returns/returns_analysis";
import ProductLines from "./scenes/product_lines/product_lines";
import CompetitorSearch from "./scenes/competitor_search/competitor_search";
import IndexChecker from "./scenes/index_checker/index_checker";
import Repricing from "./scenes/repricing/repricing";
import DAMFolder from "./scenes/dam_folder/dam_folder";
import MonthlyReport from "./scenes/monthly_report/monthly_report";
import Pricing_Fee_Preview from "./scenes/fulfilment_fee/pricing_fee_preview";
import KeywordHistoric from "./scenes/keyword_historic/keyword_historic";
import ScratchpadFeed from "./scenes/scratchpad_feed/scratchpad_feed";
import Campaigns from "./scenes/campaigns/campaigns";
import SEOModule from "./scenes/seo_module/seo_module";
import ETLBusinessReport from "./scenes/etl_business_report/etl_business_report";
import LineControlPanel from "./scenes/product_lines/line_control_panel";
import KeywordSpends from "./scenes/keyword_spends/keyword_spends";
import Tasks from "./scenes/tasks/tasks";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SnackbarProvider } from 'notistack';
import AsinSearch from "./scenes/asin_search/asin_search";
import ProductLineInfo from "./scenes/product_line_info/product_line_info";
import Analytics from "./scenes/analytics/analytics";

function AppContent() {
  const [theme, colorMode] = useMode();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const resizeObserverError = error => {
      if (error.message.includes('ResizeObserver loop')) {
        const resizeObserverError = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverError) {
          resizeObserverError.style.display = 'none'
        }
      }
    }

    window.addEventListener('error', resizeObserverError)

    return () => window.removeEventListener('error', resizeObserverError)
  }, [])

  return (
    <SnackbarProvider maxSnack={3}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {isAuthenticated() && <Sidebar />}
            <main className="content">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/catalog_lines/list" element={<CatalogLinesList />} />
                  <Route path="/catalog_lines/form" element={<CatalogLinesForm />} />
                  <Route path="/catalog_size_variations/list" element={<CatalogSizeVariationsList />} />
                  <Route path="/catalog_size_variations/form" element={<CatalogSizeVariationsForm />} />
                  <Route path="/catalog_tools/list" element={<CatalogTools />} />
                  <Route path="/variations/list" element={<Variations />} />
                  <Route path="/fulfilment_fee/sku" element={<SkuFulfilmentFee />} />
                  <Route path="/fulfilment_fee/line" element={<LineFulfilmentFee />} />
                  <Route path="/fulfilment_fee/pricing_fee_preview" element={<Pricing_Fee_Preview />} />
                  <Route path="/logs/list" element={<Logs />} />
                  <Route path="/Returns" element={<Returns />} />
                  <Route path="/sb_dashboard" element={<SBDashboard />} />
                  <Route path="/cosp" element={<COSP />} />
                  <Route path="/line_monthly" element={<LineMonthly />} />
                  <Route path="/dam" element={<DAM />} />
                  <Route path="/cache-clearing" element={<CacheClearingPanel />} />
                  <Route path="/keyword_explorer" element={<KeywordExplorer />} />
                  <Route path="/line_details" element={<LineDetails />} />
                  <Route path="/sp" element={<SP />} />
                  <Route path="/po_checks" element={<POChecks />} />
                  <Route path="/po_details/:shipment_id" element={<PODetails />} />
                  <Route path="/campaign_changes" element={<CampaignChanges />} />
                  <Route path="/Returns_Analysis" element={<ReturnsAnalysis />} />
                  <Route path="/product_lines" element={<ProductLines />} />
                  <Route path="/competitor_search" element={<CompetitorSearch />} />
                  <Route path="/index_checker" element={<IndexChecker />} />
                  <Route path="/repricing" element={<Repricing />} />
                  <Route path="/dam_folder" element={<DAMFolder />} />
                  <Route path="/monthly-report" element={<MonthlyReport />} />
                  <Route path="/keyword_historic" element={<KeywordHistoric />} />
                  <Route path="/scratchpad-feed" element={<ScratchpadFeed />} />
                  <Route path="/campaigns" element={<Campaigns />} />
                  <Route path="/seo_module" element={<SEOModule />} />
                  <Route path="/etl_business_report" element={<ETLBusinessReport />} />
                  <Route path="/line-control-panel" element={<LineControlPanel />} />
                  <Route path="/tasks" element={<Tasks />} />
                  <Route path="/keyword-spends" element={<KeywordSpends />} />
                  <Route path="/asin_search" element={<AsinSearch />} />
                  <Route path="/product-line-info" element={<ProductLineInfo />} />
                  <Route path="/analytics" element={<Analytics />} />
                </Route>
              </Routes>
            </main>
            <ToastContainer position="bottom-right" />
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </SnackbarProvider>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
