import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { logEvent } from '../../utils/logEvent';
import './Login.css'; // We'll create this CSS file for styling

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, googleSignIn, isAuthenticated } = useAuth();

  // Get Google Client ID from environment variable or use the hardcoded one as fallback
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || 
    '923092386478-an9q92tt62tcle3494lr7hplns26jlbp.apps.googleusercontent.com';
  
  // Get the API URL for logging
  const API_URL = process.env.REACT_APP_API_URL || 
                 (window.location.hostname === 'localhost' 
                  ? 'http://localhost:5000' 
                  : window.location.origin);
  
  console.log('Using Google Client ID:', GOOGLE_CLIENT_ID.substring(0, 10) + '...');
  console.log('Using API URL:', API_URL);

  // Check if already authenticated
  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  // Initialize Google Sign-In when component mounts
  useEffect(() => {
    const loadGoogleScript = () => {
      // Load the Google Sign-In API script
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID, // Now using the variable
          callback: handleGoogleResponse,
          auto_select: false,
        });
        
        window.google.accounts.id.renderButton(
          document.getElementById('google-signin-button'),
          { theme: 'outline', size: 'large', width: 250, text: 'signin_with' }
        );
      };
    };
    
    loadGoogleScript();
  }, [GOOGLE_CLIENT_ID]); // Add GOOGLE_CLIENT_ID as a dependency

  // Handle Google Sign-In response
  const handleGoogleResponse = async (response) => {
    setLoading(true);
    console.log('Google Sign-In response received:', { credential: response.credential ? 'present' : 'missing' });
    
    try {
      // Log the credential we're sending to the backend
      console.log('Sending credential to backend');
      console.log('Auth endpoint:', `${API_URL}/api/auth/google`);
      
      // First try to check if the API is accessible
      try {
        const testResponse = await fetch(`${API_URL}/api/test/env`);
        if (testResponse.ok) {
          const testData = await testResponse.json();
          console.log('API test response:', testData);
        } else {
          console.error('API test failed:', testResponse.status);
        }
      } catch (testError) {
        console.error('API test error:', testError);
      }
      
      const result = await googleSignIn(response.credential);
      console.log('Backend response:', result);
      
      if (result.success) {
        console.log('Google authentication successful, redirecting to dashboard');
        
        // Log the successful login event to analytics using the utility function
        await logEvent(result.user?.email || 'unknown', 'login', 'google_auth', { login_method: 'google', name: result.user?.name, success: true });
        
        navigate('/dashboard');
      } else {
        console.error('Authentication failed:', result.message);
        setError(result.message || 'Authentication failed');
      }
    } catch (err) {
      console.error('Authentication error details:', err);
      setError('Authentication error. Please try again: ' + (err.message || 'Unknown error'));
    } finally {
      setLoading(false);
    }
  };

  // Handle traditional login
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      const result = await login(username, password);
      
      if (result.success) {
        // Log the successful password login event to analytics using the utility function
        await logEvent(
          result.user?.email || username || 'unknown',
          'login',
          'password_auth',
          {
            login_method: 'password',
            name: result.user?.name,
            success: true
          }
        );
        
        navigate('/dashboard');
      } else {
        setError(result.message || 'Login failed');
      }
    } catch (err) {
      setError('Login error. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login to HQ</h2>
        
        {error && <div className="error-message">{error}</div>}
        
        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={loading}
              className="login-input"
            />
          </div>
          
          <div className="form-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              className="login-input"
            />
          </div>
          
          <button type="submit" disabled={loading} className="login-button">
            {loading ? 'Loading...' : 'Login with Password'}
          </button>
        </form>
        
        <div className="divider">
          <span>OR</span>
        </div>
        
        {/* Google Sign-In Button */}
        <div id="google-signin-button" className="google-button-container"></div>
      </div>
    </div>
  );
};

export default Login;