import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button, FormControlLabel, Switch } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import QuickFilter from '../../components/global/QuickFilter';
import AGGrid_Options from '../../components/global/AGGrid_Options';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar';
import { columns } from './lineMonthlyColumns';

const LineMonthly = () => {
  const theme = useTheme();
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const [appliedQuickFilter, setAppliedQuickFilter] = useState('');
  const [showRatios, setShowRatios] = useState(true);
  const gridRef = useRef(null);
  const ratioGridRef = useRef(null);
  
  // State to store calculated ratio data
  const [ratioData, setRatioData] = useState([]);
  
  // State for resizable grid heights
  const [mainGridHeight, setMainGridHeight] = useState(67); // Initial percentage
  const [ratioGridHeight, setRatioGridHeight] = useState(33); // Initial percentage
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    document.title = "Line Monthly | Encasa HQ";
    console.log('Initiating Line Monthly data fetch');
    fetch('/api/line/get_combined_line_data')
      .then(response => {
        console.log(`Response status: ${response.status}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          const computedData = data.map(row => {
            const negL12 = Array.from({ length: 12 }, (_, i) => row[`month_${i + 1}`] || 0)
                          .filter(value => value < 0).length;
            const posL12 = Array.from({ length: 12 }, (_, i) => row[`month_${i + 1}`] || 0)
                          .filter(value => value > 0).length;
            return { ...row,  negL12, posL12 };
          });
          setRowData(computedData);
        } else {
          console.error('Unexpected data structure:', typeof data);
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching Line Monthly data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, []);
  
  // Update ratio data based on main grid's model
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const updateRatioData = () => {
        try {
          console.log("Starting ratio calculations...");
          // Get raw metric data from grid
          const metrics = {};
          
          // First build a dictionary of all metrics
          gridRef.current.api.forEachNodeAfterFilter(node => {
            if (node.data && node.data.metric) {
              // Initialize metric if not present
              if (!metrics[node.data.metric]) {
                metrics[node.data.metric] = {
                  total: 0,
                  months: Array(12).fill(0),
                  days: {}, // For day-wise data (l7, p7, l30, day_2 to day_60)
                  weeks: {} // For weekly data (week_1 to week_12)
                };
              }
              
              // Add total value
              metrics[node.data.metric].total += parseFloat(node.data.total || 0);
              
              // Add monthly values
              for (let i = 1; i <= 12; i++) {
                const value = parseFloat(node.data[`month_${i}`] || 0);
                metrics[node.data.metric].months[i-1] += value;
              }
              
              // Add day-wise values
              // Add l7, p7, l30 values
              ['l7', 'p7', 'l30'].forEach(dayField => {
                if (node.data[dayField] !== undefined) {
                  if (!metrics[node.data.metric].days[dayField]) {
                    metrics[node.data.metric].days[dayField] = 0;
                  }
                  metrics[node.data.metric].days[dayField] += parseFloat(node.data[dayField] || 0);
                }
              });
              
              // Add day_2 to day_60 values
              for (let i = 2; i <= 60; i++) {
                const dayField = `day_${i}`;
                if (node.data[dayField] !== undefined) {
                  if (!metrics[node.data.metric].days[dayField]) {
                    metrics[node.data.metric].days[dayField] = 0;
                  }
                  metrics[node.data.metric].days[dayField] += parseFloat(node.data[dayField] || 0);
                }
              }
              
              // Add weekly values
              for (let i = 1; i <= 12; i++) {
                const weekField = `week_${i}`;
                if (node.data[weekField] !== undefined) {
                  if (!metrics[node.data.metric].weeks[weekField]) {
                    metrics[node.data.metric].weeks[weekField] = 0;
                  }
                  metrics[node.data.metric].weeks[weekField] += parseFloat(node.data[weekField] || 0);
                }
              }
            }
          });
          
          console.log("Collected metrics:", Object.keys(metrics));
          
          // Define ratio calculations using the metrics collected
          const ratioRows = [];
          
          // Simple helper to calculate a ratio
          const calculateRatio = (num, denom, asPercent = true, makeNegative = false) => {
            if (denom === 0) return 0;
            let result = num / denom;
            if (asPercent) result *= 100;
            if (makeNegative) result = -Math.abs(result);
            return result;
          };
          
          // Add Profit % row
          if (metrics['Net Profit'] && metrics['Sales']) {
            const profitRow = { 
              metric: 'Profit %',
              total: calculateRatio(metrics['Net Profit'].total, metrics['Sales'].total)
            };
            
            // Calculate monthly values
            for (let i = 1; i <= 12; i++) {
              const netProfit = metrics['Net Profit'].months[i-1];
              const sales = metrics['Sales'].months[i-1];
              profitRow[`month_${i}`] = calculateRatio(netProfit, sales);
            }
            
            // Calculate day-wise values
            // Add l7, p7, l30 values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Net Profit'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                profitRow[dayField] = calculateRatio(metrics['Net Profit'].days[dayField], metrics['Sales'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Net Profit'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                profitRow[dayField] = calculateRatio(metrics['Net Profit'].days[dayField], metrics['Sales'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Net Profit'].weeks[weekField] !== undefined && metrics['Sales'].weeks[weekField] !== undefined) {
                profitRow[weekField] = calculateRatio(metrics['Net Profit'].weeks[weekField], metrics['Sales'].weeks[weekField]);
              }
            }
            
            ratioRows.push(profitRow);
          }
          
          // Add Return % row
          if (metrics['Returns'] && metrics['Units Sold']) {
            const returnRow = {
              metric: 'Return %',
              total: calculateRatio(metrics['Returns'].total, metrics['Units Sold'].total, true, true)
            };
            
            for (let i = 1; i <= 12; i++) {
              const returns = metrics['Returns'].months[i-1];
              const unitsSold = metrics['Units Sold'].months[i-1];
              returnRow[`month_${i}`] = calculateRatio(returns, unitsSold, true, true);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Returns'].days[dayField] !== undefined && metrics['Units Sold'].days[dayField] !== undefined) {
                returnRow[dayField] = calculateRatio(metrics['Returns'].days[dayField], metrics['Units Sold'].days[dayField], true, true);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Returns'].days[dayField] !== undefined && metrics['Units Sold'].days[dayField] !== undefined) {
                returnRow[dayField] = calculateRatio(metrics['Returns'].days[dayField], metrics['Units Sold'].days[dayField], true, true);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Returns'].weeks[weekField] !== undefined && metrics['Units Sold'].weeks[weekField] !== undefined) {
                returnRow[weekField] = calculateRatio(metrics['Returns'].weeks[weekField], metrics['Units Sold'].weeks[weekField], true, true);
              }
            }
            
            ratioRows.push(returnRow);
          }
          
          // Add TACoS % row
          if (metrics['Advertising Fees'] && metrics['Sales']) {
            const tacosRow = {
              metric: 'TACoS %',
              total: calculateRatio(metrics['Advertising Fees'].total, metrics['Sales'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const adFees = metrics['Advertising Fees'].months[i-1];
              const sales = metrics['Sales'].months[i-1];
              tacosRow[`month_${i}`] = calculateRatio(adFees, sales);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Advertising Fees'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                tacosRow[dayField] = calculateRatio(metrics['Advertising Fees'].days[dayField], metrics['Sales'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Advertising Fees'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                tacosRow[dayField] = calculateRatio(metrics['Advertising Fees'].days[dayField], metrics['Sales'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Advertising Fees'].weeks[weekField] !== undefined && metrics['Sales'].weeks[weekField] !== undefined) {
                tacosRow[weekField] = calculateRatio(metrics['Advertising Fees'].weeks[weekField], metrics['Sales'].weeks[weekField]);
              }
            }
            
            ratioRows.push(tacosRow);
          }
          
          // Add Amazon Fees % row
          if (metrics['Amazon Fees'] && metrics['Sales']) {
            const feesRow = {
              metric: 'Amazon Fees %',
              total: calculateRatio(metrics['Amazon Fees'].total, metrics['Sales'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const fees = metrics['Amazon Fees'].months[i-1];
              const sales = metrics['Sales'].months[i-1];
              feesRow[`month_${i}`] = calculateRatio(fees, sales);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Amazon Fees'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                feesRow[dayField] = calculateRatio(metrics['Amazon Fees'].days[dayField], metrics['Sales'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Amazon Fees'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                feesRow[dayField] = calculateRatio(metrics['Amazon Fees'].days[dayField], metrics['Sales'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Amazon Fees'].weeks[weekField] !== undefined && metrics['Sales'].weeks[weekField] !== undefined) {
                feesRow[weekField] = calculateRatio(metrics['Amazon Fees'].weeks[weekField], metrics['Sales'].weeks[weekField]);
              }
            }
            
            ratioRows.push(feesRow);
          }
          
          // Add COGS % row
          if (metrics['Cost of Goods'] && metrics['Sales']) {
            const cogsRow = {
              metric: 'COGS %',
              total: calculateRatio(metrics['Cost of Goods'].total, metrics['Sales'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const cogs = metrics['Cost of Goods'].months[i-1];
              const sales = metrics['Sales'].months[i-1];
              cogsRow[`month_${i}`] = calculateRatio(cogs, sales);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Cost of Goods'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                cogsRow[dayField] = calculateRatio(metrics['Cost of Goods'].days[dayField], metrics['Sales'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Cost of Goods'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                cogsRow[dayField] = calculateRatio(metrics['Cost of Goods'].days[dayField], metrics['Sales'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Cost of Goods'].weeks[weekField] !== undefined && metrics['Sales'].weeks[weekField] !== undefined) {
                cogsRow[weekField] = calculateRatio(metrics['Cost of Goods'].weeks[weekField], metrics['Sales'].weeks[weekField]);
              }
            }
            
            ratioRows.push(cogsRow);
          }
          
          // Add Profit/Pc row
          if (metrics['Net Profit'] && metrics['Units Sold']) {
            const profitPcRow = {
              metric: 'Profit/Pc',
              total: calculateRatio(metrics['Net Profit'].total, metrics['Units Sold'].total, false)
            };
            
            for (let i = 1; i <= 12; i++) {
              const netProfit = metrics['Net Profit'].months[i-1];
              const unitsSold = metrics['Units Sold'].months[i-1];
              profitPcRow[`month_${i}`] = calculateRatio(netProfit, unitsSold, false);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Net Profit'].days[dayField] !== undefined && metrics['Units Sold'].days[dayField] !== undefined) {
                profitPcRow[dayField] = calculateRatio(metrics['Net Profit'].days[dayField], metrics['Units Sold'].days[dayField], false);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Net Profit'].days[dayField] !== undefined && metrics['Units Sold'].days[dayField] !== undefined) {
                profitPcRow[dayField] = calculateRatio(metrics['Net Profit'].days[dayField], metrics['Units Sold'].days[dayField], false);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Net Profit'].weeks[weekField] !== undefined && metrics['Units Sold'].weeks[weekField] !== undefined) {
                profitPcRow[weekField] = calculateRatio(metrics['Net Profit'].weeks[weekField], metrics['Units Sold'].weeks[weekField], false);
              }
            }
            
            ratioRows.push(profitPcRow);
          }
          
          // FBA Commission %
          if (metrics['> FBA Commission'] && metrics['Sales']) {
            const fbaCommRow = {
              metric: '> FBA Commision %',
              total: calculateRatio(metrics['> FBA Commission'].total, metrics['Sales'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const fbaComm = metrics['> FBA Commission'].months[i-1];
              const sales = metrics['Sales'].months[i-1];
              fbaCommRow[`month_${i}`] = calculateRatio(fbaComm, sales);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['> FBA Commission'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                fbaCommRow[dayField] = calculateRatio(metrics['> FBA Commission'].days[dayField], metrics['Sales'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['> FBA Commission'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                fbaCommRow[dayField] = calculateRatio(metrics['> FBA Commission'].days[dayField], metrics['Sales'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['> FBA Commission'].weeks[weekField] !== undefined && metrics['Sales'].weeks[weekField] !== undefined) {
                fbaCommRow[weekField] = calculateRatio(metrics['> FBA Commission'].weeks[weekField], metrics['Sales'].weeks[weekField]);
              }
            }
            
            ratioRows.push(fbaCommRow);
          }
          
          // FBA FulfillmentFee %
          if (metrics['> FBA FulfillmentFee'] && metrics['Sales']) {
            const fbaFulfillRow = {
              metric: '> FBA FulfimentFees %',
              total: calculateRatio(metrics['> FBA FulfillmentFee'].total, metrics['Sales'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const fbaFulfill = metrics['> FBA FulfillmentFee'].months[i-1];
              const sales = metrics['Sales'].months[i-1];
              fbaFulfillRow[`month_${i}`] = calculateRatio(fbaFulfill, sales);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['> FBA FulfillmentFee'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                fbaFulfillRow[dayField] = calculateRatio(metrics['> FBA FulfillmentFee'].days[dayField], metrics['Sales'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['> FBA FulfillmentFee'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                fbaFulfillRow[dayField] = calculateRatio(metrics['> FBA FulfillmentFee'].days[dayField], metrics['Sales'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['> FBA FulfillmentFee'].weeks[weekField] !== undefined && metrics['Sales'].weeks[weekField] !== undefined) {
                fbaFulfillRow[weekField] = calculateRatio(metrics['> FBA FulfillmentFee'].weeks[weekField], metrics['Sales'].weeks[weekField]);
              }
            }
            
            ratioRows.push(fbaFulfillRow);
          }
          
          // Add the remaining FBA percentages if metrics exist
          const remainingFbaMetrics = [
            { name: '> FBA StorageFees %', numerator: '> FBA Storage Fees' },
            { name: '> FBA LTSF %', numerator: '> FBA LTSF' },
            { name: '> FBA Other Fees %', numerator: '> FBA Other Fees' }
          ];
          
          remainingFbaMetrics.forEach(({ name, numerator }) => {
            if (metrics[numerator] && metrics['Sales']) {
              const row = {
                metric: name,
                total: calculateRatio(metrics[numerator].total, metrics['Sales'].total)
              };
              
              for (let i = 1; i <= 12; i++) {
                const numValue = metrics[numerator].months[i-1];
                const sales = metrics['Sales'].months[i-1];
                row[`month_${i}`] = calculateRatio(numValue, sales);
              }
              
              // Calculate day-wise values
              ['l7', 'p7', 'l30'].forEach(dayField => {
                if (metrics[numerator].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                  row[dayField] = calculateRatio(metrics[numerator].days[dayField], metrics['Sales'].days[dayField]);
                }
              });
              
              // Add day_2 to day_60 values
              for (let i = 2; i <= 60; i++) {
                const dayField = `day_${i}`;
                if (metrics[numerator].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                  row[dayField] = calculateRatio(metrics[numerator].days[dayField], metrics['Sales'].days[dayField]);
                }
              }
              
              // Calculate weekly values
              for (let i = 1; i <= 12; i++) {
                const weekField = `week_${i}`;
                if (metrics[numerator].weeks[weekField] !== undefined && metrics['Sales'].weeks[weekField] !== undefined) {
                  row[weekField] = calculateRatio(metrics[numerator].weeks[weekField], metrics['Sales'].weeks[weekField]);
                }
              }
              
              ratioRows.push(row);
            }
          });
          
          // VAT %
          if (metrics['VAT'] && metrics['Sales']) {
            const vatRow = {
              metric: 'VAT %',
              total: calculateRatio(metrics['VAT'].total, metrics['Sales'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const vat = metrics['VAT'].months[i-1];
              const sales = metrics['Sales'].months[i-1];
              vatRow[`month_${i}`] = calculateRatio(vat, sales);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['VAT'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                vatRow[dayField] = calculateRatio(metrics['VAT'].days[dayField], metrics['Sales'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['VAT'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                vatRow[dayField] = calculateRatio(metrics['VAT'].days[dayField], metrics['Sales'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['VAT'].weeks[weekField] !== undefined && metrics['Sales'].weeks[weekField] !== undefined) {
                vatRow[weekField] = calculateRatio(metrics['VAT'].weeks[weekField], metrics['Sales'].weeks[weekField]);
              }
            }
            
            ratioRows.push(vatRow);
          }
          
          // Promo Value %
          if (metrics['Promo Value'] && metrics['Sales']) {
            const promoRow = {
              metric: 'Promo Value %',
              total: calculateRatio(metrics['Promo Value'].total, metrics['Sales'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const promo = metrics['Promo Value'].months[i-1];
              const sales = metrics['Sales'].months[i-1];
              promoRow[`month_${i}`] = calculateRatio(promo, sales);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Promo Value'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                promoRow[dayField] = calculateRatio(metrics['Promo Value'].days[dayField], metrics['Sales'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Promo Value'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                promoRow[dayField] = calculateRatio(metrics['Promo Value'].days[dayField], metrics['Sales'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Promo Value'].weeks[weekField] !== undefined && metrics['Sales'].weeks[weekField] !== undefined) {
                promoRow[weekField] = calculateRatio(metrics['Promo Value'].weeks[weekField], metrics['Sales'].weeks[weekField]);
              }
            }
            
            ratioRows.push(promoRow);
          }
          
          // Ads metrics
          if (metrics['Ads Cost'] && metrics['Ads Clicks']) {
            const adsCpcRow = {
              metric: 'Ads CPC',
              total: calculateRatio(metrics['Ads Cost'].total, metrics['Ads Clicks'].total, false)
            };
            
            for (let i = 1; i <= 12; i++) {
              const adsCost = metrics['Ads Cost'].months[i-1];
              const adsClicks = metrics['Ads Clicks'].months[i-1];
              adsCpcRow[`month_${i}`] = calculateRatio(adsCost, adsClicks, false);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Ads Cost'].days[dayField] !== undefined && metrics['Ads Clicks'].days[dayField] !== undefined) {
                adsCpcRow[dayField] = calculateRatio(metrics['Ads Cost'].days[dayField], metrics['Ads Clicks'].days[dayField], false);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Ads Cost'].days[dayField] !== undefined && metrics['Ads Clicks'].days[dayField] !== undefined) {
                adsCpcRow[dayField] = calculateRatio(metrics['Ads Cost'].days[dayField], metrics['Ads Clicks'].days[dayField], false);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Ads Cost'].weeks[weekField] !== undefined && metrics['Ads Clicks'].weeks[weekField] !== undefined) {
                adsCpcRow[weekField] = calculateRatio(metrics['Ads Cost'].weeks[weekField], metrics['Ads Clicks'].weeks[weekField], false);
              }
            }
            
            ratioRows.push(adsCpcRow);
          }
          
          if (metrics['Ads Clicks'] && metrics['Impressions']) {
            const adsCtrRow = {
              metric: 'Ads CTR %',
              total: calculateRatio(metrics['Ads Clicks'].total, metrics['Impressions'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const adsClicks = metrics['Ads Clicks'].months[i-1];
              const impressions = metrics['Impressions'].months[i-1];
              adsCtrRow[`month_${i}`] = calculateRatio(adsClicks, impressions);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Ads Clicks'].days[dayField] !== undefined && metrics['Impressions'].days[dayField] !== undefined) {
                adsCtrRow[dayField] = calculateRatio(metrics['Ads Clicks'].days[dayField], metrics['Impressions'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Ads Clicks'].days[dayField] !== undefined && metrics['Impressions'].days[dayField] !== undefined) {
                adsCtrRow[dayField] = calculateRatio(metrics['Ads Clicks'].days[dayField], metrics['Impressions'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Ads Clicks'].weeks[weekField] !== undefined && metrics['Impressions'].weeks[weekField] !== undefined) {
                adsCtrRow[weekField] = calculateRatio(metrics['Ads Clicks'].weeks[weekField], metrics['Impressions'].weeks[weekField]);
              }
            }
            
            ratioRows.push(adsCtrRow);
          }
          
          if (metrics['Ads Orders'] && metrics['Ads Clicks']) {
            const adsCvrRow = {
              metric: 'Ads CVR %',
              total: calculateRatio(metrics['Ads Orders'].total, metrics['Ads Clicks'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const adsOrders = metrics['Ads Orders'].months[i-1];
              const adsClicks = metrics['Ads Clicks'].months[i-1];
              adsCvrRow[`month_${i}`] = calculateRatio(adsOrders, adsClicks);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Ads Orders'].days[dayField] !== undefined && metrics['Ads Clicks'].days[dayField] !== undefined) {
                adsCvrRow[dayField] = calculateRatio(metrics['Ads Orders'].days[dayField], metrics['Ads Clicks'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Ads Orders'].days[dayField] !== undefined && metrics['Ads Clicks'].days[dayField] !== undefined) {
                adsCvrRow[dayField] = calculateRatio(metrics['Ads Orders'].days[dayField], metrics['Ads Clicks'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Ads Orders'].weeks[weekField] !== undefined && metrics['Ads Clicks'].weeks[weekField] !== undefined) {
                adsCvrRow[weekField] = calculateRatio(metrics['Ads Orders'].weeks[weekField], metrics['Ads Clicks'].weeks[weekField]);
              }
            }
            
            ratioRows.push(adsCvrRow);
          }
          
          if (metrics['Ads Cost'] && metrics['Ads Sales']) {
            const acosRow = {
              metric: 'ACoS',
              total: calculateRatio(metrics['Ads Cost'].total, metrics['Ads Sales'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const adsCost = metrics['Ads Cost'].months[i-1];
              const adsSales = metrics['Ads Sales'].months[i-1];
              acosRow[`month_${i}`] = calculateRatio(adsCost, adsSales);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Ads Cost'].days[dayField] !== undefined && metrics['Ads Sales'].days[dayField] !== undefined) {
                acosRow[dayField] = calculateRatio(metrics['Ads Cost'].days[dayField], metrics['Ads Sales'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Ads Cost'].days[dayField] !== undefined && metrics['Ads Sales'].days[dayField] !== undefined) {
                acosRow[dayField] = calculateRatio(metrics['Ads Cost'].days[dayField], metrics['Ads Sales'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Ads Cost'].weeks[weekField] !== undefined && metrics['Ads Sales'].weeks[weekField] !== undefined) {
                acosRow[weekField] = calculateRatio(metrics['Ads Cost'].weeks[weekField], metrics['Ads Sales'].weeks[weekField]);
              }
            }
            
            ratioRows.push(acosRow);
          }
          
          if (metrics['Ads Sales'] && metrics['Sales']) {
            const adrRow = {
              metric: 'ADR',
              total: calculateRatio(metrics['Ads Sales'].total, metrics['Sales'].total)
            };
            
            for (let i = 1; i <= 12; i++) {
              const adsSales = metrics['Ads Sales'].months[i-1];
              const sales = metrics['Sales'].months[i-1];
              adrRow[`month_${i}`] = calculateRatio(adsSales, sales);
            }
            
            // Calculate day-wise values
            ['l7', 'p7', 'l30'].forEach(dayField => {
              if (metrics['Ads Sales'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                adrRow[dayField] = calculateRatio(metrics['Ads Sales'].days[dayField], metrics['Sales'].days[dayField]);
              }
            });
            
            // Add day_2 to day_60 values
            for (let i = 2; i <= 60; i++) {
              const dayField = `day_${i}`;
              if (metrics['Ads Sales'].days[dayField] !== undefined && metrics['Sales'].days[dayField] !== undefined) {
                adrRow[dayField] = calculateRatio(metrics['Ads Sales'].days[dayField], metrics['Sales'].days[dayField]);
              }
            }
            
            // Calculate weekly values
            for (let i = 1; i <= 12; i++) {
              const weekField = `week_${i}`;
              if (metrics['Ads Sales'].weeks[weekField] !== undefined && metrics['Sales'].weeks[weekField] !== undefined) {
                adrRow[weekField] = calculateRatio(metrics['Ads Sales'].weeks[weekField], metrics['Sales'].weeks[weekField]);
              }
            }
            
            ratioRows.push(adrRow);
          }
          
          // Sort ratio rows by preferred order
          const metricOrder = [
            'Return %', 'Profit %', 'Profit/Pc', 'TACoS %', 'Amazon Fees %', 
            '> FBA Commision %', '> FBA FulfimentFees %', '> FBA StorageFees %', '> FBA LTSF %', '> FBA Other Fees %',
            'COGS %', 'VAT %', 'Promo Value %',
            'Ads CPC', 'Ads CTR %', 'Ads CVR %', 'ACoS', 'ADR'
          ];
          
          ratioRows.sort((a, b) => {
            const indexA = metricOrder.indexOf(a.metric);
            const indexB = metricOrder.indexOf(b.metric);
            return indexA - indexB;
          });
          
          console.log(`Generated ${ratioRows.length} ratio rows`);
          setRatioData(ratioRows);
        } catch (err) {
          console.error('Error calculating ratios:', err);
        }
      };
      
      // Register for events that would require recalculation
      const listener = () => {
        if (showRatios) {
          updateRatioData();
        }
      };
      
      gridRef.current.api.addEventListener('modelUpdated', listener);
      gridRef.current.api.addEventListener('filterChanged', listener);
      gridRef.current.api.addEventListener('rowGroupOpened', listener);
      
      // Initial calculation
      if (showRatios && rowData.length > 0) {
        updateRatioData();
      }
      
      return () => {
        if (gridRef.current && gridRef.current.api) {
          gridRef.current.api.removeEventListener('modelUpdated', listener);
          gridRef.current.api.removeEventListener('filterChanged', listener);
          gridRef.current.api.removeEventListener('rowGroupOpened', listener);
        }
      };
    }
  }, [rowData, showRatios]);

  // Handle mouse events for resizing
  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const containerHeight = containerRect.height;
        const mouseY = e.clientY - containerRect.top;
        
        // Calculate percentages
        const newMainGridHeight = (mouseY / containerHeight) * 100;
        const newRatioGridHeight = 100 - newMainGridHeight;
        
        // Set minimum heights to prevent grids from disappearing
        if (newMainGridHeight >= 20 && newRatioGridHeight >= 20) {
          setMainGridHeight(newMainGridHeight);
          setRatioGridHeight(newRatioGridHeight);
        }
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const formatValue = (params, defaultDecimalPlaces = 0) => {
    if (!params.data || !params.data.metric) {
      return params.value ? params.value.toLocaleString('en-US', { 
        maximumFractionDigits: defaultDecimalPlaces, 
        minimumFractionDigits: defaultDecimalPlaces 
      }) : '';
    }
  
    const metric = params.data.metric; 
    const percentageMetrics = [
      "Profit %", "Return %", "TACoS %", "Amazon Fees %", "COGS %",
      "> FBA Commision %", "> FBA FulfimentFees %", "> FBA StorageFees %", "> FBA LTSF %",
      "> FBA Other Fees %", "VAT %", "Promo Value %", "Ads CTR %", "Ads CVR %", "ACoS", "ADR"
    ];
    const shouldFormatToOneDecimal = percentageMetrics.includes(metric); 
  
    const decimalPlaces = shouldFormatToOneDecimal ? 1 : defaultDecimalPlaces;
  
    return params.value ? params.value.toLocaleString('en-US', {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces
    }) : '';
  };

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setAppliedQuickFilter('');
  }, []);

  const handleQuickFilterChange = useCallback((filter) => {
    setAppliedQuickFilter(filter);
  }, []);

  const sideBar = useMemo(() => AGGrid_Sidebar, []);
  
  // Ratio grid column definitions - same as main grid
  const ratioColumnDefs = useMemo(() => columns, []);
  
  // Toggle for ratio grid visibility
  const toggleRatios = useCallback(() => {
    setShowRatios(prev => !prev);
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Line Historic</Typography>
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={<Switch checked={showRatios} onChange={toggleRatios} />}
            label="Show Ratios"
            style={{ marginRight: '10px' }}
          />
          <QuickFilter onFilterChange={handleQuickFilterChange} />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <Box 
          ref={containerRef}
          display="flex" 
          flexDirection="column" 
          style={{ flex: 1, position: 'relative' }}
        >
          {/* Main data grid */}
          <div 
            className="ag-theme-alpine" 
            style={{ 
              height: showRatios ? `${mainGridHeight}%` : '100%', 
              width: '100%',
              transition: isDragging ? 'none' : 'height 0.2s ease'
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columns}
              {...AGGrid_Options}
              sideBar={sideBar}
              quickFilterText={appliedQuickFilter}
              alignedGrids={() => showRatios && ratioGridRef.current ? [ratioGridRef.current.api] : []}
              autoGroupColumnDef={{
                headerName: 'Group',
                minWidth: 250,
                cellRendererParams: {
                  suppressCount: true,
                },
              }}
              getRowStyle={params => {
                if (params.node.rowPinned) {
                  return { fontWeight: 'bold', backgroundColor: theme.palette.background.default };
                }
                return null;
              }}
            />
          </div>
          
          {/* Draggable divider */}
          {showRatios && (
            <div 
              style={{
                height: '10px',
                width: '100%',
                backgroundColor: theme.palette.divider,
                cursor: 'ns-resize',
                position: 'relative',
                zIndex: 10,
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
              onMouseDown={handleMouseDown}
            >
              <div 
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50px',
                  height: '4px',
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '2px'
                }}
              />
            </div>
          )}
          
          {/* Ratio grid - only shown when toggle is on */}
          {showRatios && (
            <div 
              className="ag-theme-alpine" 
              style={{ 
                height: `${ratioGridHeight}%`, 
                width: '100%',
                backgroundColor: theme.palette.background.paper,
                overflow: 'auto',
                transition: isDragging ? 'none' : 'height 0.2s ease'
              }}
            >
              <AgGridReact
                ref={ratioGridRef}
                rowData={ratioData}
                columnDefs={ratioColumnDefs}
                alignedGrids={() => gridRef.current ? [gridRef.current.api] : []}
                suppressHorizontalScroll={false}
                {...AGGrid_Options}
                headerHeight={0} // Hide header since it's the same as the main grid
                rowStyle={{}}
                defaultColDef={{
                  ...AGGrid_Options.defaultColDef,
                  valueFormatter: params => formatValue(params, 1) // Always use 1 decimal place for ratios
                }}
              />
            </div>
          )}
        </Box>
      )}
    </div>
  );
};

export default LineMonthly;