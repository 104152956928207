import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, TextField, Box, Tabs, Tab, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { styled } from '@mui/material/styles';
import { columns, columns_sku, BQ_Repricing_Log_Columns } from './repricingColumns.js';
import { useTheme } from "@mui/material";
import AGGrid_Options from '../../components/global/AGGrid_Options.jsx';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar.jsx';

const Repricing = () => {
    const [countryPricingData, setCountryPricingData] = useState('');
    const [linePricingData, setLinePricingData] = useState('');
    const [skuPricingData, setSkuPricingData] = useState('');
    const [pricingData, setPricingData] = useState([]);
    const [pricingDataFeedback, setPricingDataFeedback] = useState({ message: '', style: {} });
    const gridRef = useRef(null);
    const [pricingFileFeedback, setPricingFileFeedback] = useState({ message: '', style: {} });
    const [cachedPricingData, setCachedPricingData] = useState(null);

    const theme = useTheme();
    const [logs, setLogs] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    const handlegetpricingData = async () => {
        setPricingDataFeedback({ message: 'Fetching pricing data...', style: { color: 'blue' } });
        try {
            const lines = linePricingData.split(/[\s,]+/).map(line => line.trim()).filter(line => line !== '');
            const response = await fetch('/api/repricing/repricing_line', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ country: countryPricingData, lines: lines })
            });

            const result = await response.json();
            if (response.ok) {
                const dataWithIds = result.data.map((row, index) => ({ ...row, id: index }));
                setPricingData(dataWithIds);
                setCachedPricingData(dataWithIds);
                setPricingDataFeedback({ message: 'Data fetched successfully', style: { color: 'blue' } });
            } else {
                setPricingDataFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
            }
        } catch (error) {
            setPricingDataFeedback({ message: `Error: ${error.message}`, style: { color: 'red' } });
        }
    };

    const handlegetpricingData_sku = async () => {
        setPricingDataFeedback({ message: 'Fetching pricing data...', style: { color: 'blue' } });
        if (!countryPricingData || !skuPricingData) {
            setPricingDataFeedback({ message: 'Please provide both country and SKUs', style: { color: 'red' } });
            return;
        }

        try {
            const skus = skuPricingData.split(/[\s,]+/).map(sku => sku.trim()).filter(sku => sku !== '');
            const response = await fetch('/api/repricing/repricing_skuwise', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ country: countryPricingData, skus: skus })
            });

            const result = await response.json();
            if (response.ok) {
                const dataWithIds = result.data.map((row, index) => ({ ...row, id: index }));
                setPricingData(dataWithIds);
                setPricingDataFeedback({ message: 'Data fetched successfully', style: { color: 'green' } });
            } else {
                setPricingDataFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
            }
        } catch (error) {
            setPricingDataFeedback({ message: `Error: ${error.message}`, style: { color: 'red' } });
        }
    };

    const handleSentPricingData = async () => {
        if (!cachedPricingData) {
            setPricingFileFeedback({ message: 'No cached data available. Please fetch data first.', style: { color: 'red' } });
            return;
        }

        setPricingFileFeedback({ message: 'Preparing pricing update file...', style: { color: 'blue' } });
        try {
            const response = await fetch('/api/repricing/pricing_upload', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ country: countryPricingData, lines: linePricingData.split(/[\s,]+/).map(line => line.trim()).filter(line => line !== '') })
            });

            const result = await response.json();
            if (response.ok) {
                const feedId = result.message.match(/Feed ID: ([A-Za-z0-9-]+)/)?.[1];
                const successMessage = feedId ? `Pricing update initiated successfully for ${countryPricingData} (Feed ID: ${feedId})` : result.message;
                setPricingFileFeedback({ message: successMessage, style: { color: 'green' } });
            } else {
                setPricingFileFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
            }
        } catch (error) {
            setPricingFileFeedback({ message: `Error submitting pricing update: ${error.message}`, style: { color: 'red' } });
        }
    };

    const handleSentPricingData_Sku = async () => {
        setPricingFileFeedback({ message: 'Preparing pricing update file...', style: { color: 'blue' } });
        try {
            const skus = skuPricingData.split(/[\s,]+/).map(sku => sku.trim()).filter(sku => sku !== '');
            const response = await fetch('/api/repricing/pricing_upload_skuwise', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ country: countryPricingData, skus: skus })
            });

            const result = await response.json();
            if (response.ok) {
                const feedId = result.message.match(/Feed ID: ([A-Za-z0-9-]+)/)?.[1];
                const successMessage = feedId ? `Pricing update initiated successfully for ${countryPricingData} (Feed ID: ${feedId})` : result.message;
                setPricingFileFeedback({ message: successMessage, style: { color: 'green' } });
            } else {
                setPricingFileFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
            }
        } catch (error) {
            setPricingFileFeedback({ message: `Error submitting pricing update: ${error.message}`, style: { color: 'red' } });
        }
    };

    const handleTabChange = (event, newValue) => { setActiveTab(newValue); };

    useEffect(() => {
        if (activeTab === 0) {
            fetch('/api/repricing/repricing_logs')
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(response => response.data)
                .then(data => setLogs(data.map((log, index) => ({ ...log, id: index }))))
                .catch(error => console.error('Error fetching logs:', error));
        }
    }, [activeTab]);

    const handleExportCSV = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const handleClearFilters = useCallback(() => {
        gridRef.current.api.setFilterModel(null);
        const quickFilterInput = document.querySelector('input[placeholder="Quick Filter... (Press Enter to apply)"]');
        if (quickFilterInput) {
            quickFilterInput.value = '';
        }
    }, []);

    const gridTheme = theme.palette.mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';

    return (
        <Box m="5px">
            {/* <Typography variant="h5" component="h1" gutterBottom style={{ fontWeight: 'bold', marginBottom: '0px' }}>
                Products Repricing
            </Typography> */}
            <StyledTabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="primary"
            >
                <StyledTab label="Repricing Logs" />
                <StyledTab label="Line Wise" />
                <StyledTab label="Sku Wise" />
            </StyledTabs>

            {activeTab === 0 && (
                <div style={{ padding: '10px', marginTop: '-60px', height: '99vh', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{
                        display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                        padding: '10px 20px', borderBottom: '1px solid #e0e0e0', marginBottom: '10px'
                    }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleExportCSV}
                            sx={{ marginRight: '10px' }}
                        >
                            Export CSV
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleClearFilters}
                        >
                            Clear Filters
                        </Button>
                    </Box>

                    <div className={gridTheme} style={{ flex: 1, marginLeft: '0px', width: '100%' }}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={BQ_Repricing_Log_Columns}
                            rowData={logs}
                            {...AGGrid_Options} sideBar={AGGrid_Sidebar}
                            defaultColDef={{
                                floatingFilter: true,
                                ...AGGrid_Options.defaultColDef,
                                cellStyle: params => ({
                                    ...AGGrid_Options.defaultColDef.cellStyle,
                                    backgroundColor: theme.palette.mode === 'dark' ? '#2d3436' : undefined,
                                    color: theme.palette.mode === 'dark' ? '#ffffff' : undefined
                                })
                            }}
                            enableColResize={true}
                            suppressColumnVirtualisation={true}
                            enableRangeSelection={true}
                            multiSortKey="ctrl"
                            pagination={true}
                            paginationPageSize={500}
                            paginationPageSizeSelector={[100, 500, 1000]}
                            suppressCellSelection={true}
                            rowHeight={32}
                            onGridReady={(params) => {
                                // params.api.sizeColumnsToFit();
                            }}
                        />
                    </div>
                </div>
            )}

            {activeTab === 1 && (
                <div style={{ padding: '10px', marginTop: '-15px', height: '95vh', display: 'flex', flexDirection: 'column' }}>
                    <Box mt={1}>
                        <Box display="flex" alignItems="center" mb={2} ml={0.5}>
                            <TextField
                                label="Country"
                                value={countryPricingData}
                                onChange={(e) => setCountryPricingData(e.target.value)}
                                style={{ marginRight: '10px' }}
                            />
                            <TextField
                                label="Lines (comma-separated)"
                                value={linePricingData}
                                onChange={(e) => setLinePricingData(e.target.value)}
                                style={{ marginRight: '10px', width: '500px' }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handlegetpricingData}
                                style={{ marginRight: '10px' }}
                            >
                                View Pricing Data
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleExportCSV}
                                sx={{ marginRight: '10px' }}
                            >
                                Export CSV
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#ffd54f',
                                    color: '#000',
                                    marginRight: '10px',
                                    '&:hover': { backgroundColor: '#ffeb3b' },
                                    fontWeight: 'bold'
                                }}
                                onClick={handleSentPricingData}
                            >
                                Send Pricing Data
                            </Button>
                        </Box>

                        {pricingDataFeedback.message && (
                            <Box mt={-1.5} mb={0} ml={0.5}>
                                <Typography style={pricingDataFeedback.style}>
                                    {pricingDataFeedback.message}
                                </Typography>
                            </Box>
                        )}

                        {pricingFileFeedback.message && (
                            <Box mt={-2.3} mb={0} ml={25}>
                                <Typography style={pricingFileFeedback.style}>
                                    {pricingFileFeedback.message}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <div className={gridTheme} style={{ flex: 1, marginLeft: '2px', width: '100%' }}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columns}
                            rowData={pricingData}
                            {...AGGrid_Options} sideBar={AGGrid_Sidebar}
                            defaultColDef={{
                                ...AGGrid_Options.defaultColDef,
                                cellStyle: params => ({
                                    ...AGGrid_Options.defaultColDef.cellStyle,
                                    backgroundColor: theme.palette.mode === 'dark' ? '#2d3436' : undefined,
                                    color: theme.palette.mode === 'dark' ? '#ffffff' : undefined
                                })
                            }}
                            enableRangeSelection={true}
                            multiSortKey="ctrl"
                            pagination={true}
                            paginationPageSize={100}
                            paginationPageSizeSelector={[50, 100, 500, 1000]}
                            suppressCellSelection={true}
                            onGridReady={(params) => {
                                params.api.sizeColumnsToFit();
                            }}
                        />
                    </div>
                </div>
            )}

            {activeTab === 2 && (
                <div style={{ padding: '10px', marginTop: '-15px', height: '95vh', display: 'flex', flexDirection: 'column' }}>
                    <Box mt={1}>
                        <Box display="flex" alignItems="center" mb={2} ml={0.5}>
                            <TextField
                                label="Country"
                                value={countryPricingData}
                                onChange={(e) => setCountryPricingData(e.target.value)}
                                style={{ marginRight: '10px' }}
                            />
                            <TextField
                                label="Skus (comma-separated)"
                                value={skuPricingData}
                                onChange={(e) => setSkuPricingData(e.target.value)}
                                style={{ marginRight: '10px', width: '500px' }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handlegetpricingData_sku}
                                style={{ marginRight: '10px' }}
                            >
                                View Pricing Data
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#ffd54f',
                                    color: '#000',
                                    marginRight: '10px',
                                    '&:hover': { backgroundColor: '#ffeb3b' },
                                    fontWeight: 'bold'
                                }}
                                onClick={handleSentPricingData_Sku}
                            >
                                Send Pricing Data
                            </Button>
                        </Box>

                        {pricingDataFeedback.message && (
                            <Box mt={-1.5} mb={0} ml={0.5}>
                                <Typography style={pricingDataFeedback.style}>
                                    {pricingDataFeedback.message}
                                </Typography>
                            </Box>
                        )}

                        {pricingFileFeedback.message && (
                            <Box mt={-2.3} mb={0} ml={25}>
                                <Typography style={pricingFileFeedback.style}>
                                    {pricingFileFeedback.message}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <div className={gridTheme} style={{ flex: 1, marginLeft: '5px', width: '100%' }}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columns_sku}
                            rowData={pricingData}
                            {...AGGrid_Options} sideBar={AGGrid_Sidebar}
                            enableRangeSelection={true}
                            multiSortKey="ctrl"
                            pagination={true}
                            paginationPageSize={100}
                            paginationPageSizeSelector={[50, 100, 500, 1000]}
                            onGridReady={(params) => {
                                params.api.sizeColumnsToFit();
                            }}
                        />
                    </div>
                </div>
            )}
        </Box>
    );
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& .MuiTab-root': {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        minWidth: '150px',
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    marginRight: theme.spacing(2),
    '&.Mui-selected': {
        fontWeight: 'bold', color: 'inherit',
    }
}));

export default Repricing;