/**
 * Utility function for logging analytics events
 */

// Get the API URL for logging
const API_URL = process.env.REACT_APP_API_URL || 
               (window.location.hostname === 'localhost' 
                ? 'http://localhost:5000' 
                : window.location.origin);

/**
 * Log an analytics event to the backend
 * @param {string} userId - User identifier (email, ID, etc.)
 * @param {string} tool - The tool or section being used (e.g., 'login', 'dashboard')
 * @param {string} eventType - Type of event (e.g., 'google_auth', 'button_click')
 * @param {Object} additionalData - Any additional data to include with the event
 * @returns {Promise<boolean>} - Whether the logging was successful
 */
export const logEvent = async (userId, tool, eventType, additionalData = {}) => {
  try {
    console.log(`Logging event: ${eventType} for tool: ${tool}`);
    
    const response = await fetch(`${API_URL}/api/analytics/log_event`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id: userId || 'unknown',
        tool_used: tool,
        event_type: eventType,
        additional_data: additionalData
      })
    });

    if (response.ok) {
      console.log(`Successfully logged event: ${eventType}`);
      return true;
    } else {
      console.warn(`Failed to log event: ${eventType}`);
      return false;
    }
  } catch (error) {
    console.error(`Error logging event: ${eventType}`, error);
    return false;
  }
}; 