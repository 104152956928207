import { Box } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useState, useEffect } from "react";
import Header from "../../components/Header";
import { Alert, Snackbar, Typography, Button } from "@mui/material";
import { IconButton } from "@mui/material";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import AGGrid_Options from "../../components/global/AGGrid_Options";

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState(null);
  const [authError, setAuthError] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const fetchTasks = async () => {
    try {
      const response = await fetch("/api/tasks/list");
      if (response.status === 401) {
        setAuthError(true);
        setError("Authentication failed. Please check Flower API credentials.");
        setTasks([]);
        return;
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // Process and enrich task data
      const processedTasks = Array.isArray(data) ? data.map(task => ({
        ...task,
        // Format runtime to 2 decimal places if it exists
        runtime: task.runtime ? Number(task.runtime).toFixed(2) : null,
        // Add any additional processing here
      })) : [];
      setTasks(processedTasks);
      setError(null);
      setAuthError(false);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setError(error.message);
      setTasks([]);  // Reset to empty array on error
    }
  };

  useEffect(() => {
    fetchTasks();
    const interval = setInterval(fetchTasks, 5000); // Refresh every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const columnDefs = useMemo(() => [
    { field: "uuid", headerName: "Task ID", flex: 1,
      cellRenderer: (params) => params.value ? params.value.slice(0, 8) + "..." : "" // Show only first 8 chars of UUID
    },
    { field: "name", headerName: "Task Name", flex: 1.5,
      valueFormatter: (params) => {
        // Show only the last part of the task name
        if (!params.value) return "";
        const parts = params.value.split('.');
        return parts[parts.length - 1];
      }
    },
    { field: "state", headerName: "Status", flex: 1,
      cellRenderer: (params) => {
        const state = params.value;
        let color = "inherit";
        let text = state;
        if (state === "SUCCESS") {
          color = "green";
          text = "✓ Success";
        }
        if (state === "FAILURE") {
          color = "red";
          text = "✗ Failed";
        }
        if (state === "STARTED") {
          color = "blue";
          text = "⟳ Running";
        }
        if (state === "PENDING") {
          text = "⋯ Pending";
        }
        return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
      }
    },
    { field: "country", headerName: "Country", flex: 0.7 },
    { field: "line", headerName: "Line", flex: 0.7 },
    { field: "task_type", headerName: "Type", flex: 2,
      valueFormatter: (params) => {
        if (!params.value) return "";
        // Convert snake_case to Title Case
        return params.value
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }
    },
    { field: "total_keywords", headerName: "Total", flex: 0.8, type: 'numericColumn',
      valueFormatter: (params) => params.value?.toLocaleString() || '-'
    },
    { field: "processed_keywords", headerName: "Processed", flex: 0.8, type: 'numericColumn',
      valueFormatter: (params) => params.value?.toLocaleString() || '-',
      cellRenderer: (params) => {
        if (!params.value) return '-';
        const total = params.data.total_keywords;
        if (!total) return params.value.toLocaleString();
        const percentage = Math.round((params.value / total) * 100);
        return `${params.value.toLocaleString()} (${percentage}%)`;
      }
    },
    { field: "runtime", headerName: "Runtime", flex: 0.8, type: 'numericColumn',
      valueFormatter: (params) => {
        if (!params.value) return '-';
        return `${Number(params.value).toFixed(1)}s`;
      }
    },
    { field: "received", headerName: "Received", flex: 1.2,
      valueFormatter: (params) => {
        if (!params.value) return '-';
        return new Date(params.value).toLocaleTimeString();
      }
    },
    { field: "started", headerName: "Started", flex: 1.2,
      valueFormatter: (params) => {
        if (!params.value) return '-';
        return new Date(params.value).toLocaleTimeString();
      }
    },
    { field: "worker", headerName: "Worker", flex: 1,
      valueFormatter: (params) => {
        if (!params.value) return '-';
        // Show only the first part of the worker name
        return params.value.split('@')[0];
      }
    },
    {
        headerName: 'Actions',
        field: 'actions',
        sortable: false,
        filter: false,
        width: 120,
        cellRenderer: (params) => {
            if (!params.data) return null;
            return (
                <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                        size="small"
                        onClick={() => handleTerminateTask(params.data.uuid)}
                        disabled={!['STARTED', 'PENDING'].includes(params.data.state)}
                        title="Terminate Task"
                        color="error"
                    >
                        <StopCircleIcon />
                    </IconButton>
                </Box>
            );
        }
    }
  ], []);

  const handleTerminateTask = async (taskId) => {
    try {
      const response = await fetch(`/api/keyword-classifier/terminate_task/${taskId}`, {
        method: 'POST',
      });
      const data = await response.json();
      
      if (response.ok) {
        setSnackbar({
          open: true,
          message: 'Task terminated successfully',
          severity: 'success'
        });
        // Refresh the task list
        fetchTasks();
      } else {
        throw new Error(data.error || 'Failed to terminate task');
      }
    } catch (error) {
      console.error('Error terminating task:', error);
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error'
      });
    }
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Tasks Monitor</Typography>
      </Box>
      
      {error && (
        <Box mb={2}>
          <Alert severity={authError ? "warning" : "error"}>
            {error}
            {authError && (
              <div>
                Set FLOWER_BASIC_AUTH environment variable with format "username:password" or enable unauthenticated access with FLOWER_UNAUTHENTICATED_API=1
              </div>
            )}
          </Alert>
        </Box>
      )}
      <Box
        height="75vh"
        sx={{
          "& .ag-theme-alpine": {
            border: "none",
          },
        }}
      >
        <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            rowData={tasks}
            columnDefs={columnDefs}
            {...AGGrid_Options}
          />
        </div>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Tasks; 