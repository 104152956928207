import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Dialog, DialogTitle, DialogContent, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FlagIcon from '@mui/icons-material/Flag';
import axios from 'axios';

// Component can be used in three ways:
// 1. History View: Shows FBA stock history over time
// 2. Inventory View: Shows current inventory breakdown
// 3. PO Checks View: Shows PO check details
const SKUTooltip = ({ sku, channel_abb, shipment_id, triggerButton, type = 'history' }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        try {
          // Different endpoints based on type
          let endpoint;
          let params = {};
          
          if (type === 'history') {
            endpoint = '/api/dih/get_launch_data';
            params = { sku, channel_abb };
          } else if (type === 'inventory') {
            endpoint = '/api/cosp/get_inventory_details';
            params = { sku, channel_abb };
          } else if (type === 'po_checks') {
            endpoint = '/api/get_po_check_details';
            params = { shipment_id };
          }
          
          const response = await axios.get(endpoint, { params });
          
          if (type === 'po_checks' && response.data) {
            console.log("SKUTooltip - PO Check data keys:", response.data.length > 0 ? Object.keys(response.data[0]) : 'No data');
            console.log("SKUTooltip - Cat values:", response.data.map(row => row.cat).filter(Boolean));
          }
          
          setData(response.data);
          setLoading(false);
        } catch (err) {
          console.error("Error fetching data for SKUTooltip:", err);
          setError(err.message);
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [sku, channel_abb, shipment_id, open, type]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // History view specific function
  const getRowStyle = (currentStock, previousStock, isFirstRow) => {
    if (currentStock === 0 && previousStock > 0) {
      return { backgroundColor: '#ffebee' }; // Light red for out of stock
    }
    if (!isFirstRow && currentStock >= previousStock + 5) {
      return { backgroundColor: '#e8f5e9' }; // Light green for stock increase
    }
    return {};
  };

  // Render inventory breakdown table
  const renderInventoryTable = () => (
    <Box sx={{
      maxHeight: '400px',
      overflowY: 'auto',
      '& table': {
        width: '100%',
        borderCollapse: 'collapse',
        fontSize: '14px'
      }
    }}>
      <table>
        <thead>
          <tr>
            <th style={{ width: '60%', textAlign: 'left', padding: '8px' }}>Inventory</th>
            <th style={{ width: '40%', textAlign: 'right', padding: '8px' }}>Units</th>
          </tr>
        </thead>
        <tbody>
          {[
            { label: 'Available', field: 'available_stock' },
            { label: 'Inbound', field: 'inbound_stock' },
            { label: 'Reserved', field: 'reserved_stock' },
            { label: 'Unfulfillable', field: 'unsellable_stock' },
            { label: 'Researching', field: 'researching_stock' },
            { label: 'MFN', field: 'mfn_stock' },
          ].map(({ label, field }) => (
            <tr key={field}>
              <td style={{ padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 0.5)' }}>{label}</td>
              <td style={{ padding: '8px', borderBottom: '1px solid rgba(224, 224, 224, 0.5)', textAlign: 'right' }}>
                {data?.[field] || 0}
              </td>
            </tr>
          ))}
          <tr style={{ fontWeight: 'bold' }}>
            <td style={{ padding: '8px' }}>Total</td>
            <td style={{ padding: '8px', textAlign: 'right' }}>
              {data ? Object.entries(data)
                .filter(([key]) => key.endsWith('_stock'))
                .reduce((sum, [, value]) => sum + (value || 0), 0) : 0}
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );

  // Render history table (existing functionality)
  const renderHistoryTable = () => (
    <Box sx={{
      maxHeight: '400px',
      overflowY: 'auto',
      '& table': {
        width: '100%',
        borderCollapse: 'collapse',
        fontSize: '14px',
        tableLayout: 'fixed'
      },
      '& thead': {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 10,
        width: '100%'
      },
      '& th': {
        backgroundColor: 'white',
        padding: '8px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        textAlign: 'left'
      },
      '& td': {
        padding: '8px',
        borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
        backgroundColor: 'inherit'
      }
    }}>
      <table>
        <thead>
          <tr>
            <th style={{ width: '15%' }}>Country</th>
            <th style={{ width: '20%' }}>SKU</th>
            <th style={{ width: '20%' }}>Date</th>
            <th style={{ width: '15%', textAlign: 'right' }}>Stock</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            const previousStock = index < data.length - 1 ? data[index + 1].stock || 0 : 0;
            const currentStock = row.stock || 0;
            return (
              <tr key={index} style={getRowStyle(currentStock, previousStock, index === data.length - 1)}>
                <td>{row.country}</td>
                <td>{row.msku}</td>
                <td>{formatDate(row.ob_date)}</td>
                <td style={{ textAlign: 'right' }}>
                  {currentStock.toLocaleString()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );

  // Render PO checks details table
  const renderPOChecksTable = () => {
    if (!Array.isArray(data) || data.length === 0) return null;

    // Add flag column at the start
    const columnsToShow = ['flag', 'sku', 'quantity', 'last_sold_days', 'fba_stock', 'doc', 'discount', 'listing_issue'];
    
    const shouldShowFlag = (row) => {
      const conditions = [
        // Condition 1: Last sold days > 7 and FBA stock > 0
        (row.last_sold_days > 7 && row.fba_stock > 0),
        // Condition 2: DOC > 120
        row.doc > 120,
        // Condition 3: Discount >= -20%
        (row.discount && row.discount >= -20),
        // Condition 4: Listing Issue is not blank
        (row.listing_issue && row.listing_issue.trim() !== '')
      ];
      return conditions.some(condition => condition);
    };

    const getFlagTooltip = (row) => {
      const reasons = [];
      if (row.last_sold_days > 7 && row.fba_stock > 0) {
        reasons.push(`Last sold: ${row.last_sold_days} days, FBA stock: ${row.fba_stock}`);
      }
      if (row.doc > 120) {
        reasons.push(`DOC: ${row.doc} days`);
      }
      if (row.discount && row.discount >= -20) {
        reasons.push(`Discount: ${row.discount}%`);
      }
      if (row.listing_issue && row.listing_issue.trim() !== '') {
        reasons.push(`Listing Issue: ${row.listing_issue}`);
      }
      return reasons.join('\n');
    };
    
    // Column display names and formatters
    const columnConfig = {
      flag: { 
        header: '', 
        width: '40px',
        formatter: (_, row) => shouldShowFlag(row) ? (
          <Tooltip title={getFlagTooltip(row)} placement="right">
            <FlagIcon sx={{ color: '#f44336', fontSize: '20px' }} />
          </Tooltip>
        ) : null
      },
      sku: { 
        header: 'SKU',
        width: '120px',
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' }
      },
      quantity: { 
        header: 'Quantity',
        width: '80px',
        headerStyle: { textAlign: 'right' },
        cellStyle: { textAlign: 'right' },
        formatter: (value) => value ? value.toLocaleString('en-US', {maximumFractionDigits: 0}) : ''
      },
      last_sold_days: { 
        header: 'Last Sold Days',
        width: '100px',
        headerStyle: { textAlign: 'right' },
        cellStyle: { textAlign: 'right' },
        formatter: (value) => value ? value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        getCellStyle: (value, row) => ({
          textAlign: 'right',
          backgroundColor: (value > 7 && row.fba_stock > 0) ? '#ffd6d6' : 'inherit'
        })
      },
      fba_stock: { 
        header: 'FBA Stock',
        width: '80px',
        headerStyle: { textAlign: 'right' },
        cellStyle: { textAlign: 'right' },
        formatter: (value) => value ? value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        getCellStyle: (value, row) => ({
          textAlign: 'right',
          backgroundColor: (row.last_sold_days > 7 && value > 0) ? '#ffd6d6' : 'inherit'
        })
      },
      doc: { 
        header: 'DOC',
        width: '70px',
        headerStyle: { textAlign: 'right' },
        cellStyle: { textAlign: 'right' },
        formatter: (value) => value ? value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        getCellStyle: (value) => ({
          textAlign: 'right',
          backgroundColor: value > 120 ? '#ffd6d6' : 'inherit'
        })
      },
      discount: { 
        header: 'Discount',
        width: '80px',
        headerStyle: { textAlign: 'right' },
        cellStyle: { textAlign: 'right' },
        formatter: (value) => value ? `${value}%` : '',
        getCellStyle: (value) => ({
          textAlign: 'right',
          backgroundColor: (value && value >= -20) ? '#ffd6d6' : 'inherit'
        })
      },
      listing_issue: { 
        header: 'Listing Issue',
        width: '150px',
        headerStyle: { textAlign: 'left' },
        cellStyle: { textAlign: 'left' },
        getCellStyle: (value) => ({
          textAlign: 'left',
          backgroundColor: (value && value.trim() !== '') ? '#ffd6d6' : 'inherit'
        })
      }
    };

    return (
      <Box sx={{
        maxHeight: '400px',
        overflowY: 'auto',
        '& table': {
          width: '100%',
          borderCollapse: 'collapse',
          fontSize: '14px',
          tableLayout: 'fixed'
        },
        '& thead': {
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 10,
          width: '100%'
        },
        '& th': {
          backgroundColor: 'white',
          padding: '4px 6px',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          borderRight: '1px solid rgba(224, 224, 224, 0.5)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:last-child': {
            borderRight: 'none'
          }
        },
        '& td': {
          padding: '4px 6px',
          borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
          borderRight: '1px solid rgba(224, 224, 224, 0.5)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:last-child': {
            borderRight: 'none'
          }
        }
      }}>
        <table>
          <thead>
            <tr>
              {columnsToShow.map(column => (
                <th 
                  key={column} 
                  title={columnConfig[column].header}
                  style={{
                    ...(columnConfig[column].width ? { width: columnConfig[column].width } : {}),
                    ...(columnConfig[column].headerStyle || {})
                  }}
                >
                  {columnConfig[column].header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columnsToShow.map(column => (
                  <td 
                    key={column} 
                    title={column !== 'flag' ? row[column]?.toString() : ''}
                    style={{
                      ...(columnConfig[column].width ? { width: columnConfig[column].width } : {}),
                      ...(columnConfig[column].cellStyle || {}),
                      ...(columnConfig[column].getCellStyle ? columnConfig[column].getCellStyle(row[column], row) : {})
                    }}
                  >
                    {columnConfig[column].formatter 
                      ? columnConfig[column].formatter(row[column], row)
                      : row[column]?.toString() || ''}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    );
  };

  const getPODetailsHeader = () => {
    if (!data?.[0]) return '';
    const firstRow = data[0];
    return (
      <>
        <Typography variant="subtitle1" component="div" sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
          Shipment: {shipment_id}
        </Typography>
        <Typography variant="subtitle2" component="div" sx={{ 
          color: 'text.secondary',
          fontSize: '1rem',
          mt: 0.5
        }}>
          Dispatch Date: <Typography component="span" sx={{ fontWeight: 600 }}>{formatDate(firstRow.dispatch_date)}</Typography> &nbsp;|&nbsp; 
          Region: <Typography component="span" sx={{ fontWeight: 600 }}>{firstRow.region}</Typography> &nbsp;|&nbsp; 
          Status: <Typography component="span" sx={{ fontWeight: 600 }}>{firstRow.status}</Typography> &nbsp;|&nbsp; 
          Production: <Typography component="span" sx={{ fontWeight: 600 }}>{firstRow.production}</Typography>
        </Typography>
      </>
    );
  };

  return (
    <>
      <Box onClick={() => setOpen(true)} style={{ cursor: 'pointer', display: 'inline-flex' }}>
        {triggerButton}
      </Box>
      <Dialog 
        open={open} 
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              {type === 'po_checks' ? getPODetailsHeader() : (
                <Typography variant="h6">
                  {type === 'history' ? 'FBA Stock History' : 'Inventory Details'}
                </Typography>
              )}
            </Box>
            <IconButton onClick={() => setOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={20} />
            </Box>
          ) : error ? (
            <Box sx={{ p: 2, color: 'error.main' }}>
              <Typography variant="body2">Error loading data</Typography>
            </Box>
          ) : !data || (Array.isArray(data) && data.length === 0) ? (
            <Box sx={{ p: 2 }}>
              <Typography variant="body2">No data available</Typography>
            </Box>
          ) : (
            type === 'history' ? renderHistoryTable() : 
            type === 'inventory' ? renderInventoryTable() :
            renderPOChecksTable()
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SKUTooltip; 