import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { createCampaignChangesColumns } from './campaignChangesColumns.js';
import QuickFilter from '../../components/global/QuickFilter.jsx';
import ScratchpadNotes from '../../components/global/ScratchpadNotes';
import AGGrid_Options from '../../components/global/AGGrid_Options';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar';

const CampaignChanges = () => {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [error, setError] = useState(null);
  const gridRef = useRef(null);
  const [quickFilterText, setQuickFilterText] = useState('');

  useEffect(() => {
    document.title = "Campaign Changes | Encasa HQ";
    console.log('Initiating Campaign Changes data fetch');
    fetch('/api/campaigns/get_campaigns')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          
          const transformedData = transformData(data);
          setRowData(transformedData);
          
          const columns = createCampaignChangesColumns(data);
          setColumnDefs(columns);
        } else {
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching Campaign Changes data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, []);

  const transformData = (data) => {
    const transformedData = {};
    const totalSpendPerDate = {};
  
    // Step 1: Collect total spend per date and transform data
    data.forEach(item => {
      const key = `${item.country_code}_${item.campaign_name}`;
      if (!transformedData[key]) {
        transformedData[key] = {
          country_code: item.country_code,
          campaign_name: item.campaign_name,
          line: item.line,
          dri: item.dri        
        };
      }
      transformedData[key][`${item.date}_budget`] = item.budget;
      transformedData[key][`${item.date}_impressions`] = item.impressions;
      transformedData[key][`${item.date}_clicks`] = item.clicks;
      transformedData[key][`${item.date}_spend`] = item.spend;
      transformedData[key][`${item.date}_sales`] = item.sales;
      transformedData[key][`${item.date}_orders`] = item.orders;
      transformedData[key][`${item.date}_units`] = item.units;
      transformedData[key][`${item.date}_cvr`] = item.cvr;
      transformedData[key][`${item.date}_acos`] = item.acos;
  
      // Accumulate total spend per date
      totalSpendPerDate[item.date] = (totalSpendPerDate[item.date] || 0) + item.spend;
    });
  
    // Get sorted dates in descending order (latest date first)
    const dates = [...new Set(data.map(item => item.date))].sort((a, b) => new Date(b) - new Date(a));
  
    // Step 2: Calculate impact, deltas, and delta percentages for each campaign
    Object.values(transformedData).forEach(campaign => {
      for (let i = 0; i < dates.length - 1; i++) {
        const date = dates[i];
        const nextDate = dates[i + 1];
  
        const acosField = `${date}_acos`;
        const nextAcosField = `${nextDate}_acos`;
        const spendField = `${date}_spend`;
        const nextSpendField = `${nextDate}_spend`;
        const salesField = `${date}_sales`;
        const nextSalesField = `${nextDate}_sales`;
  
        const currentACoS = campaign[acosField];
        const previousACoS = campaign[nextAcosField];
        const spend = campaign[spendField] || 0;
        const previousSpend = campaign[nextSpendField] || 0;
        const sales = campaign[salesField] || 0;
        const previousSales = campaign[nextSalesField] || 0;
        const totalSpend = totalSpendPerDate[date] || 0;
  
        if (currentACoS !== undefined && previousACoS !== undefined && totalSpend > 0) {
          const deltaAcos = currentACoS - previousACoS;
          const spendProportion = spend / totalSpend;
          const impact = deltaAcos * spendProportion;
          campaign[`${date}_impact`] = impact;
        } else {
          campaign[`${date}_impact`] = null;
        }
  
        // Calculate Δ Spend and Δ Sales
        campaign[`${date}_delta_spend`] = spend - previousSpend;
        campaign[`${date}_delta_sales`] = sales - previousSales;
        
        // Calculate Δ Spend % and Δ Sales %
        campaign[`${date}_delta_spend_percent`] = previousSpend !== 0 ? (spend - previousSpend) / previousSpend : null;
        campaign[`${date}_delta_sales_percent`] = previousSales !== 0 ? (sales - previousSales) / previousSales : null;
      }
      // For the earliest date, set impact and deltas to null
      const earliestDate = dates[dates.length - 1];
      campaign[`${earliestDate}_impact`] = null;
      campaign[`${earliestDate}_delta_spend`] = null;
      campaign[`${earliestDate}_delta_sales`] = null;
      campaign[`${earliestDate}_delta_spend_percent`] = null;
      campaign[`${earliestDate}_delta_sales_percent`] = null;
    });
  
    return Object.values(transformedData);
  };

  const handleExportCSV = () => {
    console.log('Exporting CSV...');
    gridRef.current.api.exportDataAsCsv();
  };

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setQuickFilterText('');
    const quickFilterInput = document.querySelector('input[placeholder="Quick Filter... (Press Enter to apply)"]');
    if (quickFilterInput) {
      quickFilterInput.value = '';
    }
  }, []);

  const handleQuickFilterChange = useCallback((filterText) => {
    setQuickFilterText(filterText);
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Campaign Changes</Typography>
        <Box display="flex" alignItems="center">
          <QuickFilter onFilterChange={handleQuickFilterChange} />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV} style={{ marginRight: '10px' }}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            {...AGGrid_Options}
            sideBar={AGGrid_Sidebar}
            quickFilterText={quickFilterText}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignChanges;